import React, { useState, useRef } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import {
  TextField,
  Button,
  Box,
  CardContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Grid,
  Select,
  Switch,
  MenuItem,
  InputAdornment,
  Alert,
  AlertTitle,
  Tooltip,
  Chip,
  Checkbox,
  Autocomplete,
} from '@mui/material';
import copy from 'copy-to-clipboard';
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import InputMask from 'react-input-mask';
import {
  addOrder,
  fetchLinkStatus,
  getClientList,
  getClientUserByClientId,
  getOrder,
  getOrderTypes,
} from '../../../services/order/order';
import { useSelector } from 'react-redux';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { getParticipantType } from '../../../services/admin/participantType';
import CancelDialog from '../../../components/common/CancelDialog';
import Loader from '../../../components/common/Loader';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { addressVerification } from '../../../services/order/benutech';
import CustomizedStepper from '../../../components/order/CustomizedStepper';
import { Cached, CalendarToday, ContentCopy } from '@mui/icons-material';
import DatePicker from 'react-datepicker';
import {
  CLIENT_ADMIN_ROLE,
  OFFICE_USER_ROLE,
  ENTITY_TYPES,
  STATES,
  CLIENT_USER_ROLE,
} from '../../../utils/constants';
import ParticipantTypeModal from '../../../components/order/ParticipantTypeModal';
import DocumentViewer from '../../../components/common/DocumentViewer';
import { cloneDeep } from 'lodash';
import { getConfigurations } from '../../../services/admin/configurations';
import { formatDateToYYYYMMDD } from '../../../utils/helpers';
import { getOfficeList } from '../../../services/admin/users';
import MultipleSellerConfirmationModal from '../../../components/order/MultipleSellerConfirmationModal';

const AddOrder = () => {
  const config = useSelector((state) => state.authSlice)?.configuration?.[window.location.origin];
  const hostname = window.location.hostname;
  const port = window.location.port;
  const domainName = port ? `${hostname}:${port}` : hostname;
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
    trigger,
  } = useForm();
  const divToPrintRef = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [orderTypeLoading, setOrderTypeLoading] = useState(false);
  const [participantListLoading, setParticipantListLoading] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [selectedOption, setSelectedOption] = useState('address');
  const [sameAddress, setSameAddress] = useState([false]);
  const [ClientList, setClientList] = useState([]);
  const [orderDetails, setOrderDetails] = useState({});
  const [orderTypes, setOrderTypes] = useState([]);
  const [UserList, setUserList] = useState([]);
  const [participantList, setParticipantList] = useState([]);
  const [filteredParticipantTypes, setFilteredParticipantTypes] = useState([]);
  const [typesForModal, setTypesForModal] = useState([]);
  const { order } = useSelector((state) => state?.authSlice);
  const [address, setAddress] = useState('');
  const [centerPosition, setCenterPosition] = useState('');
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [isParticipantTypeModalOpen, setIsParticipantTypeModalOpen] = useState(false);
  const [participantAddress, setParticipantAddress] = useState(['']);
  const [isAddressEmpty, setIsAddressEmpty] = useState(false);
  const [addressDetails, setAddressDetails] = useState({});
  const [docModalOpen, setDocModalOpen] = useState(false);
  const [url, setUrl] = useState('');
  const [propertyId, setPropertyId] = useState('');
  const [unitNo, setUnitNo] = useState('');
  const [addressOptions, setAddressOptions] = useState([]);
  const [linkStatus, setLinkStatus] = useState({});
  const [emailChecked, setEmailChecked] = useState(false);
  const [emailParticipantChecked, setEmailParticipantChecked] = useState(true);
  const [showEmailReminder, setShowEmailReminder] = useState(false);
  const [showEmailPart, setShowEmailPart] = useState(false);
  const [showMulPartConfirmation, setShowMultiplePartConfirmation] = useState(false);
  const [deedType, setDeedType] = useState('');
  const [docNumber, setDocNumber] = useState('');
  const [recordingDate, setRecordingDate] = useState('');
  const [docLink, setDocLink] = useState('');
  const [officeList, setOfficeList] = useState([]);
  const [office, setOffice] = useState([]);
  const [payload, setPayload] = useState({});
  const [msg, setMsg] =
    useState(`You have added multiple sellers for the orders. Please select the flow you want to
            follow for handling multiple sellers.`);

  // This state is use to verify address on manual change of city/state/zip as it will rerender component and formData will get latest values. We might revise this logic later on.
  const [bool, setBool] = useState(false);

  const formData = getValues();
  const isClientUser =
    order?.user_role_name === OFFICE_USER_ROLE || order?.user_role_name === CLIENT_USER_ROLE
      ? true
      : false;

  const isSeller = (val) => {
    const obtainedVal = getValues(val);
    let sellerId = participantList?.find(
      (type) => type?.participant_type_name === 'Seller'
    )?.participant_type_id;
    return obtainedVal != sellerId;
  };

  const isBorrower = (val) => {
    const obtainedVal = getValues(val);
    let borrowerId = participantList?.find(
      (type) => type?.participant_type_name === 'Borrower'
    )?.participant_type_id;
    return obtainedVal != borrowerId;
  };

  useEffect(() => {
    fetchDetails();
    fetchOfficeList();
  }, []);

  const fetchOfficeList = async () => {
    setLoading(true);
    const res = await getOfficeList(order?.token, order?.client_id, order?.id);
    if (res?.success) {
      setOfficeList(res?.response || []);
      if (res?.response?.length) {
        const firstOfc = res?.response?.find((ofc) => ofc.status !== 'disabled');
        if (firstOfc) {
          setOffice(firstOfc?.office_id);
        } else {
          toast.error('No active office is available to create an order.');
          setTimeout(() => {
            setLoading(false);
            navigate(-1);
          }, 3000);
        }
      }
    } else {
      toast.error(res?.message);
    }
    setLoading(false);
  };

  const fetchDetails = async () => {
    setLoading(true);
    const res = await getConfigurations(order?.client_id, order?.token);
    if (res?.success) {
      setEmailParticipantChecked(res?.response?.is_sent_invite_link_email === 'yes' ? true : false);
      setShowEmailPart(res?.response?.is_sent_invite_link_email === 'yes' ? true : false);
      setEmailChecked(
        res?.response?.is_send_reminder_email === 'yes' &&
          res?.response?.is_sent_invite_link_email === 'yes'
          ? true
          : false
      );
      setShowEmailReminder(
        res?.response?.is_send_reminder_email === 'yes' &&
          res?.response?.is_sent_invite_link_email === 'yes'
          ? true
          : false
      );
    }
    setLoading(false);
  };
  const handleParticipantSelect = async (value, index) => {
    try {
      const results = await geocodeByAddress(value);
      const addressComponents = results[0].address_components;
      const streetAddress = addressComponents
        .filter((comp) => comp.types.includes('street_number') || comp.types.includes('route'))
        .map((comp) => comp.short_name)
        .join(' ');
      setParticipantAddress((prevData) => {
        return prevData.map((item, i) => (i === index ? streetAddress : item));
      });
      let countyArr =
        addressComponents
          .find((comp) => comp.types.includes('administrative_area_level_2'))
          ?.long_name?.split(' ') || [];
      countyArr.pop();
      setValue(`participants[${index}].address_line_1`, streetAddress);
      // setValue(
      //   `participants[${index}].address_line_2`,
      //   addressComponents.find((comp) => comp.types.includes('locality'))?.long_name || ''
      // );
      setValue(
        `participants[${index}].city`,
        addressComponents.find((comp) => comp.types.includes('locality'))?.long_name || ''
      );
      setValue(
        `participants[${index}].state`,
        addressComponents.find((comp) => comp.types.includes('administrative_area_level_1'))
          ?.short_name || ''
      );
      setValue(
        `participants[${index}].zip_code`,
        addressComponents.find((comp) => comp.types.includes('postal_code'))?.long_name || ''
      );
      setValue(`participants[${index}].county`, countyArr.join(' ') || '');
    } catch (error) {
      console.error('Error selecting address:', error);
    }
  };
  const mapRef = useRef(null);

  useEffect(() => {
    let zoom = 10;
    let center = centerPosition;
    let streetView = true;
    if (mapRef.current && centerPosition?.lat && centerPosition?.lng) {
      if (streetView) {
        new window.google.maps.StreetViewService().getPanorama(
          { location: center, radius: 50 },
          function (data, status) {
            if (status === window.google.maps.StreetViewStatus.OK) {
              const paronama = new window.google.maps.StreetViewPanorama(mapRef.current, {
                position: center,
                pov: {
                  heading: 0,
                  pitch: 0,
                },
              });
              const whereToLookLatLng = new window.google.maps.LatLng(
                parseFloat(center?.lat),
                parseFloat(center?.lng)
              );
              const manLatLng = data.location.latLng;

              const heading = window.google.maps.geometry.spherical.computeHeading(
                manLatLng,
                whereToLookLatLng
              );

              const pov = paronama.getPov();

              pov.heading = heading;
              pov.zoom = 0;

              paronama.setPov(pov);
            } else {
              new window.google.maps.Map(mapRef?.current, {
                center: {
                  lat: parseFloat(center?.lat),
                  lng: parseFloat(center?.lng),
                },
                zoom: zoom,
                streetViewControl: streetView,
              });
            }
          }
        );
      } else {
        new window.google.maps.Map(mapRef?.current, {
          center: {
            lat: parseFloat(center?.lat),
            lng: parseFloat(center?.lng),
          },
          zoom: zoom,
          streetViewControl: streetView,
        });
      }
    }
  }, [centerPosition?.lat, centerPosition?.lng]);

  const handleCloseCancelDialog = () => {
    setIsCancelDialogOpen(false);
  };

  const handleConfirmCancel = () => {
    setAddress('');
    reset();
    setActiveStep(0);
    setParticipantAddress({});
    setIsAddressEmpty(false);
    handleCloseCancelDialog();
  };

  const validateAddress = () => {
    if (address?.length === 0) {
      setIsAddressEmpty(true);
      return;
    } else {
      setIsAddressEmpty(false);
    }
  };
  useEffect(() => {
    const dataValues = getValues();
    if (address?.length && propertyId) {
      const fip =
        addressOptions?.find((address) => address.sa_property_id === propertyId)
          ?.mm_fips_state_code || '';
      verifyAddressDetails(
        {
          property_id: propertyId,
          site_address: address,
          site_city: dataValues['city'] ?? '',
          site_state: dataValues['state'] ?? '',
          site_zip: dataValues['zipCode'] ?? '',
          // site_unit: unitNo,
          site_full_address:
            address + ',' + dataValues['city'] ??
            '' + ',' + dataValues['state'] ??
            '' + ',' + dataValues['zipCode'] ??
            '',
          state_fips: fip,
        },
        dataValues['county'] ?? '',
        false
      );
    }
  }, [propertyId]);

  // below code is to handle the case where user manually change the city/state/zip

  useEffect(() => {
    const handler = setTimeout(() => {
      if (
        addressDetails?.data?.length &&
        (addressDetails?.data?.[0]?.sa_site_city !== formData?.city ||
          addressDetails?.data?.[0]?.sa_site_state !== formData?.state ||
          addressDetails?.data?.[0]?.sa_site_zip !== formData?.zipCode)
      ) {
        const dataValues = getValues();
        const fip =
          addressOptions?.find((address) => address.sa_property_id === propertyId)
            ?.mm_fips_state_code || '';
        let payload = {
          site_address: address,
          site_city: dataValues['city'] ?? '',
          site_state: dataValues['state'] ?? '',
          site_zip: dataValues['zipCode'] ?? '',
          site_full_address:
            address + ',' + dataValues['city'] ??
            '' + ',' + dataValues['state'] ??
            '' + ',' + dataValues['zipCode'] ??
            '',
        };
        if (propertyId) {
          payload.property_id = propertyId;
          payload.state_fips = fip;
        }
        if (address?.length) verifyAddressDetails(payload, dataValues['county'] ?? '', false);
      }
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [formData?.city, formData?.state, formData?.zipCode]);

  // below code is to handle the case where user change order type again after adding address

  useEffect(() => {
    if (addressDetails?.data?.length) {
      const dataValues = getValues();
      const fip =
        addressOptions?.find((address) => address.sa_property_id === propertyId)
          ?.mm_fips_state_code || '';
      let payload = {
        site_address: address,
        site_city: dataValues['city'] ?? '',
        site_state: dataValues['state'] ?? '',
        site_zip: dataValues['zipCode'] ?? '',
        site_full_address:
          address + ',' + dataValues['city'] ??
          '' + ',' + dataValues['state'] ??
          '' + ',' + dataValues['zipCode'] ??
          '',
      };
      if (propertyId) {
        payload.property_id = propertyId;
        payload.state_fips = fip;
      }
      if (address?.length) verifyAddressDetails(payload, dataValues['county'] ?? '', false);
    }
  }, [formData?.order_type_id]);

  const resetPopulatedData = () => {
    // participant mailing address
    setSameAddress([false]);
    setParticipantAddress((prevData) => {
      let tempAddress = prevData;
      tempAddress[0] = '';
      return tempAddress;
    });
    setValue(`participants[0].address_line_1`, '');
    setValue(`participants[0].address_line_2`, '');
    setValue(`participants[0].city`, '');
    setValue(`participants[0].state`, '');
    setValue(`participants[0].zip_code`, '');
    setValue(`participants[0].unit_no`, '');
    setValue(`participants[0].county`, '');

    // deed related details
    setValue(`loan_reference_number`, '');
    setDeedType('');
    setDocNumber('');
    setRecordingDate('');
    setDocLink('');

    // owner related details
    setValue(
      `participants[0].business_name`,

      ''
    );
    setValue(`participants[0].entity_type`, '');

    setValue(`participants[0].first_name`, '');
    setValue(`participants[0].last_name`, '');
  };

  const populateParticipantInfo = (addressVerificationDetails, index = 0, isSeller) => {
    // if we need to populate address info then remove below isSeller condition.
    if (addressVerificationDetails?.data?.[0]?.sa_site_mail_same === 'N') {
      setParticipantAddress((prevData) => {
        let tempAddress = prevData;
        tempAddress[index] = addressVerificationDetails?.data?.[index]?.v_mail_address;
        return tempAddress;
      });
      setValue(
        `participants[${index}].address_line_1`,
        addressVerificationDetails?.data?.[0]?.v_mail_address ?? ''
      );
      setValue(`participants[${index}].address_line_2`, '');
      setValue(
        `participants[${index}].city`,
        addressVerificationDetails?.data?.[0]?.sa_mail_city ?? ''
      );
      setValue(
        `participants[${index}].state`,
        addressVerificationDetails?.data?.[0]?.sa_mail_state ?? ''
      );
      setValue(
        `participants[${index}].zip_code`,
        addressVerificationDetails?.data?.[0]?.sa_mail_zip ?? ''
      );
      setValue(
        `participants[${index}].unit_no`,
        addressVerificationDetails?.data?.[0]?.sa_mail_unit_val ?? ''
      );
      setValue(`participants[${index}].county`, '');
    }
    if (isSeller) {
      setValue(`loan_reference_number`, addressVerificationDetails?.loan?.['Doc #'] ?? '');
      setDeedType(addressVerificationDetails?.loan?.['Deed Type'] ?? '');
      setDocNumber(addressVerificationDetails?.loan?.['Doc #'] ?? '');
      setRecordingDate(addressVerificationDetails?.loan?.['Recording Date'] ?? '');
      setDocLink(addressVerificationDetails?.loan?.['Doc_link'] ?? '');
    }

    let isEntity = addressVerificationDetails?.ownerType === 'Company';
    let entityType = '';

    if (isEntity) {
      for (let eType of ENTITY_TYPES) {
        if (
          addressVerificationDetails?.data?.[0]?.[`sa_owner_${index + 1}_first`]
            ?.toLowerCase()
            ?.includes(eType?.toLowerCase())
        ) {
          entityType = eType;
          break;
        }
        if (eType === 'Corporation') {
          if (
            addressVerificationDetails?.data?.[0]?.[`sa_owner_${index + 1}_first`]
              ?.toLowerCase()
              ?.includes('inc')
          ) {
            entityType = eType;
            break;
          }
        }
      }
      setValue(
        `participants[${index}].business_name`,
        addressVerificationDetails?.data?.[0]?.[`formatted_sa_owner_${index + 1}`] ||
          addressVerificationDetails?.data?.[0]?.[`sa_owner_${index + 1}_first`] ||
          ''
      );
      setValue(`participants[${index}].entity_type`, entityType || '');
      setParticipants((oldParticipants) => {
        let tempParticipants = [...oldParticipants];
        tempParticipants[index].is_individual = false;
        return tempParticipants;
      });
    } else {
      setParticipants((oldParticipants) => {
        let tempParticipants = [...oldParticipants];
        tempParticipants[index].is_individual = true;
        return tempParticipants;
      });

      setValue(
        `participants[${index}].first_name`,
        addressVerificationDetails?.data?.[0]?.[`sa_owner_${index + 1}_first`] ?? ''
      );
      setValue(
        `participants[${index}].last_name`,
        addressVerificationDetails?.data?.[0]?.[`sa_owner_${index + 1}_last`] ?? ''
      );
    }
  };
  const verifyAddressDetails = async (payload, county) => {
    setLoading(true);
    const dataValues = getValues();
    let sellerId = participantList?.find(
      (type) => type?.participant_type_name === 'Seller'
    )?.participant_type_id;
    let borrowerId = participantList?.find(
      (type) => type?.participant_type_name === 'Borrower'
    )?.participant_type_id;
    let addressVerificationDetails = dataValues?.apnNo
      ? { success: true, data: {} }
      : await addressVerification(payload, order?.token);

    setAddressDetails(addressVerificationDetails);
    if (!addressVerificationDetails?.success) {
      setLoading(false);
      toast.warning(
        'We are unable to verify your address. Please review the details and try entering the address manually.'
      );
    }

    if (addressVerificationDetails?.multiple) {
      setAddressOptions([{ header: true }, ...addressVerificationDetails?.data]);
      setUnitNo('');
      setPropertyId('');
      toast.warning(
        'Multiple addresses found. Please enter the unit number for seamless processing.'
      );
      if (
        dataValues?.participants?.[0]?.user_participant_type_id == sellerId ||
        dataValues?.participants?.[0]?.user_participant_type_id == borrowerId
      )
        resetPopulatedData(true);
    } else {
      setSameAddress((prev) => {
        let tempData = [...prev];
        tempData[0] =
          addressVerificationDetails?.data?.[0]?.sa_site_mail_same === 'Y' &&
          !addressVerificationDetails?.multiple &&
          (dataValues?.participants?.[0]?.user_participant_type_id == sellerId ||
            dataValues?.participants?.[0]?.user_participant_type_id == borrowerId)
            ? true
            : false;
        return tempData;
      });

      if (
        dataValues?.participants?.[0]?.user_participant_type_id == sellerId ||
        dataValues?.participants?.[0]?.user_participant_type_id == borrowerId
      ) {
        // below function will populate information in seller 1
        populateParticipantInfo(
          addressVerificationDetails,
          0,
          dataValues?.participants?.[0]?.user_participant_type_id == sellerId ? true : false
        );

        // below is the logic to populate second seller information

        if (addressVerificationDetails?.data?.[0]?.sa_owner_2_first?.length) {
          let idx = null;
          for (const [index, part] of (getValues('participants') || [])?.entries()) {
            if (index === 0) continue;
            if (
              part?.user_participant_type_id === sellerId ||
              part?.user_participant_type_id === borrowerId
            ) {
              idx = index;
              break;
            }
          }
          if (!idx) {
            idx = participants?.length;
            setParticipants((parts) => [...parts, { is_individual: true }]);
            setValue(
              `participants[${idx}].user_participant_type_id`,
              dataValues?.participants?.[0]?.user_participant_type_id
            );
            setParticipantAddress([...participantAddress, '']);
            const isSameAddress =
              addressVerificationDetails?.data?.[0]?.sa_site_mail_same === 'Y' &&
              !addressVerificationDetails?.multiple;

            if (isSameAddress) {
              setSameAddress((prevData) => [...prevData, isSameAddress]);
              setValue(`participants[${idx}].address_line_1`, address ?? '');
              setValue(`participants[${idx}].address_line_2`, '');
              setValue(
                `participants[${idx}].city`,
                addressVerificationDetails?.data?.[0]?.sa_site_city ?? ''
              );
              setValue(
                `participants[${idx}].state`,
                addressVerificationDetails?.data?.[0]?.sa_site_state ?? ''
              );
              setValue(
                `participants[${idx}].zip_code`,
                addressVerificationDetails?.data?.[0]?.sa_site_zip ?? ''
              );
              setValue(
                `participants[${idx}].unit_no`,
                !addressVerificationDetails?.multiple
                  ? addressVerificationDetails?.data?.[0]?.sa_site_unit_val || ''
                  : ''
              );
              setValue(
                `participants[${idx}].county`,
                addressVerificationDetails?.success ? county : ''
              );
            }
            // setSameAddressList(
            //   idx,
            //   addressVerificationDetails?.data?.[0]?.sa_site_mail_same === 'Y' &&
            //     !addressVerificationDetails?.multiple
            // );
          }
          populateParticipantInfo(
            addressVerificationDetails,
            idx,
            dataValues?.participants?.[0]?.user_participant_type_id == sellerId ? true : false
          );
        }
      } else {
        setParticipantAddress((old) => {
          let tempAddress = [...old];
          tempAddress[0] = '';
          return tempAddress;
        });
        setValue(`participants[0].address_line_1`, '');
        setValue(`participants[0].address_line_2`, '');
        setValue(`participants[0].city`, '');
        setValue(`participants[0].state`, '');
        setValue(`participants[0].zip_code`, '');
        setValue(`participants[0].unit_no`, '');
        setValue(`participants[0].county`, '');
      }

      const idx = addressOptions?.findIndex(
        (adr) => adr.sa_property_id === addressVerificationDetails?.data?.[0]?.sa_property_id
      );
      if (idx === -1) {
        setPropertyId('');
        setUnitNo('');
        setAddressOptions([]);
      }
    }

    // Data population

    setValue('city', addressVerificationDetails?.data?.[0]?.sa_site_city || '');
    setValue('state', addressVerificationDetails?.data?.[0]?.sa_site_state || '');
    setValue('zipCode', addressVerificationDetails?.data?.[0]?.sa_site_zip || '');
    setValue(
      'unit_no',
      !addressVerificationDetails?.multiple
        ? addressVerificationDetails?.data?.[0]?.sa_site_unit_val || ''
        : ''
    );
    setValue('county', addressVerificationDetails?.success ? county : '');

    // resetting data in case of wrong address

    if (!addressVerificationDetails?.data?.length) {
      setAddress('');
      setUnitNo('');
      setPropertyId('');
      if (
        dataValues?.participants?.[0]?.user_participant_type_id == sellerId ||
        dataValues?.participants?.[0]?.user_participant_type_id == borrowerId
      )
        resetPopulatedData(true);
    }
    trigger('city');
    trigger('state');
    trigger('zipCode');
    trigger('county');
    setLoading(false);
  };

  const resetParticipants = (val) => {
    let formValParticipants = getValues('participants');
    if (formValParticipants?.length) {
      formValParticipants.splice(0, 1);
      setValue('participants', formValParticipants);
    }
    let tempParticipantTypes = (orderTypes?.find((o) => o.id == val)?.participant_types || [])?.map(
      (o) => o?.participant_type_id
    );
    const tempTypes = participantList.filter((type) =>
      tempParticipantTypes.includes(type.participant_type_id)
    );
    if (tempTypes?.length) {
      setSameAddress([false]);
      setParticipants([{ is_individual: true }]);
      setValue(`participants[0].user_participant_type_id`, tempTypes[0]?.participant_type_id);
      setValue(`participants[0].first_name`, '');
      setValue(`participants[0].last_name`, '');
      setValue(`participants[0].email`, '');
      setValue(`participants[0].mobile_phone`, '');
      setValue(`participants[0].ssn_number`, '');
      setValue(`participants[0].business_name`, '');
    } else {
      setSameAddress([]);
      setParticipants([]);
    }
    setBool((last) => !last);
    setFilteredParticipantTypes(tempTypes);
  };

  const handleSelect = async (value) => {
    setLoading(true);
    setAddress(value);
    setIsAddressEmpty(false);
    try {
      // reset participants when new address is selected
      const orderType = getValues('order_type_id');
      resetParticipants(orderType);

      // address destructuring logic

      const results = await geocodeByAddress(value);
      const latLng = await getLatLng(results[0]);
      setCenterPosition(latLng);
      const addressComponents = results[0].address_components;
      const streetAddress = addressComponents
        .filter((comp) => comp.types.includes('street_number') || comp.types.includes('route'))
        .map((comp) => comp.short_name)
        .join(' ');
      setAddress(streetAddress);

      // Address description

      const city =
        addressComponents.find((comp) => comp.types.includes('locality'))?.long_name || '';
      const state =
        addressComponents.find((comp) => comp.types.includes('administrative_area_level_1'))
          ?.short_name || '';
      const zip =
        addressComponents.find((comp) => comp.types.includes('postal_code'))?.long_name || '';
      // const unit = dataValues['unit_no']?.length ? dataValues['unit_no'] : '';
      const fullAddress = address + ',' + city + ',' + state + ',' + zip;
      let countyArr =
        addressComponents
          .find((comp) => comp.types.includes('administrative_area_level_2'))
          ?.long_name?.split(' ') || [];
      countyArr.pop();
      // Address verification

      if (value?.length && addressComponents?.length) {
        await verifyAddressDetails(
          {
            site_address: streetAddress,
            site_city: city,
            site_state: state,
            site_zip: zip,
            // site_unit: unit,
            site_full_address: fullAddress,
          },
          countyArr.join(' ') || ''
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error selecting address:', error);
    }
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const steps = ['PROPERTY INFORMATION', 'PARTICIPANT & OTHER DETAILS', 'ORDER CONFIRMATION'];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (sameAddress[0]) {
      setSameAddressList(0, true);
    }
  }, [sameAddress]);

  const removeParticipant = (index) => {
    const updatedParticipants = [...participants];
    updatedParticipants.splice(index, 1);
    setParticipants(updatedParticipants);
    setSameAddress((prevData) => {
      return prevData.filter((_, i) => i !== index);
    });
    setParticipantAddress((prevData) => {
      return prevData.filter((_, i) => i !== index);
    });
    let formValParticipants = getValues('participants');
    if (!updatedParticipants?.length) {
      setValue('participants', []);
    } else {
      formValParticipants.splice(index, 1);
      setValue('participants', formValParticipants);
    }
    reset(getValues());
  };

  const fetchParticipantList = async () => {
    setParticipantListLoading(true);
    const res = await getParticipantType(order?.token, order?.client_id, office);
    if (res?.success) {
      setParticipantList(res?.response || []);
    }
    setParticipantListLoading(false);
  };

  const fetchOrderTypes = async () => {
    setOrderTypeLoading(true);
    const res = await getOrderTypes(order?.token, order?.client_id, office);
    if (res?.success) {
      setOrderTypes(res?.response || []);
    }
    setOrderTypeLoading(false);
  };

  const fetchClientList = async () => {
    setLoading(true);
    if (order?.user_role_name === 'Admin') {
      const res = await getClientList(order?.token);
      if (res?.success) {
        setClientList(res?.response || []);
      }
    } else {
      setClientList([{ id: order?.client_id, full_name: order?.client_name }]);
    }
    setLoading(false);
  };

  const fetchUserList = async (ClientId) => {
    setLoading(true);
    const id = ClientId;
    const res = await getClientUserByClientId(id, order?.token);
    if (res) {
      setUserList(res?.response || []);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchOrderTypes();
    fetchParticipantList();
  }, [office]);

  useEffect(() => {
    fetchClientList();
    if (isClientUser) {
      setUserList([{ id: order?.user_id, full_name: order?.first_name + ' ' + order?.last_name }]);
    } else if (order?.user_role_name === CLIENT_ADMIN_ROLE) {
      fetchUserList(order?.user_id);
    }
  }, []);

  // link status
  useEffect(() => {
    if (orderDetails?.participants?.length) getLinkStatus();
  }, [orderDetails?.participants]);

  const getLinkStatus = async () => {
    setLoading(true);
    let tempLinkStatus = cloneDeep(linkStatus);
    for (const participant of orderDetails?.participants) {
      const res = await fetchLinkStatus(participant.uuid, orderDetails?.id, order?.token);
      if (res?.success) tempLinkStatus[participant.uuid] = res?.response?.is_source_data_pulled;
    }
    setLinkStatus(tempLinkStatus);
    setLoading(false);
  };

  const sortByDynamicTypeId = (arr) => {
    const result = [];
    const usedIndexes = new Set(); // To track already used indices

    let currentTypeId = arr[0].user_participant_type_id; // Start with the first element's type ID
    let remaining = [...arr]; // Clone the array to work on it

    while (result.length < arr.length) {
      // Find all elements with the current type ID
      const matching = remaining.filter((item, index) => {
        if (!usedIndexes.has(index) && item.user_participant_type_id === currentTypeId) {
          usedIndexes.add(index);
          return true;
        }
        return false;
      });

      // Add matched elements to the result
      result.push(...matching);

      // Find the next unmatched element to determine the next type ID
      const nextUnmatchedIndex = remaining.findIndex((_, index) => !usedIndexes.has(index));
      if (nextUnmatchedIndex !== -1) {
        currentTypeId = remaining[nextUnmatchedIndex].user_participant_type_id;
      }
    }

    return result;
  };

  const findDuplicateUserParticipantTypeIds = (initialParticipants) => {
    const seen = new Set();
    const duplicates = new Set();

    // Single pass to find duplicates
    initialParticipants.forEach((participant) => {
      const typeId = participant.user_participant_type_id;
      if (seen.has(typeId)) {
        duplicates.add(typeId); // If already seen, add to duplicates
      } else {
        seen.add(typeId); // Mark as seen
      }
    });

    return Array.from(duplicates); // Convert Set to an array
  };

  const onHandleSubmit = async (data, _, type = null) => {
    if (selectedOption === 'address') {
      if (address?.length === 0) {
        validateAddress();
        return setActiveStep(0);
      }
    }

    const initialParticipants = data?.participants?.map((participant, index) => ({
      ...participant,
      is_address_same_as_order_property: sameAddress[index] ? 'yes' : 'no',
      first_name: participants?.[index]?.is_individual ? participant?.first_name : '',
      last_name: participants?.[index]?.is_individual ? participant?.last_name : '',
      contact_first_name: participants?.[index]?.is_individual
        ? ''
        : participant?.contact_first_name,
      contact_last_name: participants?.[index]?.is_individual ? '' : participant?.contact_last_name,
      user_participant_type_id: participant?.user_participant_type_id,
      ssn_number: participant?.ssn_number || '',
      mobile_phone: participant?.mobile_phone || '',
      email: participant?.email || '',
      business_name: participant?.business_name || '',
      entity_type: participant?.entity_type || '',
      entity_state: participant?.entity_state || '',
      entity_ein: participant?.entity_ein || '',
      address_line_1: participant?.address_line_1 || '',
      address_line_2: participant?.address_line_2 || '',
      city: participant?.city || '',
      state: participant?.state || '',
      zip_code: participant?.zip_code || participant?.Zip_code || '',
      unit_no: participant?.unit_no || '',
      county: participant?.county || '',
      apn_no: selectedOption === 'apn' ? participant?.apn_no || '' : '',
      is_individual_or_entity: participants?.[index]?.is_individual ? 'individual' : 'entity',
    }));
    const mulParticipants = findDuplicateUserParticipantTypeIds(initialParticipants);
    if (mulParticipants?.length && !type) {
      const mulParticipantsNames = mulParticipants?.map(
        (part) =>
          filteredParticipantTypes?.find(
            (filteredPart) => filteredPart?.participant_type_id === part
          )?.participant_type_name
      );
      setPayload(data);
      setMsg(`You have added multiple participants of same participant type(${mulParticipantsNames?.join(
        ', '
      )}) for the orders. Please select the flow you want to
            follow for handling multiple participants of same participant type.`);
      setShowMultiplePartConfirmation(true);
      return;
    }

    const filteredData = filterObjectsWithValues(initialParticipants);
    if (filteredData?.length !== initialParticipants.length)
      return toast.error('Please make sure you have added all required fields.');

    const templateData = {
      client_id: data?.client_id,
      user_id: data?.user_id,
      escrow_number: data?.escrow_number,
      order_type_id: data?.order_type_id,
      loan_reference_number: data?.loan_reference_number,
      anticipated_close_date: data?.anticipated_close_date,
      property_address_type: selectedOption,
      property_address_1: address,
      property_address_2: data?.streetAddress,
      property_city: data?.city,
      property_state: data?.state,
      property_zipcode: data?.zipCode,
      property_unit_no: unitNo ?? '',
      property_county: data?.county ?? '',
      apn_no: data?.apnNo || '',
      is_participant_form_separate_or_single: type ? type : 'single',
      participants: sortByDynamicTypeId(filteredData) || [],
    };
    handleSave(templateData);
  };

  const onSubmitStep1 = async () => {
    setLoading(true);
    const dataValues = getValues();

    // if (!addressDetails?.success) {
    //   toast.warning('Address is not verified. Please try to correct the address.');
    // }

    if (addressDetails?.multiple) {
      toast.error('Please enter unit number for smooth processing.');
    }

    if (isAddressEmpty && address?.length) {
      setIsAddressEmpty(false);
    }
    if (sameAddress[0]) {
      setSameAddressList(0, true);
    }
    handleNext();
    setLoading(false);
  };

  const handleParticipantAdd = (participant_type) => {
    const addedParticipants = getValues('participants');

    // Get Seller and Borrower participant type IDs
    let sellerId = filteredParticipantTypes?.find(
      (type) => type?.participant_type_name === 'Seller'
    )?.participant_type_id;
    let borrowerId = filteredParticipantTypes?.find(
      (type) => type?.participant_type_name === 'Borrower'
    )?.participant_type_id;

    // Check if Seller or Borrower already exists
    const sellerAlreadyAvailable = addedParticipants?.find(
      (addedPart) => addedPart?.user_participant_type_id === sellerId
    );
    const borrowerAlreadyAvailable = addedParticipants?.find(
      (addedPart) => addedPart?.user_participant_type_id === borrowerId
    );

    let idx = 0;
    setParticipants((parts) => {
      idx = parts?.length;
      return [...parts, { is_individual: true }];
    });
    setValue(`participants[${idx}].user_participant_type_id`, participant_type);
    setParticipantAddress([...participantAddress, '']);
    if (sellerId == participant_type && addressDetails?.success && !sellerAlreadyAvailable) {
      setValue(
        `participants[${idx}].first_name`,
        addressDetails?.data?.[0]?.sa_owner_1_first ?? ''
      );
      setValue(`participants[${idx}].last_name`, addressDetails?.data?.[0]?.sa_owner_1_last ?? '');
      setValue(`loan_reference_number`, addressDetails?.loan?.['Doc #'] ?? '');
      setSameAddress([addressDetails?.data?.[0]?.sa_site_mail_same === 'N' ? false : true]);
    }

    if (borrowerId === participant_type && addressDetails?.success && !borrowerAlreadyAvailable) {
      setValue(
        `participants[${idx}].first_name`,
        addressDetails?.data?.[0]?.borrower_first_name ?? ''
      );
      setValue(
        `participants[${idx}].last_name`,
        addressDetails?.data?.[0]?.borrower_last_name ?? ''
      );
      setSameAddress([addressDetails?.data?.[0]?.sa_site_mail_same === 'N' ? false : true]);
    }

    setIsParticipantTypeModalOpen(false);
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight + 500);
    }, 500);
  };

  const setSameAddressList = (index, checked) => {
    const formValues = getValues();
    setParticipantAddress((prevData) => {
      if (Array.isArray(prevData)) {
        return prevData.map((item, i) => (i === index ? (checked ? address : '') : item));
      }
      return [];
    });
    setValue(`participants[${index}].address_line_1`, checked ? address : '');
    setValue(`participants[${index}].address_line_2`, checked ? formValues?.streetAddress : '');
    setValue(`participants[${index}].city`, checked ? formValues?.city : '');
    setValue(`participants[${index}].state`, checked ? formValues?.state : '');
    setValue(`participants[${index}].zip_code`, checked ? formValues?.zipCode : '');
    setValue(`participants[${index}].unit_no`, checked ? formValues?.unit_no : '');
    setValue(`participants[${index}].county`, checked ? formValues?.county : '');
    if (selectedOption === 'apn') {
      setValue(`participants[${index}].apn_no`, checked ? formValues?.apnNo : '');
      setValue(`participants[${index}].Zip_code`, checked ? formValues?.zipCode : '');
    }
  };

  const filterObjectsWithValues = (objects) => {
    return objects.filter((obj, index) => {
      const requiredFields = !participants?.[index]?.is_individual
        ? ['email', 'user_participant_type_id', 'business_name']
        : ['first_name', 'last_name', 'email', 'user_participant_type_id'];
      // Check if all required fields have non-empty values
      for (const field of requiredFields) {
        if (!obj[field] || obj[field]?.length === 0) {
          return false;
        }
      }
      return true;
    });
  };

  const handleSameAddress = (index, newValue) => {
    setSameAddress((prevData) => {
      return prevData.map((item, i) => (i === index ? newValue : item));
    });
    setSameAddressList(index, newValue);
  };

  const handleSave = async (data) => {
    setLoading(true);
    let payload = data;
    payload.is_send_reminder_email = emailChecked ? 'yes' : 'no';
    payload.is_sent_invite_link_email = emailParticipantChecked ? 'yes' : 'no';
    payload.sa_property_id = addressDetails?.data?.[0]?.sa_property_id
      ? addressDetails?.data?.[0]?.sa_property_id
      : '';
    payload.mm_fips_state_code = addressDetails?.data?.[0]?.mm_fips_state_code
      ? addressDetails?.data?.[0]?.mm_fips_state_code
      : '';
    payload.mm_fips_muni_code = addressDetails?.data?.[0]?.mm_fips_muni_code
      ? addressDetails?.data?.[0]?.mm_fips_muni_code
      : '';
    payload.sa_parcel_nbr_primary = addressDetails?.data?.[0]?.sa_parcel_nbr_primary
      ? addressDetails?.data?.[0]?.sa_parcel_nbr_primary
      : '';
    payload.FinanceHistory =
      !addressDetails?.multiple && addressDetails?.financial_history
        ? addressDetails?.financial_history
        : [];
    payload.SalesHistory =
      !addressDetails?.multiple && addressDetails?.sales_history
        ? addressDetails?.sales_history
        : [];
    payload.OwnerInfo =
      !addressDetails?.multiple && addressDetails?.owner_info ? addressDetails?.owner_info : [];
    payload.anticipated_close_date = formatDateToYYYYMMDD(payload?.anticipated_close_date);
    payload.client_office_id = office ? office : '';

    const result = await addOrder(payload, order?.token);
    if (result?.success) {
      const orderData = await getOrder(result?.response?.order_id, order?.token);
      setShowMultiplePartConfirmation(false);
      setOrderDetails(orderData?.response || {});
      handleNext();
      toast.success(result?.message);
      setAddress();
      setParticipantAddress({});
      setSameAddress([true]);
      reset();
    } else {
      toast.error(result?.error || result?.message || 'Something went wrong!');
    }

    setLoading(false);
  };

  const onError = (errors) => {
    if (isAddressEmpty) return setActiveStep(0);
    const firstStepFields = [
      'client_id',
      'user_id',
      'escrow_number',
      'anticipated_close_date',
      'city',
      'state',
      'zipCode',
      'apnNo',
      'county',
    ];
    Object.keys(errors).forEach((error) => {
      if (firstStepFields?.includes(error)) {
        return setActiveStep(0);
      }
    });
  };

  const createOrder = async (type) => {
    onHandleSubmit(payload, '', type);
  };

  return (
    <Box className='dashboard-main order-dashboard-main'>
      {(loading || participantListLoading || orderTypeLoading) && <Loader />}
      <MultipleSellerConfirmationModal
        open={showMulPartConfirmation}
        setOpen={setShowMultiplePartConfirmation}
        handleSave={createOrder}
        msg={msg}
      />
      <Grid container className='dashboard-content add-order-content'>
        <div className='transaction-logs-wrapper'>
          <Box sx={{ mt: 2 }} className='client-user-form order-form'>
            <CardContent>
              <CustomizedStepper
                activeStep={activeStep}
                steps={steps}
                primaryColor={config?.branding_json?.primary_color}
              />
              <FormProvider>
                <form
                  onSubmit={handleSubmit(onHandleSubmit, onError)}
                  style={{ marginTop: '25px' }}
                  className='order-form disabled-form-setup'
                >
                  <div style={{ display: `${activeStep === 0 ? 'unset' : 'none'}` }}>
                    <div className='form-title mb-8'>
                      <h4>Order Information</h4>
                    </div>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4} display={'none'}>
                        <label>Client</label>
                        <label className='required'>{'*'}</label>
                        <Controller
                          name='client_id'
                          control={control}
                          defaultValue={order?.client_id}
                          rules={{ required: 'Client is required' }}
                          render={({ field }) => (
                            <FormControl fullWidth variant='outlined'>
                              <Select
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  fetchUserList(e.target.value);
                                }}
                                disabled={true}
                              >
                                {ClientList?.map((item) => (
                                  <MenuItem value={item?.id}>{item?.full_name}</MenuItem>
                                ))}
                                {ClientList?.length === 0 && (
                                  <MenuItem value=''>Client Not Found</MenuItem>
                                )}
                              </Select>
                              {errors.client_id && (
                                <Typography className='order-error' color='error'>
                                  {errors.client_id.message}
                                </Typography>
                              )}
                            </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} display={'none'}>
                        <label>User</label>
                        <label className='required'>{'*'}</label>
                        <Controller
                          name='user_id'
                          control={control}
                          defaultValue={order?.user_id}
                          rules={{ required: 'User is required' }}
                          render={({ field }) => (
                            <FormControl fullWidth>
                              <Select {...field} disabled={isClientUser ? true : false}>
                                {UserList?.map((item) => (
                                  <MenuItem value={item?.id}>{item?.full_name}</MenuItem>
                                ))}
                                {UserList?.length === 0 && (
                                  <MenuItem value=''>User Not Found</MenuItem>
                                )}
                              </Select>
                              {errors.user_id && (
                                <Typography className='order-error' color='error'>
                                  {errors.user_id.message}
                                </Typography>
                              )}
                            </FormControl>
                          )}
                        />
                      </Grid>
                      {officeList.length > 1 ? (
                        <Grid item sm={6} md={4} xs={12}>
                          <label>Office</label>
                          <label className='required'>{'*'}</label>
                          <FormControl fullWidth>
                            <Select
                              value={office}
                              onChange={(e) => {
                                setOffice(e.target.value);
                              }}
                            >
                              {officeList?.map((ofc) => (
                                <MenuItem
                                  value={ofc.office_id}
                                  key={ofc.office_id}
                                  disabled={ofc.status === 'disabled'}
                                >
                                  {ofc.office_name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      ) : (
                        ''
                      )}
                      <Grid item sm={6} md={4} xs={12}>
                        <label>Order Type</label>
                        <label className='required'>{'*'}</label>
                        <Controller
                          name={`order_type_id`}
                          control={control}
                          rules={{ required: 'Order type is required' }}
                          defaultValue=''
                          render={({ field, fieldState }) => (
                            <FormControl fullWidth>
                              <Select
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  resetParticipants(e.target.value);
                                }}
                              >
                                {orderTypes?.map((item) => {
                                  return <MenuItem value={item?.id}>{item?.name}</MenuItem>;
                                })}
                                {orderTypes?.length === 0 && (
                                  <MenuItem value=''>Order Type Not Found</MenuItem>
                                )}
                              </Select>
                              <Typography className='order-error' color='error'>
                                {fieldState.error ? fieldState.error.message : ''}
                              </Typography>
                            </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <label>Escrow Number</label>
                        <label className='required'>{'*'}</label>
                        <Controller
                          name='escrow_number'
                          control={control}
                          defaultValue=''
                          rules={{ required: 'Escrow Number is required' }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              error={!!errors.escrow_number}
                              helperText={errors.escrow_number?.message}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <label>Anticipated Close Date</label>
                        <label className='required'>{'*'}</label>
                        <Controller
                          name='anticipated_close_date'
                          control={control}
                          defaultValue=''
                          rules={{ required: 'Anticipated Close Date is required' }}
                          render={({ field }) => (
                            <DatePicker
                              showIcon
                              minDate={new Date()}
                              selected={getValues('anticipated_close_date')}
                              onChange={(date) => {
                                setValue('anticipated_close_date', date);
                                trigger('anticipated_close_date');
                              }}
                              autoComplete='off'
                              customInput={
                                <TextField
                                  className='full-width w-100p'
                                  variant='outlined'
                                  autoComplete='off'
                                  error={!!errors.anticipated_close_date}
                                  helperText={errors.anticipated_close_date?.message}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position='end'>
                                        <CalendarToday fontSize='small' style={{ color: 'grey' }} />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              }
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={1} className='mt-20'>
                      <Grid item sm={8}>
                        <Grid item xs={12}>
                          <div className='form-title mb-8'>
                            <h4>Property Address</h4>
                          </div>
                          <FormControl
                            component='fieldset'
                            className='order-radio-btn order-form-radio'
                          >
                            <RadioGroup
                              aria-label='Select Option'
                              name='option'
                              value={selectedOption}
                              onChange={handleOptionChange}
                            >
                              <FormControlLabel
                                value='address'
                                control={<Radio />}
                                label='Single address'
                              />
                              <FormControlLabel value='apn' control={<Radio />} label='APN only' />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid container item xs={12} spacing={1} style={{ minHeight: '32vh' }}>
                          {selectedOption === 'address' && (
                            <>
                              <Grid item xs={6} className='relative'>
                                <label>Street Address</label>
                                <label className='required'>{'*'}</label>
                                <PlacesAutocomplete
                                  value={address}
                                  onChange={(newValue) => {
                                    setAddress(newValue);
                                    setIsAddressEmpty(newValue?.length ? false : true);
                                  }}
                                  onSelect={handleSelect}
                                >
                                  {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading,
                                  }) => (
                                    <div>
                                      <TextField
                                        {...getInputProps({ placeholder: 'Enter an address' })}
                                        fullWidth
                                        variant='outlined'
                                        error={isAddressEmpty}
                                        helperText={isAddressEmpty ? 'Address is required' : ''}
                                      />
                                      <div
                                        // className={
                                        //   suggestions?.length > 0 || loading ? 'address-list' : ''
                                        // }
                                        style={{
                                          position: 'absolute',
                                          boxShadow:
                                            '0 4px 3px rgba(0,0,0,0.1), 0 4px 3px rgba(0,0,0,0.1)',
                                          background: '#fff',
                                          zIndex: '999',
                                          // width: '28.5%',
                                          maxHeight: '300px',
                                          overflowY: 'auto',
                                        }}
                                      >
                                        {suggestions.map((suggestion) => {
                                          const style = {
                                            backgroundColor: suggestion.active
                                              ? '#ecf3f9'
                                              : 'white',
                                            padding: '10px 10px',
                                            fontSize: '13.5px',
                                          };
                                          return (
                                            <div
                                              key={suggestion.description}
                                              {...getSuggestionItemProps(suggestion, { style })}
                                            >
                                              {suggestion.description}
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  )}
                                </PlacesAutocomplete>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <label>Address 2</label>
                                <Controller
                                  name='Street Address'
                                  control={control}
                                  defaultValue=''
                                  rules={{ required: false }}
                                  render={({ field, fieldState }) => (
                                    <TextField
                                      {...field}
                                      variant='outlined'
                                      fullWidth
                                      error={!!fieldState.error}
                                      helperText={fieldState.error ? fieldState.error.message : ''}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} className='unit-no-field'>
                                <label>Unit Number</label>
                                <Controller
                                  name='unit_no'
                                  control={control}
                                  defaultValue=''
                                  render={({ field, fieldState }) => (
                                    // <TextField
                                    //   {...field}
                                    //   onChange={(e) => {
                                    //     field.onChange(e);
                                    //     setUnitNo(e.target.value);
                                    //   }}
                                    //   variant='outlined'
                                    //   fullWidth
                                    //   error={!!fieldState.error}
                                    //   helperText={fieldState.error ? fieldState.error.message : ''}
                                    // />
                                    <Autocomplete
                                      disablePortal
                                      style={{ padding: '0px' }}
                                      value={unitNo}
                                      options={addressOptions?.map((option) => {
                                        return {
                                          ...option,
                                          label: `${option?.sa_site_unit_val} ${option?.sa_owner_1_first} ${option?.sa_owner_1_last} ${option?.sa_parcel_nbr_primary}`,
                                        };
                                      })}
                                      renderOption={(props, option, { inputValue }) => {
                                        if (option?.header) {
                                          return (
                                            <li
                                              {...props}
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                padding: '14px 10px',
                                              }}
                                            >
                                              <div
                                                className='fw-500'
                                                style={{ width: '20%' }}
                                              >{`Unit`}</div>
                                              <div
                                                className='fw-500'
                                                style={{ width: '50%' }}
                                              >{`Owner Name`}</div>
                                              <div
                                                className='fw-500'
                                                style={{ width: '30%' }}
                                              >{`APN`}</div>
                                            </li>
                                          );
                                        }
                                        return (
                                          <li
                                            {...props}
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'space-between',
                                              padding: '4%',
                                              fontSize: '13px',
                                            }}
                                          >
                                            <div
                                              style={{ width: '20%', fontSize: '13.5px' }}
                                            >{`${option?.sa_site_unit_val}`}</div>
                                            <div
                                              style={{
                                                width: '50%',
                                                fontSize: '13.5px',
                                              }}
                                            >{`${option?.sa_owner_1_first} ${option?.sa_owner_1_last}`}</div>
                                            <div
                                              style={{ width: '30%', fontSize: '13.5px' }}
                                            >{`${option?.sa_parcel_nbr_primary}`}</div>
                                          </li>
                                        );
                                      }}
                                      disableClearable
                                      blurOnSelect={true}
                                      onChange={(_, value) => {
                                        if (value?.header) return;
                                        setUnitNo(value?.sa_site_unit_val);
                                        setPropertyId(value?.sa_property_id);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          error={!!fieldState.error}
                                          helperText={
                                            fieldState.error ? fieldState.error.message : ''
                                          }
                                        />
                                      )}
                                      fullWidth
                                    />
                                  )}
                                />
                                {address?.length && !unitNo?.length && propertyId?.length ? (
                                  <div className='f-13 color-p mt-4'>
                                    APN : {addressDetails?.data?.[0]?.sa_parcel_nbr_primary ?? '-'}
                                  </div>
                                ) : (
                                  ''
                                )}
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <label>City</label>
                                <label className='required'>{'*'}</label>
                                <Controller
                                  name='city'
                                  control={control}
                                  defaultValue=''
                                  rules={{ required: 'City is required' }}
                                  render={({ field, fieldState }) => (
                                    <TextField
                                      {...field}
                                      onChange={(e) => {
                                        field.onChange(e);
                                        setBool((last) => !last);
                                      }}
                                      variant='outlined'
                                      fullWidth
                                      error={!!fieldState.error}
                                      helperText={fieldState.error ? fieldState.error.message : ''}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <label>State</label>
                                <label className='required'>{'*'}</label>
                                <Controller
                                  name='state'
                                  control={control}
                                  defaultValue=''
                                  rules={{ required: 'State is required' }}
                                  render={({ field, fieldState }) => (
                                    <TextField
                                      {...field}
                                      onChange={(e) => {
                                        field.onChange(e);
                                        setBool((last) => !last);
                                      }}
                                      variant='outlined'
                                      fullWidth
                                      error={!!fieldState.error}
                                      helperText={fieldState.error ? fieldState.error.message : ''}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <label>ZIP code</label>
                                <label className='required'>{'*'}</label>
                                <Controller
                                  name='zipCode'
                                  control={control}
                                  defaultValue=''
                                  rules={{ required: 'ZIP code is required' }}
                                  render={({ field, fieldState }) => (
                                    <TextField
                                      {...field}
                                      variant='outlined'
                                      type='number'
                                      onChange={(e) => {
                                        field.onChange(e);
                                        setBool((last) => !last);
                                      }}
                                      InputProps={{ inputProps: { min: 1 } }}
                                      fullWidth
                                      error={!!fieldState.error}
                                      helperText={fieldState.error ? fieldState.error.message : ''}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <label>County</label>
                                <label className='required'>{'*'}</label>
                                <Controller
                                  name='county'
                                  control={control}
                                  defaultValue=''
                                  rules={{ required: 'County is required' }}
                                  render={({ field, fieldState }) => (
                                    <TextField
                                      {...field}
                                      variant='outlined'
                                      fullWidth
                                      error={!!fieldState.error}
                                      helperText={fieldState.error ? fieldState.error.message : ''}
                                    />
                                  )}
                                />
                              </Grid>
                            </>
                          )}
                          {selectedOption === 'apn' && (
                            <>
                              <Grid item xs={12} sm={6}>
                                <label>APN No</label>
                                <label className='required'>{'*'}</label>
                                <Controller
                                  name='apnNo'
                                  control={control}
                                  defaultValue=''
                                  rules={{ required: 'APN No. is required' }}
                                  render={({ field, fieldState }) => (
                                    <TextField
                                      {...field}
                                      variant='outlined'
                                      fullWidth
                                      error={!!fieldState.error}
                                      helperText={fieldState.error ? fieldState.error.message : ''}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <label>ZIP code</label>
                                <label className='required'>{'*'}</label>
                                <Controller
                                  name='zipCode'
                                  control={control}
                                  defaultValue=''
                                  rules={{ required: false }}
                                  render={({ field, fieldState }) => (
                                    <TextField
                                      {...field}
                                      variant='outlined'
                                      fullWidth
                                      type='number'
                                      error={!!fieldState.error}
                                      helperText={fieldState.error ? fieldState.error.message : ''}
                                    />
                                  )}
                                />
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Wrapper
                          apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                          style={{ height: '12px' }}
                        >
                          <div ref={mapRef} id='map' style={{ width: '100%', height: '100%' }} />
                        </Wrapper>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} style={{ marginTop: '8vh' }}>
                      <Grid item xs={4}>
                        <Button
                          onClick={() => {
                            navigate(isClientUser ? `/orders/order` : `/admin/orders`);
                          }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item xs={8} className='d-flex justify-end next-btn'>
                        <Button
                          endIcon={<ArrowRightIcon size={10} />}
                          onClick={() => {
                            onSubmitStep1();
                          }}
                        >
                          Next
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                  {/* <div style={{ display: `${activeStep === 1 ? 'unset' : 'none'}` }}>
                    <div className='form-title mb-8'>
                      <h4>Loan Information</h4>
                    </div>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4}>
                        <label>Last Recorded Deed</label>
                        <Controller 
                          name='loan_reference_number'
                          control={control}
                          defaultValue=''
                          render={({ field }) => <TextField {...field} fullWidth />}
                        />
                      </Grid>
                    </Grid>
                  </div> */}
                  <div style={{ display: `${activeStep === 1 ? 'unset' : 'none'}` }}>
                    {loading && <Loader />}
                    <div className='form-title' style={{ marginTop: '25px' }}>
                      <h4>Participant Details</h4>
                    </div>

                    {showEmailPart ? (
                      <Grid item xs={12}>
                        <label>Send form invite link to participant via email</label>
                        <Checkbox
                          checked={emailParticipantChecked}
                          onChange={(e) => {
                            if (!e.target.checked) {
                              setEmailChecked(false);
                            }
                            setEmailParticipantChecked(e.target.checked);
                          }}
                          color='primary'
                          size='small'
                        />
                      </Grid>
                    ) : (
                      ''
                    )}

                    {showEmailReminder && emailParticipantChecked ? (
                      <Grid item xs={12}>
                        <label>
                          Email reminders will be sent to participants based on client
                          configurations if this box is checked. Contact your administrator to
                          update the frequency of the reminders.
                        </label>
                        <Checkbox
                          checked={emailChecked}
                          onChange={(e) => {
                            setEmailChecked(e.target.checked);
                          }}
                          color='primary'
                          size='small'
                        />
                      </Grid>
                    ) : (
                      ''
                    )}
                    <Grid container spacing={1} style={{ minHeight: '60vh' }}>
                      <Grid
                        item
                        xs={6}
                        className='order-switch-label'
                        style={{ marginTop: '10px', marginBottom: '16px' }}
                      >
                        {!participants?.length ? (
                          <div className='error f-13'>
                            To proceed further, kindly add at least one participant.
                          </div>
                        ) : (
                          ''
                        )}
                      </Grid>

                      <Grid item xs={6} className='d-flex justify-end mt-8'>
                        <Button
                          type='button'
                          // onClick={addParticipant}
                          onClick={() => {
                            let types = filteredParticipantTypes;
                            // const participantIds = getValues('participants')?.map(
                            //   (participant) => participant.user_participant_type_id
                            // );
                            // let sellerId = participantList?.find(
                            //   (type) => type?.participant_type_name === 'Seller'
                            // )?.participant_type_id;
                            // const tempTypes = types?.filter(
                            //   (type) =>
                            //     type?.participant_type_id === sellerId ||
                            //     !participantIds.includes(type?.participant_type_id)
                            // );
                            setTypesForModal(types);
                            setIsParticipantTypeModalOpen(true);
                          }}
                          className='add-participant-btn'
                          variant='outlined'
                          color='primary'
                          style={{ height: '35px' }}
                        >
                          Add Participant
                        </Button>
                      </Grid>
                      {participants?.length ? (
                        participants.map((participant, index) => (
                          <Grid item xs={12} key={index} className='mt-16'>
                            <Grid container spacing={1} className='participant-block'>
                              <Grid item xs={6} key={index}>
                                <div className='d-flex justify-between align-items-center mt-8 mb-8'>
                                  <Chip
                                    color='primary'
                                    label={`Participant Type : ${
                                      participantList.find(
                                        (type) =>
                                          type.participant_type_id ==
                                          getValues(`participants`)?.[index]
                                            ?.user_participant_type_id
                                      )?.participant_type_name ?? '-'
                                    }`}
                                    style={{
                                      height: '25px',
                                      padding: '4px',
                                    }}
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={6} key={index}>
                                <div className='d-flex justify-end align-items-center mt-8 mb-8 toggle-label'>
                                  <span
                                    className='mr-16 f-14 fw-500 color-p'
                                    style={{
                                      color: participant?.is_individual
                                        ? '#005fd4'
                                        : 'rgb(186, 185, 185)',
                                    }}
                                  >
                                    Individual
                                  </span>
                                  <FormControlLabel
                                    control={
                                      <Controller
                                        name='is_participant_or_entity'
                                        control={control}
                                        defaultValue={!participant?.is_individual} // Set the initial value based on your requirement
                                        render={({}) => (
                                          <Switch
                                            checked={!participant?.is_individual}
                                            onChange={() => {
                                              setParticipants((oldParticipants) => {
                                                let tempParticipants = [...oldParticipants];
                                                tempParticipants[index].is_individual =
                                                  !tempParticipants[index].is_individual;
                                                return tempParticipants;
                                              });
                                            }}
                                            color='primary'
                                            size='small'
                                          />
                                        )}
                                      />
                                    }
                                    style={{
                                      minWidth: 'unset',
                                      fontSize: '14px',
                                      fontWeight: '500',
                                    }}
                                    className={`pt-8`}
                                    label='Entity'
                                  />
                                </div>
                              </Grid>
                              {!isSeller(`participants[${index}].user_participant_type_id`) &&
                                index === 0 && (
                                  <Grid item md={12} xs={12} sm={12}>
                                    <Grid container spacing={1}>
                                      <Grid item xs={12} sm={3}>
                                        <label>Last Recorded Deed</label>
                                        <Controller
                                          name='loan_reference_number'
                                          control={control}
                                          defaultValue=''
                                          render={({ field }) => <TextField {...field} fullWidth />}
                                        />
                                      </Grid>
                                      {docLink ? (
                                        <Grid item xs={12} sm={6} style={{ paddingTop: '31px' }}>
                                          <div
                                            className='mt-23 ml-8'
                                            style={{
                                              minWidth: 'unset',
                                              color: '#005fd4',
                                              textDecoration: 'underline',
                                              cursor: 'pointer',
                                              fontSize: '14px',
                                            }}
                                            onClick={() => {
                                              setUrl(docLink);
                                              setDocModalOpen(true);
                                            }}
                                          >
                                            Click here to pull last recorded {deedType} #{docNumber}{' '}
                                            {recordingDate}
                                          </div>
                                        </Grid>
                                      ) : (
                                        ''
                                      )}
                                    </Grid>
                                  </Grid>
                                )}
                              <Grid
                                container
                                spacing={1}
                                className='p-8'
                                display={!participant.is_individual ? 'none' : ''}
                              >
                                <Grid item sm={6} md={4} xs={12} xl={3}>
                                  <label>Participant First Name</label>
                                  <label className='required'>{'*'}</label>
                                  <Controller
                                    name={`participants[${index}].first_name`}
                                    control={control}
                                    defaultValue=''
                                    index={index}
                                    rules={{
                                      required: participant.is_individual
                                        ? 'First Name is required'
                                        : false,
                                    }}
                                    render={({ field, fieldState }) => (
                                      <>
                                        <TextField
                                          {...field}
                                          error={!!fieldState.error}
                                          helperText={
                                            fieldState.error ? fieldState.error.message : ''
                                          }
                                          index={index}
                                          fullWidth
                                        />
                                      </>
                                    )}
                                  />
                                </Grid>
                                <Grid item sm={6} md={4} xs={12} xl={3}>
                                  <label>Participant Last Name</label>
                                  <label className='required'>{'*'}</label>
                                  <Controller
                                    name={`participants[${index}].last_name`}
                                    control={control}
                                    rules={{
                                      required: participant.is_individual
                                        ? 'Last Name is required'
                                        : false,
                                    }}
                                    defaultValue=''
                                    render={({ field, fieldState }) => (
                                      <TextField
                                        {...field}
                                        fullWidth
                                        error={!!fieldState.error}
                                        helperText={
                                          fieldState.error ? fieldState.error.message : ''
                                        }
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item sm={6} md={4} xs={12} display={'none'} xl={3}>
                                  <label>Participant Type</label>
                                  <label className='required'>{'*'}</label>
                                  <Controller
                                    name={`participants[${index}].user_participant_type_id`}
                                    control={control}
                                    rules={{ required: 'Participant type is required' }}
                                    defaultValue=''
                                    render={({ field, fieldState }) => (
                                      <FormControl fullWidth>
                                        <Select {...field}>
                                          {filteredParticipantTypes?.map((item) => {
                                            return (
                                              <MenuItem value={item?.participant_type_id}>
                                                {item?.participant_type_name}
                                              </MenuItem>
                                            );
                                          })}
                                          {filteredParticipantTypes?.length === 0 && (
                                            <MenuItem value=''>
                                              Please select an order type first to proceed with
                                              adding participants or the selected order type may not
                                              be associated with any participants{' '}
                                            </MenuItem>
                                          )}
                                        </Select>
                                        <Typography className='order-error' color='error'>
                                          {fieldState.error ? fieldState.error.message : ''}
                                        </Typography>
                                      </FormControl>
                                    )}
                                  />
                                </Grid>
                                <Grid item sm={6} md={4} xs={12} xl={3}>
                                  <label>Social Security Number</label>
                                  <Controller
                                    name={`participants[${index}].ssn_number`}
                                    control={control}
                                    rules={{
                                      pattern: {
                                        value: /[0-9]{3}-[0-9]{2}-[0-9]{4}/,
                                        message: 'Invalid SSN format.',
                                      },
                                    }}
                                    defaultValue=''
                                    render={({ field: { ref, ...field }, fieldState }) => (
                                      <InputMask mask='999-99-9999' {...field}>
                                        {() => (
                                          <TextField
                                            {...field}
                                            fullWidth
                                            error={!!fieldState.error}
                                            helperText={
                                              fieldState.error ? fieldState.error.message : ''
                                            }
                                          />
                                        )}
                                      </InputMask>
                                    )}
                                  />
                                </Grid>
                                <Grid item sm={6} md={4} xs={12} xl={3}>
                                  <label>Email</label>
                                  <label className='required'>{'*'}</label>
                                  <Controller
                                    name={`participants[${index}].email`}
                                    control={control}
                                    rules={{
                                      required: 'Email is required',
                                      pattern: {
                                        value: /^\S+@\S+\.\S+$/,
                                        message: 'Invalid email format',
                                      },
                                    }}
                                    defaultValue=''
                                    render={({ field, fieldState }) => (
                                      <TextField
                                        {...field}
                                        fullWidth
                                        autoComplete='new-password'
                                        error={!!fieldState.error}
                                        helperText={
                                          fieldState.error ? fieldState.error.message : ''
                                        }
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item sm={6} md={4} xs={12} xl={3}>
                                  <label>Cell Phone</label>
                                  <Controller
                                    name={`participants[${index}].mobile_phone`}
                                    rules={{
                                      pattern: {
                                        value: /\(\d{3}\) \d{3}-\d{4}/, // Validate the phone number pattern
                                        message:
                                          'Invalid phone number format (e.g., (123) 456-7890)',
                                      },
                                    }}
                                    control={control}
                                    defaultValue=''
                                    render={({ field: { ref, ...field }, fieldState }) => (
                                      <InputMask mask={'(999) 999-9999'} {...field}>
                                        {() => (
                                          <TextField
                                            {...field}
                                            fullWidth
                                            autoFocus
                                            error={!!fieldState.error}
                                            helperText={
                                              fieldState.error ? fieldState.error.message : ''
                                            }
                                          />
                                        )}
                                      </InputMask>
                                    )}
                                  />
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={1}
                                className='p-8'
                                display={participant.is_individual ? 'none' : ''}
                              >
                                <Grid item sm={6} md={4} xs={12} xl={3}>
                                  <label>Legal Business Name</label>
                                  <label className='required'>{'*'}</label>
                                  <Controller
                                    name={`participants[${index}].business_name`}
                                    control={control}
                                    rules={{
                                      required: !participant.is_individual
                                        ? 'Legal Business is required'
                                        : false,
                                    }}
                                    defaultValue=''
                                    render={({ field, fieldState }) => (
                                      <TextField
                                        {...field}
                                        fullWidth
                                        error={!!fieldState.error}
                                        helperText={
                                          fieldState.error ? fieldState.error.message : ''
                                        }
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item sm={6} md={4} xs={12} xl={3}>
                                  <label>Entity Type</label>
                                  <Controller
                                    name={`participants[${index}].entity_type`}
                                    rules={{ required: false }}
                                    control={control}
                                    defaultValue=''
                                    render={({ field, fieldState }) => (
                                      <FormControl fullWidth>
                                        <Select {...field}>
                                          {ENTITY_TYPES.map((ent) => (
                                            <MenuItem value={ent}>{ent}</MenuItem>
                                          ))}
                                        </Select>
                                        <Typography className='order-error' color='error'>
                                          {fieldState.error ? fieldState.error.message : ''}
                                        </Typography>
                                      </FormControl>
                                    )}
                                  />
                                </Grid>
                                <Grid item sm={6} md={4} xs={12} xl={3}>
                                  <label>Entity State</label>
                                  <Controller
                                    name={`participants[${index}].entity_state`}
                                    rules={{ required: false }}
                                    control={control}
                                    defaultValue=''
                                    render={({ field, fieldState }) => (
                                      <FormControl fullWidth>
                                        <Select {...field}>
                                          {STATES.map((state) => (
                                            <MenuItem value={state}>{state}</MenuItem>
                                          ))}
                                        </Select>
                                        <Typography className='order-error' color='error'>
                                          {fieldState.error ? fieldState.error.message : ''}
                                        </Typography>
                                      </FormControl>
                                    )}
                                  />
                                </Grid>
                                <Grid item sm={6} md={4} xs={12} display={'none'} xl={3}>
                                  <label>Participant Type</label>
                                  <label className='required'>{'*'}</label>
                                  <Controller
                                    name={`participants[${index}].user_participant_type_id`}
                                    control={control}
                                    rules={{ required: 'Participant type is required' }}
                                    defaultValue=''
                                    render={({ field, fieldState }) => (
                                      <FormControl fullWidth>
                                        <Select {...field}>
                                          {filteredParticipantTypes?.map((item) => {
                                            return (
                                              <MenuItem value={item?.participant_type_id}>
                                                {item?.participant_type_name}
                                              </MenuItem>
                                            );
                                          })}
                                          {filteredParticipantTypes?.length === 0 && (
                                            <MenuItem value=''>
                                              Please select an order type first to proceed with
                                              adding participants or the selected order type may not
                                              be associated with any participants.
                                            </MenuItem>
                                          )}
                                        </Select>
                                        <Typography className='order-error' color='error'>
                                          {fieldState.error ? fieldState.error.message : ''}
                                        </Typography>
                                      </FormControl>
                                    )}
                                  />
                                </Grid>
                                <Grid item sm={6} md={4} xs={12} xl={3}>
                                  <label>Entity EIN</label>
                                  <Controller
                                    name={`participants[${index}].entity_ein`}
                                    control={control}
                                    rules={{ required: false }}
                                    defaultValue=''
                                    render={({ field: { ref, ...field }, fieldState }) => (
                                      <InputMask mask={'99-9999999'} {...field}>
                                        {() => (
                                          <TextField
                                            {...field}
                                            fullWidth
                                            autoFocus
                                            error={!!fieldState.error}
                                            helperText={
                                              fieldState.error ? fieldState.error.message : ''
                                            }
                                          />
                                        )}
                                      </InputMask>
                                    )}
                                  />
                                </Grid>
                                <Grid item sm={6} md={4} xs={12} xl={3}>
                                  <label>Contact First Name</label>
                                  <Controller
                                    name={`participants[${index}].contact_first_name`}
                                    control={control}
                                    rules={{ required: false }}
                                    defaultValue=''
                                    render={({ field, fieldState }) => (
                                      <TextField
                                        {...field}
                                        fullWidth
                                        error={!!fieldState.error}
                                        helperText={
                                          fieldState.error ? fieldState.error.message : ''
                                        }
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item sm={6} md={4} xs={12} xl={3}>
                                  <label>Contact Last Name</label>
                                  <Controller
                                    name={`participants[${index}].contact_last_name`}
                                    rules={{ required: false }}
                                    control={control}
                                    defaultValue=''
                                    render={({ field, fieldState }) => (
                                      <TextField
                                        {...field}
                                        fullWidth
                                        error={!!fieldState.error}
                                        helperText={
                                          fieldState.error ? fieldState.error.message : ''
                                        }
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item sm={6} md={4} xs={12} xl={3}>
                                  <label>Email</label>
                                  <label className='required'>{'*'}</label>
                                  <Controller
                                    name={`participants[${index}].email`}
                                    rules={{
                                      required: 'Email is required',
                                    }}
                                    control={control}
                                    defaultValue=''
                                    render={({ field, fieldState }) => (
                                      <TextField
                                        {...field}
                                        fullWidth
                                        autoComplete='new-password'
                                        error={!!fieldState.error}
                                        helperText={
                                          fieldState.error ? fieldState.error.message : ''
                                        }
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item sm={6} md={4} xs={12} xl={3}>
                                  <label>Cell Phone</label>
                                  <Controller
                                    name={`participants[${index}].mobile_phone`}
                                    rules={{
                                      pattern: {
                                        value: /\(\d{3}\) \d{3}-\d{4}/, // Validate the phone number pattern
                                        message:
                                          'Invalid phone number format (e.g., (123) 456-7890)',
                                      },
                                    }}
                                    control={control}
                                    defaultValue=''
                                    render={({ field: { ref, ...field }, fieldState }) => (
                                      <InputMask mask={'(999) 999-9999'} {...field}>
                                        {() => (
                                          <TextField
                                            {...field}
                                            fullWidth
                                            error={!!fieldState.error}
                                            helperText={
                                              fieldState.error ? fieldState.error.message : ''
                                            }
                                          />
                                        )}
                                      </InputMask>
                                    )}
                                  />
                                </Grid>
                              </Grid>
                              <Grid item={12} container spacing={1} className='address-section'>
                                <Grid
                                  item
                                  sm={12}
                                  xs={12}
                                  className='order-switch-label mt-12 mb-12'
                                >
                                  <span>Mailing Address</span>
                                  {(!isSeller(`participants[${index}].user_participant_type_id`) ||
                                    !isBorrower(
                                      `participants[${index}].user_participant_type_id`
                                    )) && (
                                    <>
                                      <span style={{ color: 'grey' }}>
                                        ( Same as subject property
                                      </span>
                                      <FormControlLabel
                                        control={
                                          <Controller
                                            name={`participants[${index}].is_address_same_as_order_property`}
                                            defaultValue={sameAddress[index]}
                                            control={control}
                                            render={({ field }) => (
                                              <Checkbox
                                                checked={sameAddress[index]}
                                                onChange={(e) => {
                                                  handleSameAddress(index, e.target.checked);
                                                }}
                                                color='primary'
                                                size='small'
                                              />
                                              // <Switch
                                              //   checked={sameAddress[index]}
                                              //   onChange={() => {
                                              //     handleSameAddress(index, sameAddress[index]);
                                              //   }}
                                              //   color='primary'
                                              //   size='small'
                                              // />
                                            )}
                                          />
                                        }
                                        className='pt-8'
                                        labelPlacement='start'
                                      />
                                      <span style={{ color: 'grey' }}>)</span>
                                    </>
                                  )}
                                </Grid>
                                {selectedOption === 'address' ? (
                                  <>
                                    <Grid container item sm={12} xs={12} spacing={1}>
                                      <Grid item xs={12} sm={3} className='relative'>
                                        <label>Street Address</label>
                                        <label className='required'>{'*'}</label>
                                        <PlacesAutocomplete
                                          // value={participantAddress?.[index]}
                                          value={getValues(`participants[${index}].address_line_1`)}
                                          onChange={(newValue) => {
                                            const updatedParticipantAddress = [
                                              ...participantAddress,
                                            ];
                                            updatedParticipantAddress[index] = newValue;
                                            setParticipantAddress(updatedParticipantAddress);
                                            setValue(
                                              `participants[${index}].address_line_1`,
                                              newValue
                                            );
                                          }}
                                          disabled={sameAddress?.[index]}
                                          onSelect={(e) => {
                                            handleParticipantSelect(e, index);
                                          }}
                                        >
                                          {({
                                            getInputProps,
                                            suggestions,
                                            getSuggestionItemProps,
                                            loading,
                                          }) => (
                                            <div>
                                              <TextField
                                                {...getInputProps({
                                                  placeholder: 'Enter an address',
                                                })}
                                                fullWidth
                                                disabled={sameAddress[index]}
                                                variant='outlined'
                                                rules={{ required: sameAddress[index] }}
                                                name={`participants[${index}].address_line_1`}
                                                autoComplete='new-password'
                                              />
                                              <div
                                                // className={
                                                //   suggestions?.length > 0 || loading
                                                //     ? 'address-list'
                                                //     : ''
                                                // }
                                                style={{
                                                  position: 'absolute',
                                                  boxShadow:
                                                    '0 4px 3px rgba(0,0,0,0.1), 0 4px 3px rgba(0,0,0,0.1)',
                                                  background: '#fff',
                                                  zIndex: '999',
                                                  // width: '28.5%',
                                                  maxHeight: '300px',
                                                  overflowY: 'auto',
                                                }}
                                              >
                                                {suggestions.map((suggestion) => {
                                                  const style = {
                                                    backgroundColor: suggestion.active
                                                      ? '#ecf3f9'
                                                      : 'white',
                                                    padding: '10px 10px',
                                                    fontSize: '13.5px',
                                                  };
                                                  return (
                                                    <div
                                                      key={suggestion.description}
                                                      {...getSuggestionItemProps(suggestion, {
                                                        style,
                                                      })}
                                                    >
                                                      {suggestion.description}
                                                    </div>
                                                  );
                                                })}
                                              </div>
                                            </div>
                                          )}
                                        </PlacesAutocomplete>
                                      </Grid>
                                      <Grid item xs={12} sm={3}>
                                        <label>Address 2</label>
                                        <Controller
                                          name={`participants[${index}].address_line_2`}
                                          control={control}
                                          disabled={sameAddress[index]}
                                          defaultValue=''
                                          rules={{ required: false }}
                                          render={({ field, fieldState }) => (
                                            <TextField
                                              {...field}
                                              variant='outlined'
                                              fullWidth
                                              error={!!fieldState.error}
                                              helperText={
                                                fieldState.error ? fieldState.error.message : ''
                                              }
                                            />
                                          )}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={3}>
                                        <label>Unit Number</label>
                                        <Controller
                                          name={`participants[${index}].unit_no`}
                                          control={control}
                                          defaultValue=''
                                          render={({ field, fieldState }) => (
                                            <TextField
                                              {...field}
                                              variant='outlined'
                                              fullWidth
                                              disabled={sameAddress[index]}
                                              error={!!fieldState.error}
                                              helperText={
                                                fieldState.error ? fieldState.error.message : ''
                                              }
                                            />
                                          )}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={3}>
                                        <label>City</label>
                                        <label className='required'>{'*'}</label>
                                        <Controller
                                          name={`participants[${index}].city`}
                                          control={control}
                                          defaultValue=''
                                          rules={{ required: 'City is required' }}
                                          render={({ field, fieldState }) => (
                                            <TextField
                                              {...field}
                                              variant='outlined'
                                              fullWidth
                                              disabled={sameAddress[index]}
                                              error={!!fieldState.error}
                                              helperText={
                                                fieldState.error ? fieldState.error.message : ''
                                              }
                                            />
                                          )}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={3}>
                                        <label>State</label>
                                        <label className='required'>{'*'}</label>
                                        <Controller
                                          name={`participants[${index}].state`}
                                          control={control}
                                          defaultValue=''
                                          rules={{ required: 'State is required' }}
                                          render={({ field, fieldState }) => (
                                            <TextField
                                              {...field}
                                              variant='outlined'
                                              fullWidth
                                              disabled={sameAddress[index]}
                                              error={!!fieldState.error}
                                              helperText={
                                                fieldState.error ? fieldState.error.message : ''
                                              }
                                            />
                                          )}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={3}>
                                        <label>ZIP Code</label>
                                        <label className='required'>{'*'}</label>
                                        <Controller
                                          name={`participants[${index}].zip_code`}
                                          control={control}
                                          defaultValue=''
                                          rules={{ required: 'ZIP code is required' }}
                                          render={({ field, fieldState }) => (
                                            <TextField
                                              {...field}
                                              type='number'
                                              variant='outlined'
                                              fullWidth
                                              disabled={sameAddress[index]}
                                              error={!!fieldState.error}
                                              helperText={
                                                fieldState.error ? fieldState.error.message : ''
                                              }
                                            />
                                          )}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={3}>
                                        <label>County</label>
                                        <Controller
                                          name={`participants[${index}].county`}
                                          control={control}
                                          defaultValue=''
                                          rules={{ required: false }}
                                          render={({ field, fieldState }) => (
                                            <TextField
                                              {...field}
                                              variant='outlined'
                                              fullWidth
                                              disabled={sameAddress[index]}
                                              error={!!fieldState.error}
                                              helperText={
                                                fieldState.error ? fieldState.error.message : ''
                                              }
                                            />
                                          )}
                                        />
                                      </Grid>
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Grid item xs={6} sm={3}>
                                      <label>APN No</label>
                                      <label className='required'>{'*'}</label>
                                      <Controller
                                        name={`participants[${index}].apn_no`}
                                        control={control}
                                        rules={{ required: false }}
                                        disabled={sameAddress[index]}
                                        defaultValue=''
                                        render={({ field }) => <TextField {...field} fullWidth />}
                                      />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                      <label>ZIP Code</label>
                                      <label className='required'>{'*'}</label>
                                      <Controller
                                        name={`participants[${index}].Zip_code`}
                                        rules={{ required: false }}
                                        control={control}
                                        disabled={sameAddress[index]}
                                        defaultValue=''
                                        render={({ field }) => (
                                          <TextField {...field} fullWidth type='number' />
                                        )}
                                      />
                                    </Grid>
                                  </>
                                )}
                              </Grid>

                              <Grid item xs={12} className='remove-participant-btn'>
                                <Button
                                  type='button'
                                  onClick={() => removeParticipant(index)}
                                  variant='outlined'
                                  color='secondary'
                                >
                                  <HighlightOffIcon />
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))
                      ) : (
                        <div style={{ minHeight: '250px' }}></div>
                      )}
                    </Grid>
                    <Grid container xs={12} spacing={1}>
                      <Grid container item xs={12}>
                        <Grid item xs={4}>
                          <Button
                            onClick={() => {
                              navigate(isClientUser ? `/orders/order` : `/admin/orders`);
                            }}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item xs={8} className='d-flex justify-end next-btn creat-order-btn'>
                          {activeStep !== 0 && (
                            <Button onClick={handleBack} startIcon={<ArrowLeftIcon />}>
                              Back
                            </Button>
                          )}
                          <Button
                            type='submit'
                            disabled={loading || !participants?.length}
                            endIcon={<ArrowRightIcon size={10} />}
                          >
                            Create Order
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                  {activeStep === 2 ? (
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} ref={divToPrintRef}>
                        <Alert severity='success' className='f-13'>
                          <AlertTitle className='f-14 fw-600'>
                            Order Created Successfully.
                          </AlertTitle>
                          We have sent an email that includes a verification link for verifying all
                          details to every participant associated with this order. Kindly check
                          below list of Invite links for all participants.
                        </Alert>
                        <div className='mt-16 fw-600 f-14 color-p'>Participant Invite Links</div>
                        <div style={{ minHeight: '45vh', marginTop: '16px' }}>
                          {/* <div className='f-13 fw-500 color-g mt-16'>
                            Here is Invite Links for all participants.
                          </div> */}
                          {orderDetails?.participants?.map((participant) => {
                            return (
                              <Grid container style={{ marginTop: '10px' }}>
                                <Grid
                                  item
                                  md={3}
                                  sm={3}
                                  xs={3}
                                  className='f-13 fw-500 p-8'
                                  border={'1px solid rgba(50, 50, 93, 0.25)'}
                                  borderRight={'0px'}
                                >
                                  {participant?.business_name
                                    ? participant?.business_name
                                    : participant?.first_name + ' ' + participant.last_name}
                                  <span className='color-g'>
                                    (
                                    {participant?.is_primary_participant
                                      ? 'Primary '
                                      : 'Additional '}
                                    {participant?.participant_type_name})
                                  </span>
                                </Grid>
                                <Grid
                                  item
                                  md={9}
                                  sm={9}
                                  xs={9}
                                  className='d-flex align-items-center f-13 p-8'
                                  border={'1px solid rgba(50, 50, 93, 0.25)'}
                                >
                                  <Tooltip
                                    title={
                                      !linkStatus[participant?.uuid]
                                        ? `The link will be active when data lookups are completed and compiled`
                                        : ''
                                    }
                                  >
                                    <div>
                                      <RouterLink
                                        className='mt-4 f-13-5 fw-500 cursor-pointer'
                                        style={{
                                          pointerEvents: !linkStatus[participant?.uuid]
                                            ? 'none'
                                            : 'unset',
                                          color: !linkStatus[participant?.uuid]
                                            ? 'grey'
                                            : '#005fd4',
                                        }}
                                        to={`/questions/invite/${participant?.uuid}`}
                                        target='_blank'
                                      >
                                        {`${domainName}/questions/invite/${participant?.uuid}`}
                                      </RouterLink>
                                    </div>
                                  </Tooltip>
                                  {!linkStatus[participant?.uuid] ? (
                                    <Tooltip title='Click to get latest status of invite link.'>
                                      <Button
                                        variant='outlined'
                                        style={{
                                          minWidth: '0px',
                                          color: 'orange',
                                          border: 'none',
                                        }}
                                        onClick={() => {
                                          getLinkStatus();
                                        }}
                                      >
                                        <Cached fontSize='small' />
                                      </Button>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title={'Copy to clipboard.'}>
                                      <ContentCopy
                                        style={{ color: 'grey', fontSize: '14px' }}
                                        className='ml-12 cursor-pointer'
                                        onClick={() => {
                                          copy(
                                            `${domainName}/questions/invite/${participant?.uuid}`
                                          );
                                          toast.success('Copied to clipboard!');
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                </Grid>
                                <Grid md={12}>
                                  {!linkStatus[participant?.uuid] ? (
                                    <Alert
                                      severity='info'
                                      className='f-13 mt-8 fw-450'
                                      style={{ padding: '2px 10px' }}
                                    >
                                      The participant invite link will be active once data lookups
                                      are completed and compiled. Click on the refresh button above
                                      to fetch the latest status of the link.
                                    </Alert>
                                  ) : (
                                    ''
                                  )}
                                </Grid>
                                {participant?.additional_participants?.map((addPart) => {
                                  return (
                                    <Grid container style={{ marginTop: '10px' }}>
                                      <Grid
                                        item
                                        md={3}
                                        sm={3}
                                        xs={3}
                                        className='f-13 fw-500 p-8'
                                        border={'1px solid rgba(50, 50, 93, 0.25)'}
                                        borderRight={'0px'}
                                      >
                                        {addPart?.full_name}
                                        <span className='color-g'>
                                          (Additional {participant?.participant_type_name})
                                        </span>
                                      </Grid>
                                      <Grid
                                        item
                                        md={9}
                                        sm={9}
                                        xs={9}
                                        className='d-flex align-items-center f-13 p-8'
                                        border={'1px solid rgba(50, 50, 93, 0.25)'}
                                      >
                                        <div className='color-p fw-500'>
                                          Invite will be sent upon completion of the primary{' '}
                                          {participant?.participant_type_name?.toLowerCase()}'s
                                          form.
                                        </div>
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            );
                          })}
                        </div>
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={8} className='d-flex justify-end'>
                          <div className='next-btn mt-8'>
                            <Tooltip title='Click here to go to list of orders.'>
                              <Button
                                // type='submit'
                                onClick={() => {
                                  setActiveStep(0);
                                  navigate(isClientUser ? `/orders/order` : `/admin/orders`);
                                }}
                              >
                                Go to Order's List
                              </Button>
                            </Tooltip>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )}
                </form>
              </FormProvider>
            </CardContent>
          </Box>
        </div>
      </Grid>
      <CancelDialog
        open={isCancelDialogOpen}
        onClose={handleCloseCancelDialog}
        onConfirm={handleConfirmCancel}
        heading='Cancel Order'
      />
      <ParticipantTypeModal
        open={isParticipantTypeModalOpen}
        setOpen={setIsParticipantTypeModalOpen}
        // options={filteredParticipantTypes}
        options={typesForModal}
        onSave={handleParticipantAdd}
      />
      <DocumentViewer
        open={docModalOpen}
        setOpen={setDocModalOpen}
        fileUrl={url}
        originalUrl={url}
        title={'Last Recorded Deed'}
      />
    </Box>
  );
};

export default AddOrder;
