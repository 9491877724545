export const DocElementType = Object.freeze({
  Media: 'M',
  File: 'F',
  TextField: 'T',
  TextArea: 'TA',
  SelectBox: 'S',
  SSelectBox: 'STATELOOKUP',
  RadioButton: 'R',
  SocialNumber: 'SS',
  DateField: 'D',
  Upload: 'U',
  Checkbox: 'C',
  Label: 'L',
  MediaLink: 'Z',
  DocumentLink: 'LK',
});

export const CLIENT_ADMIN_ROLE = 'client admin';
export const CLIENT_USER_ROLE = 'client user';
export const SUPER_ADMIN_ROLE = 'super admin';
export const OFFICE_ADMIN_ROLE = 'office admin';
export const OFFICE_USER_ROLE = 'office user';
export const ADMIN_ROLE = 'admin';

export const LIEN_SECTION_ID = '18c8f8bb-6c90-4000-852c-d0c59a105000';
export const UTILITY_SECTION_ID = '15058e5d-e475-4f17-8e58-c076dffb0263';
export const UTILITY_RADIO_ELEMENT_ID = 'bfa68573-fe48-4b0a-898a-4dbb83b5b92f';
export const AGENT_EMAIL_FIELD_ID = '76fce2a0-2303-4f0f-8297-d824420575f9';

export const TITLE_VESTED_NAME = 'borrower_field_98403';

export const secondParticipantFields = [
  'core_buyer_fullname2',
  'buyer_v2_field_35303',
  'buyer_v2_field_31427',
  'buyer_v2_field_13711',
  'buyer_v2_field_35937',
  'buyer_v2_field_40439',
  'buyer_v2_field_25344',
  'buyer_v2_field_42164',
  'buyer_v2_field_15194',
  'buyer_v2_field_30317',
  'core_borrower_fullname2',
  'core_borrower_phone2',
  'borrower_v2_field_97372',
  'borrower_v2_field_72972',
  'borrower_v2_field_11122',
  'borrower_v2_field_45173',
  'borrower_v2_field_79431',
  'borrower_v2_field_23381',
];

export const additionalSellerFields = [
  'seller_field_23088',
  'seller_field_40229',
  'seller_field_40645',
  'seller_field_30579',
  'seller_field_65877',
];

export const spouseFields = [
  'core_spouse_first_name',
  'core_spouse_last_name',
  'core_spouse_mobile',
  'core_spouse_email',
  'core_spouse_ssn',
];

export const HOA_SECTION_ID = '57e52e39-c25d-461e-8128-2bd66ef03125';
export const CREDIT_CARD_PARENT_ID = '18ca9a49-0390-4000-8b12-903f82748c00';

export const STATES = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
];

export const ENTITY_TYPES = ['Corporation', 'LLC', 'Trust', 'Partnership', 'Other'];
export const SOI_SECTION_ID = 'c22663ed-489b-454a-9d63-5b77579b5134';
export const MARITAL_STATUS_GROUP = 'fbebecf1-6bc0-46ba-a8ad-27a178b1a37c';
export const ADDITIONAL_SELLER_PARENT_ID = '84edef03-c02d-491d-96e1-0996fcb0db65';
export const ADDITIONAL_SELLER_GROUP_ID = '711ccb04-0bd8-4f16-aace-cfb43086897f';
export const ADDITIONAL_SELLER_OPTION_ID = 'cadc2623-8e28-4000-a217-6754798c2369';
export const SPOUSE_ADDED_FIELD_ID = '7a31928c-2363-4539-b546-49181c13898c';

export const HOA_PARENT_ID = '62bcec3b-bed4-45c2-9582-83c68bf586ef';
export const HOA_GROUP_ID = 'b373fc51-e22d-45d0-bcdc-7b7ea42e1bf3';
export const HOA_MAIN_PARENT_IDX = 'd0f7f9de-1b9e-4792-895a-604a43759b3f';
export const HOA_OPTION_ID = '228de1ce-c3f5-452f-b2f4-6404ccc4c305';

export const BUYER_SECTION = '18bf5b7b-31d0-4000-89a3-14354c485700';
export const BUYER_GROUP = '18bf5bc4-db50-4000-840b-8f3687f42500';
export const BUYER_PARENT = '18bf5d33-8ba0-4000-8349-86726cf91500';
export const BUYER_PARENT_OPTION = '18bf5d3d-7a60-4000-8be6-9d444703f100';
export const BUYER_INDIVIDUAL_ENTITY = '194f3f72-6350-4000-814f-d71dd8149d00';

export const LOAN_NUMBER_1 = 'e894ca92-a22d-4867-98cd-a4cab955f01c';
export const LOAN_NUMBER_2 = 'b666fd10-5e36-46da-a221-d648d7fa4af1';
export const LENDER_INFO_ID = '85155e4b-7ca8-4829-a9c9-3ce8271cb0cb';
export const LENDER_GROUP = '2d7023f5-6200-49a9-aef0-2b74162006cc';
export const MAIN_LOAN_ELEM = 'f7ed7412-07e3-4b4a-99c4-8ed269c41879';
export const LOAN_2 = '86e017bf-46bf-47ae-bfba-36cfd2f37cec';

export const LOAN_UPLOAD_NAME = 'core_last_statement';
export const LENDER_NAME = 'core_lender_company';
export const LOAN_NUMBER_NAME = 'core_lender_loannum';
export const LENDER_PHONE_NAME = 'core_lender_phone';
export const FIELDS_CHECK_VALIDATIONS = [LENDER_NAME, LOAN_NUMBER_NAME, LENDER_PHONE_NAME];

export const LENDER_INFO_IDX = 2;

export const BORROWER_LENDER_SECTION = '18bf5f2a-c130-4000-873d-e58c37859200';
export const BORROWER_LOAN_2 = '18bf5fcf-c1b0-4000-8c92-e6bf020eb200';

export const POA_ID = '8ed55c31-e12c-4e51-aaa9-1cd75c8be0f5';

export const SUPER_ADMIN_EMAIl = 'superadmin@mailinator.com';

export const ROLES_FOR_SUPER_ADMIN = [
  { name: 'client admin', label: 'Client Admin' },
  { name: 'client user', label: 'Client User' },
  { name: 'office admin', label: 'Office Admin' },
  { name: 'office user', label: 'Office User' },
];

export const ROLES_FOR_CLIENT_ADMIN = [
  { name: 'office admin', label: 'Office Admin' },
  { name: 'office user', label: 'Office User' },
];

export const SSN_FIELDS = [
  'core_ssn',
  'core_spouse_ssn',
  'seller_ssn',
  'borrower_ssn',
  'borrower_spouse_ssn',
  'borrower_ssn',
  'core_tid',
  'e_core_tax_id',
  'borrower_tid',
  'borrower_core_tax_id',
  'seller_entity_ein',
];

export const languages = [
  { label: 'Afrikaans', value: 'af' },
  { label: 'Albanian', value: 'sq' },
  { label: 'Amharic', value: 'am' },
  { label: 'Arabic', value: 'ar' },
  { label: 'Armenian', value: 'hy' },
  { label: 'Azerbaijani', value: 'az' },
  { label: 'Basque', value: 'eu' },
  { label: 'Belarusian', value: 'be' },
  { label: 'Bengali', value: 'bn' },
  { label: 'Bosnian', value: 'bs' },
  { label: 'Bulgarian', value: 'bg' },
  { label: 'Catalan', value: 'ca' },
  { label: 'Cebuano', value: 'ceb' },
  { label: 'Chinese (Simplified)', value: 'zh-CN' },
  { label: 'Chinese (Traditional)', value: 'zh-TW' },
  { label: 'Corsican', value: 'co' },
  { label: 'Croatian', value: 'hr' },
  { label: 'Czech', value: 'cs' },
  { label: 'Danish', value: 'da' },
  { label: 'Dutch', value: 'nl' },
  { label: 'English', value: 'en' },
  { label: 'Esperanto', value: 'eo' },
  { label: 'Estonian', value: 'et' },
  { label: 'Finnish', value: 'fi' },
  { label: 'French', value: 'fr' },
  { label: 'Frisian', value: 'fy' },
  { label: 'Galician', value: 'gl' },
  { label: 'Georgian', value: 'ka' },
  { label: 'German', value: 'de' },
  { label: 'Greek', value: 'el' },
  { label: 'Gujarati', value: 'gu' },
  { label: 'Haitian Creole', value: 'ht' },
  { label: 'Hausa', value: 'ha' },
  { label: 'Hawaiian', value: 'haw' },
  { label: 'Hebrew', value: 'he' },
  { label: 'Hindi', value: 'hi' },
  { label: 'Hmong', value: 'hmn' },
  { label: 'Hungarian', value: 'hu' },
  { label: 'Icelandic', value: 'is' },
  { label: 'Igbo', value: 'ig' },
  { label: 'Indonesian', value: 'id' },
  { label: 'Irish', value: 'ga' },
  { label: 'Italian', value: 'it' },
  { label: 'Japanese', value: 'ja' },
  { label: 'Vietnamese', value: 'vi' },
  { label: 'Spanish', value: 'es' },
  // still need to convert in static content
  { label: 'Javanese', value: 'jv' },
  { label: 'Kannada', value: 'kn' },
  { label: 'Kazakh', value: 'kk' },
  { label: 'Khmer', value: 'km' },
  { label: 'Kinyarwanda', value: 'rw' },
  { label: 'Korean', value: 'ko' },
  { label: 'Kurdish (Kurmanji)', value: 'ku' },
  { label: 'Kyrgyz', value: 'ky' },
  { label: 'Lao', value: 'lo' },
  { label: 'Latin', value: 'la' },
  { label: 'Latvian', value: 'lv' },
  { label: 'Lithuanian', value: 'lt' },
  { label: 'Luxembourgish', value: 'lb' },
  { label: 'Macedonian', value: 'mk' },
  { label: 'Malagasy', value: 'mg' },
  { label: 'Malay', value: 'ms' },
  { label: 'Malayalam', value: 'ml' },
  { label: 'Maltese', value: 'mt' },
  { label: 'Maori', value: 'mi' },
  { label: 'Marathi', value: 'mr' },
  { label: 'Mongolian', value: 'mn' },
  { label: 'Myanmar (Burmese)', value: 'my' },
  { label: 'Nepali', value: 'ne' },
  { label: 'Norwegian', value: 'no' },
  { label: 'Nyanja (Chichewa)', value: 'ny' },
  { label: 'Odia (Oriya)', value: 'or' },
  { label: 'Pashto', value: 'ps' },
  { label: 'Persian', value: 'fa' },
  { label: 'Polish', value: 'pl' },
  { label: 'Portuguese (Portugal, Brazil)', value: 'pt' },
  { label: 'Punjabi', value: 'pa' },
  { label: 'Romanian', value: 'ro' },
  { label: 'Russian', value: 'ru' },
  { label: 'Samoan', value: 'sm' },
  { label: 'Scots Gaelic', value: 'gd' },
  { label: 'Serbian', value: 'sr' },
  { label: 'Sesotho', value: 'st' },
  { label: 'Shona', value: 'sn' },
  { label: 'Sindhi', value: 'sd' },
  { label: 'Sinhala (Sinhalese)', value: 'si' },
  { label: 'Slovak', value: 'sk' },
  { label: 'Slovenian', value: 'sl' },
  { label: 'Somali', value: 'so' },
  { label: 'Sundanese', value: 'su' },
  { label: 'Swahili', value: 'sw' },
  { label: 'Swedish', value: 'sv' },
  { label: 'Tagalog (Filipino)', value: 'tl' },
  { label: 'Tajik', value: 'tg' },
  { label: 'Tamil', value: 'ta' },
  { label: 'Tatar', value: 'tt' },
  { label: 'Telugu', value: 'te' },
  { label: 'Thai', value: 'th' },
  { label: 'Turkish', value: 'tr' },
  { label: 'Turkmen', value: 'tk' },
  { label: 'Ukrainian', value: 'uk' },
  { label: 'Urdu', value: 'ur' },
  { label: 'Uyghur', value: 'ug' },
  { label: 'Uzbek', value: 'uz' },
  { label: 'Welsh', value: 'cy' },
  { label: 'Xhosa', value: 'xh' },
  { label: 'Yiddish', value: 'yi' },
  { label: 'Yoruba', value: 'yo' },
  { label: 'Zulu', value: 'zu' },
];
export const dlSectionIds = [
  'c22663ed-489b-454a-9d63-5b77579b5134',
  'c22663ed-489b-454a-9d63-5b77579b5134',
  '18bf5b7b-31d0-4000-89a3-14354c485700',
];

export const primaryDlFields = ['0421c07b-8c0c-4f0b-82d1-6fe45e2e0007'];

export const DL_FIELD_NUMBER = '_driver_lic';
export const DL_FIELD_STATE = '_driver_lic_state';
export const DL_FIELD_DOB = '_date_of_birth';
export const ADDITIONAL_SELLER_TYPE = '_type';
export const ADDITIONAL_SELLER_FIRST_NAME = '_first_name';
export const ADDITIONAL_SELLER_LAST_NAME = '_last_name';

export const DL_NUMBER_FIELD = '0421c07b-8c0c-4f0b-82d1-6fe45e2e0007';
export const DL_STATE_FIELD = '0104abf4-2633-47c9-a217-62c6f476bb6b';
export const BUYER_DL_STATE_FIELD = '194f39a5-a770-4000-832d-b43f09378400';
export const DATE_OF_BIRTH = '7d77a9a9-6365-4882-839a-a8566b9bb045';
export const BUYER_BATE_OF_BIRTH = '18bf5d1d-7480-4000-8a4b-e03bedf32e00';
export const SELLER_FIRST_NAME = 'e1a08d42-7169-440d-89e9-cc1649033804';
export const BORROWER_FIRST_NAME = 'e1a08d42-7169-440d-89e9-cc1649033804';
export const SELLER_LAST_NAME = '8c1ae17a-258e-4bbd-b7cf-b5a30df43e2f';
export const BORROWER_LAST_NAME = '8c1ae17a-258e-4bbd-b7cf-b5a30df43e2f';
export const BUYER_FULL_NAME_ID = '18bf5cb5-0580-4000-8859-a024f878d100';

export const IS_INDIVIDUAL_ENTITY = '02a56cad-f7ae-445c-996a-ac7dabbfa495';

export const MAILING_ADDRESS_QUESTION = '8d961be3-a84d-40b2-998c-d052dbc91415';
export const MAILING_ADDRESS_ADDRESS = '73fdef5b-54b0-4304-b3a7-9f365acccfb0';
export const MAILING_ADDRESS_CITY = 'de1c982f-1ba8-4795-aeb6-d4a341b2aa92';
export const MAILING_ADDRESS_STATE = '49a30dc5-65ab-4f66-9887-2a6f9ef80860';
export const MAILING_ADDRESS_ZIP = '80e2ba9b-6236-4ed1-a5da-e6b1460169c7';

export const DL_VERIFICATION_FIELDS = [
  DL_NUMBER_FIELD,
  DL_STATE_FIELD,
  SELLER_FIRST_NAME,
  SELLER_LAST_NAME,
  MAILING_ADDRESS_ADDRESS,
  MAILING_ADDRESS_CITY,
  MAILING_ADDRESS_STATE,
  MAILING_ADDRESS_ZIP,
  DATE_OF_BIRTH,
  BUYER_BATE_OF_BIRTH,
  BUYER_DL_STATE_FIELD,
];

export const STATE_FIELDS = [
  '0104abf4-2633-47c9-a217-62c6f476bb6b',
  '3c87f997-50fd-4139-8cf0-498423bccfba',
  '49a30dc5-65ab-4f66-9887-2a6f9ef80860',
  'e4bfa203-2bff-49a0-b8c3-f19bd4a59d9',
  '59bd2867-91e8-46f1-aa3e-56cb2d85abb0',
  '889dd184-3d56-4cce-970c-ca090f4d76b6',
  'e5cfca34-20a4-46aa-a9b0-466cfc591d42',
  '15feaabd-250e-40a3-b667-7e8990ff9e1f',
  'a6e5bbe6-46fd-4bf3-80b3-70c90f11d9e7',
];

export const sampleDataSetCSV = (officeList) => [
  {
    'Office Name': officeList?.[0] ?? '',
    'Order Type': 'purchase',
    'Escrow Number': '123-Escrow',
    'Anticipated Close Date': '09/13/2024',
    'Property Address Line 1': '123 Main Street',
    'Property Address Line 2': '',
    'Property Unit Number': '',
    'Property City': 'Irvine',
    'Property State': 'Ca',
    'Property ZIP Code': 92603,
    'Property County': 'Orange',
    'Number of participant': 3,
    'Multiple Participant Form': 'Separate',
    'Participant1 Category': 'individual',
    'Participant1 Type': 'seller',
    'Participant1 First Name': 'John',
    'Participant1 Last Name': 'Doe',
    'Participant1 Social Security Number': '111-11-1111',
    'Participant1 Email': 'john.doe@mailinator.com',
    'Participant1 Phone': '(111) 111-1111',
    'Participant1 Legal Business Name': '',
    'Participant1 Entity Type': '',
    'Participant1 Entity State': '',
    'Participant1 Entity EIN': '',
    'Participant1 mailing address same as property address': 'yes',
    'Participant1 mailing Address line 1': '',
    'Participant1 mailing Address line 2': '',
    'Participant1 mailing Unit Number': '',
    'Participant1 mailing City': '',
    'Participant1 mailing State': '',
    'Participant1 mailing ZIP Code': '',
    'Participant1 mailing County': '',
    'Participant2 Category': 'individual',
    'Participant2 Type': 'seller',
    'Participant2 First Name': 'Maria',
    'Participant2 Last Name': 'Barbis',
    'Participant2 Social Security Number': '111-11-1111',
    'Participant2 Email': 'maria@mailinator.com',
    'Participant2 Phone': '(111) 111-1111',
    'Participant2 Legal Business Name': '',
    'Participant2 Entity Type': '',
    'Participant2 Entity State': '',
    'Participant2 Entity EIN': '',
    'Participant2 mailing address same as property address': 'yes',
    'Participant2 mailing Address line 1': '',
    'Participant2 mailing Address line 2': '',
    'Participant2 mailing Unit Number': '',
    'Participant2 mailing City': '',
    'Participant2 mailing State': '',
    'Participant2 mailing ZIP Code': '',
    'Participant2 mailing County': '',
    'Participant3 Category': 'individual',
    'Participant3 Type': 'buyer',
    'Participant3 First Name': 'John',
    'Participant3 Last Name': 'Carter',
    'Participant3 Social Security Number': '111-11-1111',
    'Participant3 Email': 'john.carter@mailinator.com',
    'Participant3 Phone': '(111) 111-1111',
    'Participant3 Legal Business Name': '',
    'Participant3 Entity Type': '',
    'Participant3 Entity State': '',
    'Participant3 Entity EIN': '',
    'Participant3 mailing address same as property address': 'yes',
    'Participant3 mailing Address line 1': '',
    'Participant3 mailing Address line 2': '',
    'Participant3 mailing Unit Number': '',
    'Participant3 mailing City': '',
    'Participant3 mailing State': '',
    'Participant3 mailing ZIP Code': '',
    'Participant3 mailing County': '',
  },
  {
    'Office Name': officeList?.[0] ?? '',
    'Order Type': 'purchase',
    'Escrow Number': '1235-Escrow',
    'Anticipated Close Date': '09/13/2024',
    'Property Address Line 1': '123 Main Street',
    'Property Address Line 2': '',
    'Property Unit Number': '',
    'Property City': 'Bennington',
    'Property State': 'Ne',
    'Property ZIP Code': 68007,
    'Property County': 'Douglas',
    'Number of participant': 2,
    'Multiple Participant Form': 'Single',
    'Participant1 Category': 'entity',
    'Participant1 Type': 'seller',
    'Participant1 First Name': '',
    'Participant1 Last Name': '',
    'Participant1 Social Security Number': '111-11-1111',
    'Participant1 Email': 'voxtur.corp@mailinator.com',
    'Participant1 Phone': '(111) 111-1111',
    'Participant1 Legal Business Name': 'Voxtur',
    'Participant1 Entity Type': 'Corporation',
    'Participant1 Entity State': 'CA',
    'Participant1 Entity EIN': '',
    'Participant1 mailing address same as property address': 'no',
    'Participant1 mailing Address line 1': '1234 Main Street',
    'Participant1 mailing Address line 2': '',
    'Participant1 mailing Unit Number': '',
    'Participant1 mailing City': 'Murrieta',
    'Participant1 mailing State': 'Ca',
    'Participant1 mailing ZIP Code': 92562,
    'Participant1 mailing County': '',
    'Participant2 Category': 'entity',
    'Participant2 Type': 'seller',
    'Participant2 First Name': '',
    'Participant2 Last Name': '',
    'Participant2 Social Security Number': '111-11-1111',
    'Participant2 Email': 'tek.corp@mailinator.com',
    'Participant2 Phone': '(111) 111-1111',
    'Participant2 Legal Business Name': 'Tek corporation',
    'Participant2 Entity Type': 'Corporation',
    'Participant2 Entity State': 'CA',
    'Participant2 Entity EIN': '',
    'Participant2 mailing address same as property address': 'no',
    'Participant2 mailing Address line 1': '12 Main Street',
    'Participant2 mailing Address line 2': '',
    'Participant2 mailing Unit Number': '',
    'Participant2 mailing City': 'Murrieta',
    'Participant2 mailing State': 'Ca',
    'Participant2 mailing ZIP Code': 92562,
    'Participant2 mailing County': '',
    'Participant3 Category': '',
    'Participant3 Type': '',
    'Participant3 First Name': '',
    'Participant3 Last Name': '',
    'Participant3 Social Security Number': '',
    'Participant3 Email': '',
    'Participant3 Phone': '',
    'Participant3 Legal Business Name': '',
    'Participant3 Entity Type': '',
    'Participant3 Entity State': '',
    'Participant3 Entity EIN': '',
    'Participant3 mailing address same as property address': '',
    'Participant3 mailing Address line 1': '',
    'Participant3 mailing Address line 2': '',
    'Participant3 mailing Unit Number': '',
    'Participant3 mailing City': '',
    'Participant3 mailing State': '',
    'Participant3 mailing ZIP Code': '',
    'Participant3 mailing County': '',
  },
  {
    'Office Name': officeList?.[0] ?? '',
    'Order Type': 'refinance',
    'Escrow Number': '1232-Escrow',
    'Anticipated Close Date': '09/13/2024',
    'Property Address Line 1': '123 Main Street',
    'Property Address Line 2': '',
    'Property Unit Number': '',
    'Property City': 'Irvine',
    'Property State': 'Ca',
    'Property ZIP Code': 92603,
    'Property County': 'Orange',
    'Number of participant': 1,
    'Multiple Participant Form': 'Single',
    'Participant1 Category': 'individual',
    'Participant1 Type': 'borrower',
    'Participant1 First Name': 'John',
    'Participant1 Last Name': 'Carter',
    'Participant1 Social Security Number': '111-11-2222',
    'Participant1 Email': 'john.carter@gmail.com',
    'Participant1 Phone': '(111) 111-2222',
    'Participant1 Legal Business Name': '',
    'Participant1 Entity Type': '',
    'Participant1 Entity State': '',
    'Participant1 Entity EIN': '',
    'Participant1 mailing address same as property address': 'no',
    'Participant1 mailing Address line 1': '23355 Bishop Rd',
    'Participant1 mailing Address line 2': '',
    'Participant1 mailing Unit Number': '',
    'Participant1 mailing City': 'Murrieta',
    'Participant1 mailing State': 'Ca',
    'Participant1 mailing ZIP Code': 92562,
    'Participant1 mailing County': '',
    'Participant2 Category': '',
    'Participant2 Type': '',
    'Participant2 First Name': '',
    'Participant2 Last Name': '',
    'Participant2 Social Security Number': '',
    'Participant2 Email': '',
    'Participant2 Phone': '',
    'Participant2 Legal Business Name': '',
    'Participant2 Entity Type': '',
    'Participant2 Entity State': '',
    'Participant2 Entity EIN': '',
    'Participant2 mailing address same as property address': '',
    'Participant2 mailing Address line 1': '',
    'Participant2 mailing Address line 2': '',
    'Participant2 mailing Unit Number': '',
    'Participant2 mailing City': '',
    'Participant2 mailing State': '',
    'Participant2 mailing ZIP Code': '',
    'Participant2 mailing County': '',
    'Participant3 Category': '',
    'Participant3 Type': '',
    'Participant3 First Name': '',
    'Participant3 Last Name': '',
    'Participant3 Social Security Number': '',
    'Participant3 Email': '',
    'Participant3 Phone': '',
    'Participant3 Legal Business Name': '',
    'Participant3 Entity Type': '',
    'Participant3 Entity State': '',
    'Participant3 Entity EIN': '',
    'Participant3 mailing address same as property address': '',
    'Participant3 mailing Address line 1': '',
    'Participant3 mailing Address line 2': '',
    'Participant3 mailing Unit Number': '',
    'Participant3 mailing City': '',
    'Participant3 mailing State': '',
    'Participant3 mailing ZIP Code': '',
    'Participant3 mailing County': '',
  },
];

export const sampleDataSetCSVPhh = (officeList) => [
  {
    'Office Name': officeList?.[0] ?? '',
    'Order Type': 'refinance',
    'Escrow Number': '123-Escrow',
    'Anticipated Close Date': '09/13/2024',
    'Property Address Line 1': '123 Main Street',
    'Property Address Line 2': '',
    'Property Unit Number': '',
    'Property City': 'Irvine',
    'Property State': 'Ca',
    'Property ZIP Code': 92603,
    'Property County': 'Orange',
    'Number of participant': 1,
    'Participant1 Category': 'individual',
    'Participant1 Type': 'borrower',
    'Participant1 First Name': 'John',
    'Participant1 Last Name': 'Doe',
    'Participant1 Social Security Number': '111-11-1111',
    'Participant1 Email': 'john@doe.com',
    'Participant1 Phone': '(111) 111-1111',
    'Participant1 Legal Business Name': '',
    'Participant1 Entity Type': '',
    'Participant1 Entity State': '',
    'Participant1 Entity EIN': '',
    'Participant1 mailing address same as property address': 'yes',
    'Participant1 mailing Address line 1': '',
    'Participant1 mailing Address line 2': '',
    'Participant1 mailing Unit Number': '',
    'Participant1 mailing City': '',
    'Participant1 mailing State': '',
    'Participant1 mailing ZIP Code': '',
    'Participant1 mailing County': '',
    'Participant1 routing number': '122199983',
    'Participant1 bank account number': '923305129348',
    'Participant1 name on account': 'John Doe',
  },
  {
    'Office Name': officeList?.[0] ?? '',
    'Order Type': 'refinance',
    'Escrow Number': '1232-Escrow',
    'Anticipated Close Date': '09/13/2024',
    'Property Address Line 1': '123 Main Street',
    'Property Address Line 2': '',
    'Property Unit Number': '',
    'Property City': 'Irvine',
    'Property State': 'Ca',
    'Property ZIP Code': 92603,
    'Property County': 'Orange',
    'Number of participant': 1,
    'Participant1 Category': 'individual',
    'Participant1 Type': 'borrower',
    'Participant1 First Name': 'John',
    'Participant1 Last Name': 'Carter',
    'Participant1 Social Security Number': '111-11-2222',
    'Participant1 Email': 'john@carter.com',
    'Participant1 Phone': '(111) 111-2222',
    'Participant1 Legal Business Name': '',
    'Participant1 Entity Type': '',
    'Participant1 Entity State': '',
    'Participant1 Entity EIN': '',
    'Participant1 mailing address same as property address': 'no',
    'Participant1 mailing Address line 1': '1234 Main Street',
    'Participant1 mailing Address line 2': '',
    'Participant1 mailing Unit Number': '',
    'Participant1 mailing City': 'Murrieta',
    'Participant1 mailing State': 'Ca',
    'Participant1 mailing ZIP Code': 92562,
    'Participant1 mailing County': '',
    'Participant1 routing number': '122199983',
    'Participant1 bank account number': '923305129348',
    'Participant1 name on account': 'John Carter',
  },
  {
    'Office Name': officeList?.[0] ?? '',
    'Order Type': 'refinance',
    'Escrow Number': '1235-Escrow',
    'Anticipated Close Date': '09/13/2024',
    'Property Address Line 1': '123 Main Street',
    'Property Address Line 2': '',
    'Property Unit Number': '',
    'Property City': 'Bennington',
    'Property State': 'Ne',
    'Property ZIP Code': 68007,
    'Property County': 'Douglas',
    'Number of participant': 1,
    'Participant1 Category': 'entity',
    'Participant1 Type': 'borrower',
    'Participant1 First Name': '',
    'Participant1 Last Name': '',
    'Participant1 Social Security Number': '111-11-1111',
    'Participant1 Email': 'entity@email.com',
    'Participant1 Phone': '(111) 111-1111',
    'Participant1 Legal Business Name': 'Voxtur',
    'Participant1 Entity Type': 'Corporation',
    'Participant1 Entity State': 'CA',
    'Participant1 Entity EIN': '',
    'Participant1 mailing address same as property address': 'no',
    'Participant1 mailing Address line 1': '1234 Main Street',
    'Participant1 mailing Address line 2': '',
    'Participant1 mailing Unit Number': '',
    'Participant1 mailing City': 'Murrieta',
    'Participant1 mailing State': 'Ca',
    'Participant1 mailing ZIP Code': 92562,
    'Participant1 mailing County': '',
    'Participant1 routing number': '122199983',
    'Participant1 bank account number': '923305129348',
    'Participant1 name on account': 'Steaven Smith',
  },
];

export const guideTableData = (officeList) => [
  {
    column_name: 'Office Name',
    possible_values: officeList?.length
      ? `${officeList?.map((ofc) => `"${ofc}"`)?.join(' or ')}.`
      : `Your client has only one office you can keep this field as blank we will manage it internally.`,
    required: 'Required',
    example: officeList?.[0] ?? '-',
  },
  {
    column_name: 'Order Type',
    possible_values: `Either "refinance" or "purchase".`,
    required: 'Required',
    example: 'purchase',
  },
  {
    column_name: 'Escrow Number',
    possible_values: `Unique string for each order.`,
    required: 'Required',
    example: 'VSS-21231231',
  },
  {
    column_name: 'Anticipated Close Date',
    possible_values: `Date in the "MM/DD/YYYY" format.`,
    required: 'Required',
    example: '09/31/2024',
  },
  {
    column_name: 'Property Address Line 1',
    possible_values: 'Street number and route of the property address.',
    required: 'Required',
    example: '123 Main Street',
  },
  {
    column_name: 'Property Address Line 2',
    possible_values: 'Other details for your property address.',
    required: 'Required',
    example: 'Near Central Park',
  },
  {
    column_name: 'Property Unit Number',
    possible_values: 'Unit number for the property address, if applicable.',
    required: 'Optional',
    example: 'A',
  },
  {
    column_name: 'Property City',
    possible_values: 'City for the property address.',
    required: 'Required',
    example: 'Irvine',
  },
  {
    column_name: 'Property State',
    possible_values: 'State for the property address.',
    required: 'Required',
    example: 'CA',
  },
  {
    column_name: 'Property ZIP Code',
    possible_values: 'ZIP code for the property address.',
    required: 'Required',
    example: '92603',
  },
  {
    column_name: 'Property County',
    possible_values: 'County for the property address.',
    required: 'Required',
    example: 'Orange',
  },
  {
    column_name: 'Number of participant',
    possible_values:
      'Number of participants for the transaction. For example, if only the seller is included, it should be 1. If both the buyer and seller are included, it should be 2.',
    required: 'Required',
    example: '1',
  },
  {
    column_name: 'Multiple Participant Form',
    possible_values: (
      <div>
        <b>Separate</b>(
        <i>
          In case of multiple participants of same participant type, completely separate form will
          be sent to each seller included in the order. The primary seller will no longer be able to
          add new sellers from the participant form. If they want to add a new seller, they can
          contact an admin user, who can add it from the order details screen.
        </i>
        ) or <b>Single</b>(
        <i>
          In case of multiple participants of same participant type, the primary seller completes
          the form first. Then, additional sellers will receive this partially completed
          form(completed by the primary seller) and can continue filling in their details, such as
          uploading a Government Issued ID and adding bank information.
        </i>
        )
      </div>
    ),
    required: 'Required if multiple seller is included in order',
    example: 'Separate',
  },
  {
    column_name: 'Participant1 Category',
    possible_values: `Either "individual" or "entity".`,
    required: 'Required',
    example: 'Individual',
  },
  {
    column_name: 'Participant1 Type',
    possible_values: `Value from "seller" or "buyer" or "borrower".`,
    required: 'Required',
    example: 'seller',
  },
  {
    column_name: 'Participant1 First Name',
    possible_values: 'First name.',
    required: 'If participant category is "Individual", then Yes; otherwise No',
    example: 'John',
  },
  {
    column_name: 'Participant1 Last Name',
    possible_values: 'Last name.',
    required: 'If participant category is "Individual", then Yes; otherwise No',
    example: 'Doe',
  },
  {
    column_name: 'Participant1 Social Security Number',
    possible_values:
      'Social security number (Only applicable if participant category is "Individual").',
    required: 'Optional',
    example: '111-11-1111',
  },
  {
    column_name: 'Participant1 Email',
    possible_values: 'Valid email address.',
    required: 'Required',
    example: 'john.doe@gmail.com',
  },
  {
    column_name: 'Participant1 Phone',
    possible_values: 'Valid phone number.',
    required: 'Optional',
    example: '(111) 111-2222',
  },
  {
    column_name: 'Participant1 Legal Business Name',
    possible_values: 'Legal business name (Only applicable if participant category is "Entity").',
    required: 'If participant category is "Entity", then Yes; otherwise No',
    example: 'Tech Corporation',
  },
  {
    column_name: 'Participant1 Entity Type',
    possible_values:
      'Value from "Corporation", "LLC", "Trust", "Partnership", or "Other" (Only applicable if participant category is "Entity").',
    required: 'Optional',
    example: 'Corporation',
  },
  {
    column_name: 'Participant1 Entity State',
    possible_values:
      'State abbreviation for the entity (Only applicable if participant category is "Entity").',
    required: 'Optional',
    example: 'CA',
  },
  {
    column_name: 'Participant1 Entity EIN',
    possible_values:
      'Employer Identification Number (Only applicable if participant category is "Entity").',
    required: 'Optional',
    example: '11-1111111',
  },
  {
    column_name: 'Participant1 mailing address same as property address',
    possible_values:
      'Either "yes" or "no"(If this is selected as "Yes" you can skip adding participant mailing address details).',
    required: 'Required',
    example: 'yes',
  },
  {
    column_name: 'Participant1 mailing Address line 1',
    possible_values: 'Street number and route for the participant’s address.',
    required: 'Required',
    example: '1233 Main Street',
  },
  {
    column_name: 'Participant1 mailing Address line 2',
    possible_values: 'Mention other details about the address.',
    required: 'Optional',
    example: 'Near Central Park',
  },
  {
    column_name: 'Participant1 mailing Unit Number',
    possible_values: 'Unit number for the participant’s address.',
    required: 'Optional',
    example: 'A',
  },
  {
    column_name: 'Participant1 mailing City',
    possible_values: 'City for the participant’s address.',
    required: 'Required',
    example: 'Irvine',
  },
  {
    column_name: 'Participant1 mailing State',
    possible_values: 'State for the participant’s address.',
    required: 'Required',
    example: 'CA',
  },
  {
    column_name: 'Participant1 mailing ZIP Code',
    possible_values: 'ZIP code for the participant’s address.',
    required: 'Required',
    example: '92603',
  },
  {
    column_name: 'Participant1 mailing County',
    possible_values: 'County for the participant’s address.',
    required: 'Optional',
    example: 'Orange',
  },
  {
    column_name: 'Participant2 Category',
    possible_values: `Either "individual" or "entity".`,
    required: 'Required',
    example: 'Individual',
  },
  {
    column_name: 'Participant2 Type',
    possible_values: `Value from "seller" or "buyer" or "borrower".`,
    required: 'Required',
    example: 'seller',
  },
  {
    column_name: 'Participant2 First Name',
    possible_values: 'First name.',
    required: 'If participant category is "Individual", then Yes; otherwise No',
    example: 'Steve',
  },
  {
    column_name: 'Participant2 Last Name',
    possible_values: 'Last name.',
    required: 'If participant category is "Individual", then Yes; otherwise No',
    example: 'Smith',
  },
  {
    column_name: 'Participant2 Social Security Number',
    possible_values:
      'Social security number (Only applicable if participant category is "Individual").',
    required: 'Optional',
    example: '111-11-1111',
  },
  {
    column_name: 'Participant2 Email',
    possible_values: 'Valid email address.',
    required: 'Required',
    example: 'steave.smith@gmail.com',
  },
  {
    column_name: 'Participant2 Phone',
    possible_values: 'Valid phone number.',
    required: 'Optional',
    example: '(111) 111-2222',
  },
  {
    column_name: 'Participant2 Legal Business Name',
    possible_values: 'Legal business name (Only applicable if participant category is "Entity").',
    required: 'If participant category is "Entity", then Yes; otherwise No',
    example: 'Tech Corporation',
  },
  {
    column_name: 'Participant2 Entity Type',
    possible_values:
      'Value from "Corporation", "LLC", "Trust", "Partnership", or "Other" (Only applicable if participant category is "Entity").',
    required: 'Optional',
    example: 'Corporation',
  },
  {
    column_name: 'Participant2 Entity State',
    possible_values:
      'State abbreviation for the entity (Only applicable if participant category is "Entity").',
    required: 'Optional',
    example: 'CA',
  },
  {
    column_name: 'Participant2 Entity EIN',
    possible_values:
      'Employer Identification Number (Only applicable if participant category is "Entity").',
    required: 'Optional',
    example: '11-1111111',
  },
  {
    column_name: 'Participant2 mailing address same as property address',
    possible_values:
      'Either "yes" or "no"(If this is selected as "Yes" you can skip adding participant mailing address details).',
    required: 'Required',
    example: 'yes',
  },
  {
    column_name: 'Participant2 mailing Address line 1',
    possible_values: 'Street number and route for the participant’s address.',
    required: 'Required',
    example: '1233 ABC Street',
  },
  {
    column_name: 'Participant2 mailing Address line 2',
    possible_values: 'Mention other details about the address.',
    required: 'Optional',
    example: 'Near Central Park',
  },
  {
    column_name: 'Participant2 mailing Unit Number',
    possible_values: 'Unit number for the participant’s address.',
    required: 'Optional',
    example: 'A',
  },
  {
    column_name: 'Participant2 mailing City',
    possible_values: 'City for the participant’s address.',
    required: 'Required',
    example: 'Irvine',
  },
  {
    column_name: 'Participant2 mailing State',
    possible_values: 'State for the participant’s address.',
    required: 'Required',
    example: 'CA',
  },
  {
    column_name: 'Participant2 mailing ZIP Code',
    possible_values: 'ZIP code for the participant’s address.',
    required: 'Required',
    example: '92603',
  },
  {
    column_name: 'Participant2 mailing County',
    possible_values: 'County for the participant’s address.',
    required: 'Optional',
    example: 'Orange',
  },
  {
    column_name: 'Participant3 Category',
    possible_values: `Either "Individual" or "Entity".`,
    required: 'Yes, in cases where the order has multiple participants.',
    example: 'Entity',
  },
  {
    column_name: 'Participant3 Type',
    possible_values: `Value from "seller" or "buyer" or "borrower".`,
    required: 'Yes, in cases where the order has multiple participants.',
    example: 'buyer',
  },
  {
    column_name: 'Participant3 First Name',
    possible_values: 'First name.',
    required:
      'If participant category is "Individual" and in cases where the order has multiple participants, then Yes; otherwise No',
    example: 'James',
  },
  {
    column_name: 'Participant3 Last Name',
    possible_values: 'Last name.',
    required:
      'If participant category is "Individual" and in cases where the order has multiple participants, then Yes; otherwise No',
    example: 'Smith',
  },
  {
    column_name: 'Participant3 Social Security Number',
    possible_values:
      'Social security number (Only applicable if participant category is "Individual").',
    required: 'Optional',
    example: '222-22-2222',
  },
  {
    column_name: 'Participant3 Email',
    possible_values: 'Valid email address.',
    required: 'Yes, in cases where the order has multiple participants.',
    example: 'james.smith@gmail.com',
  },
  {
    column_name: 'Participant3 Phone',
    possible_values: 'Valid phone number.',
    required: 'Optional',
    example: '(222) 222-2222',
  },
  {
    column_name: 'Participant3 Legal Business Name',
    possible_values: 'Legal business name (Only applicable if participant category is "Entity").',
    required:
      'If participant category is "Entity" and in cases where the order has multiple participants, then Yes; otherwise No',
    example: 'Tech LLC',
  },
  {
    column_name: 'Participant3 Entity Type',
    possible_values:
      'Value from "Corporation", "LLC", "Trust", "Partnership", or "Other" (Only applicable if participant category is "Entity").',
    required: 'Optional',
    example: 'LLC',
  },
  {
    column_name: 'Participant3 Entity State',
    possible_values:
      'State abbreviation for the entity (Only applicable if participant category is "Entity").',
    required: 'Optional',
    example: 'AL',
  },
  {
    column_name: 'Participant3 Entity EIN',
    possible_values:
      'Employer Identification Number (Only applicable if participant category is "Entity").',
    required: 'Optional',
    example: '22-2222222',
  },
  {
    column_name: 'Participant3 mailing address same as property address',
    possible_values:
      'Value either "yes" or "no"(If this is selected as "yes" you can skip adding participant mailing address details).',
    required: 'Yes, in cases where the order has multiple participants.',
    example: 'no',
  },
  {
    column_name: 'Participant3 mailing Address line 1',
    possible_values: 'Street number and route for the participant’s address.',
    required: 'Yes, in cases where the order has multiple participants.',
    example: '123 Main Street',
  },
  {
    column_name: 'Participant3 mailing Address line 2',
    possible_values: 'Mention other details about the address.',
    required: 'Optional',
    example: 'Near Central Park',
  },
  {
    column_name: 'Participant3 mailing Unit Number',
    possible_values: 'Unit number for the participant’s address.',
    required: 'Optional',
    example: 'A',
  },
  {
    column_name: 'Participant3 mailing City',
    possible_values: 'City for the participant’s address.',
    required: 'Yes, in cases where the order has multiple participants.',
    example: 'Irvine',
  },
  {
    column_name: 'Participant3 mailing State',
    possible_values: 'State for the participant’s address.',
    required: 'Yes, in cases where the order has multiple participants.',
    example: 'CA',
  },
  {
    column_name: 'Participant3 mailing ZIP Code',
    possible_values: 'ZIP code for the participant’s address.',
    required: 'Yes, in cases where the order has multiple participants.',
    example: '92603',
  },
  {
    column_name: 'Participant3 mailing County',
    possible_values: 'County for the participant’s address.',
    required: 'Optional',
    example: 'Orange',
  },
];

export const guideTableDataPHH = (officeList) => [
  {
    column_name: 'Office Name',
    possible_values: officeList?.length
      ? `${officeList?.map((ofc) => `"${ofc}"`)?.join(' or ')}.`
      : `Your client has only one office you can keep this field as blank we will manage it internally.`,
    required: 'Required',
    example: officeList?.[0] ?? '-',
  },
  {
    column_name: 'Order Type',
    possible_values: `Either "refinance" or "purchase".`,
    required: 'Required',
    example: 'purchase',
  },
  {
    column_name: 'Escrow Number',
    possible_values: `Unique string for each order.`,
    required: 'Required',
    example: 'VSS-21231231',
  },
  {
    column_name: 'Anticipated Close Date',
    possible_values: `Date in the "MM/DD/YYYY" format.`,
    required: 'Required',
    example: '09/31/2024',
  },
  {
    column_name: 'Property Address Line 1',
    possible_values: 'Street number and route of the property address.',
    required: 'Required',
    example: '123 Main Street',
  },
  {
    column_name: 'Property Address Line 2',
    possible_values: 'Other details for your property address.',
    required: 'Required',
    example: 'Near Central Park',
  },
  {
    column_name: 'Property Unit Number',
    possible_values: 'Unit number for the property address, if applicable.',
    required: 'Optional',
    example: 'A',
  },
  {
    column_name: 'Property City',
    possible_values: 'City for the property address.',
    required: 'Required',
    example: 'Irvine',
  },
  {
    column_name: 'Property State',
    possible_values: 'State for the property address.',
    required: 'Required',
    example: 'CA',
  },
  {
    column_name: 'Property ZIP Code',
    possible_values: 'ZIP code for the property address.',
    required: 'Required',
    example: '92603',
  },
  {
    column_name: 'Property County',
    possible_values: 'County for the property address.',
    required: 'Required',
    example: 'Orange',
  },
  {
    column_name: 'Number of participant',
    possible_values:
      'Number of participants for the transaction. For example, if only the seller is included, it should be 1. If both the buyer and seller are included, it should be 2. If the buyer and 2 seller are included, it should be 3.',
    required: 'Required',
    example: '1',
  },

  {
    column_name: 'Participant1 Category',
    possible_values: `Either "individual" or "entity".`,
    required: 'Required',
    example: 'Individual',
  },
  {
    column_name: 'Participant1 Type',
    possible_values: `Value from "seller" or "buyer" or "borrower".`,
    required: 'Required',
    example: 'seller',
  },
  {
    column_name: 'Participant1 First Name',
    possible_values: 'First name.',
    required: 'If participant category is "Individual", then Yes; otherwise No',
    example: 'John',
  },
  {
    column_name: 'Participant1 Last Name',
    possible_values: 'Last name.',
    required: 'If participant category is "Individual", then Yes; otherwise No',
    example: 'Doe',
  },
  {
    column_name: 'Participant1 Social Security Number',
    possible_values:
      'Social security number (Only applicable if participant category is "Individual").',
    required: 'Optional',
    example: '111-11-1111',
  },
  {
    column_name: 'Participant1 Email',
    possible_values: 'Valid email address.',
    required: 'Required',
    example: 'john.doe@gmail.com',
  },
  {
    column_name: 'Participant1 Phone',
    possible_values: 'Valid phone number.',
    required: 'Optional',
    example: '(111) 111-2222',
  },
  {
    column_name: 'Participant1 Legal Business Name',
    possible_values: 'Legal business name (Only applicable if participant category is "Entity").',
    required: 'If participant category is "Entity", then Yes; otherwise No',
    example: 'Tech Corporation',
  },
  {
    column_name: 'Participant1 Entity Type',
    possible_values:
      'Value from "Corporation", "LLC", "Trust", "Partnership", or "Other" (Only applicable if participant category is "Entity").',
    required: 'Optional',
    example: 'Corporation',
  },
  {
    column_name: 'Participant1 Entity State',
    possible_values:
      'State abbreviation for the entity (Only applicable if participant category is "Entity").',
    required: 'Optional',
    example: 'CA',
  },
  {
    column_name: 'Participant1 Entity EIN',
    possible_values:
      'Employer Identification Number (Only applicable if participant category is "Entity").',
    required: 'Optional',
    example: '11-1111111',
  },
  {
    column_name: 'Participant1 mailing address same as property address',
    possible_values:
      'Either "yes" or "no"(If this is selected as "Yes" you can skip adding participant mailing address details).',
    required: 'Required',
    example: 'yes',
  },
  {
    column_name: 'Participant1 mailing Address line 1',
    possible_values: 'Street number and route for the participant’s address.',
    required: 'Required',
    example: '1234 Main Street',
  },
  {
    column_name: 'Participant1 mailing Address line 2',
    possible_values: 'Mention other details about the address.',
    required: 'Optional',
    example: 'Near Central Park',
  },
  {
    column_name: 'Participant1 mailing Unit Number',
    possible_values: 'Unit number for the participant’s address.',
    required: 'Optional',
    example: 'A',
  },
  {
    column_name: 'Participant1 mailing City',
    possible_values: 'City for the participant’s address.',
    required: 'Required',
    example: 'Irvine',
  },
  {
    column_name: 'Participant1 mailing State',
    possible_values: 'State for the participant’s address.',
    required: 'Required',
    example: 'CA',
  },
  {
    column_name: 'Participant1 mailing ZIP Code',
    possible_values: 'ZIP code for the participant’s address.',
    required: 'Required',
    example: '92603',
  },
  {
    column_name: 'Participant1 mailing County',
    possible_values: 'County for the participant’s address.',
    required: 'Optional',
    example: 'Orange',
  },
  {
    column_name: 'Participant1 routing number',
    possible_values: 'Routing number for your bank account.',
    required: 'Required',
    example: '122199983',
  },
  {
    column_name: 'Participant1 bank account number',
    possible_values: 'Account number for your bank account.',
    required: 'Required',
    example: '923305129348',
  },
  {
    column_name: 'Participant1 name on account',
    possible_values: 'Name on account for your bank account.',
    required: 'Required',
    example: 'John Doe',
  },
];
export const ID_MAPPING = { 2: 'Passport', 22: `Driver's License` };

export const MAIN_BANK_QUE = '206f1d30-42b5-4ba3-911e-700583d9ff0f';
export const BANK_ACCOUNT_SECTION = '2e71ca86-24da-4683-b7ed-4aef34bb9ac3';
export const BANK_ACCOUNT_NUMBER = '47385310-da40-432e-b5a8-0e14d72127b7';
export const ROUTING_NUMBER_FIELD = 'f6d0f62e-86a9-4b35-81bf-c8c02257b103';
export const BANK_NAME = '33c3237a-099b-4a07-8a89-ed983a9924f9';
export const BANK_ACCOUNT_NAME = '92efb91c-9bdb-4577-8ed2-455134f0fd1c';
export const BANK_CITY = 'a1ca9a9c-e068-42b9-89f7-e83e810fce12';
export const BANK_STATE = '889dd184-3d56-4cce-970c-ca090f4d76b6';

export const IMM_FIELD = 'd853c8d3-0802-4fc7-8106-919439af8a4b';
export const IMM_BANK_ACCOUNT_NUMBER = 'df98fa5d-2dcb-4a72-8375-3bb2aba21bc6';
export const IMM_ROUTING_NUMBER_FIELD = 'c3ada41a-bab5-4676-97ef-5dc83c1972c7';

export const COR_FIELD = 'dda1fa32-4a36-4249-a759-ad255d22d8d1';
export const COR_BANK_ACCOUNT_NUMBER = '2bc6551f-f250-4b91-b223-112e1b6b8600';
export const COR_ROUTING_NUMBER_FIELD = '6cca8058-03aa-436b-acd8-de0fb7c22458';

export const ACCOUNT_NAME = 'Bank Account Number';
export const ROUTING_NAME = 'Bank ABA';
export const ROUTING_NAME_2 = 'Bank ABA (Routing Number)';
export const NAME_OF_ACCOUNT_NAME = 'Name on Account';
export const BANK_CITY_NAME = 'Bank City';
export const BANK_STATE_NAME = 'Bank State';
export const BANK_NAME_NAME = 'Bank Name';

export const FIELDS_TO_DISABLE = [BANK_CITY_NAME, BANK_STATE_NAME, BANK_NAME_NAME];

export const SKIP_SECTION_IDS = [
  'c22663ed-489b-454a-9d63-5b77579b5134',
  '2e71ca86-24da-4683-b7ed-4aef34bb9ac3',
  '18bf5b7b-31d0-4000-89a3-14354c485700',
  'ea880c86-7419-4b4c-8010-edaa38c7701b',
  '192004cc-f710-4000-882a-2eced76a2100',
  '19231352-d7e0-4000-8d7b-05cd022d8a00',
  '19231354-0780-4000-8f0f-b8741b02df00',
  '18bf5f33-d3c0-4000-8325-9ebc34af9300',
  '18bf5b7b-31d0-4000-89a3-14354c485700',
];

export const DL_AVAILABLE_TYPE = ['Seller', 'Borrower', 'Buyer'];