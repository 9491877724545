import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
  CardContent,
  Box,
  Typography,
  FormControlLabel,
  Radio,
  Checkbox,
  Alert,
} from '@mui/material';
import { MuiColorInput } from 'mui-color-input';
import InputMask from 'react-input-mask';
import {
  addClient,
  getClient,
  addClientOffices,
  updateClientOffice,
  updateClient,
  updateOfficeStatus,
} from '../../../services/admin/clients';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../../components/common/Loader';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '../../../store/slices/breadcrumbSlice';
import Dropzone from 'react-dropzone';
import {
  CloudUpload,
  Clear,
  CloudOff,
  HighlightOff,
  DoNotDisturb,
  CheckCircle,
} from '@mui/icons-material';
import { login } from '../../../store/slices/authSlice';
import { randomUUID } from '../../../utils/helpers';
import { cloneDeep } from 'lodash';
import { CLIENT_ADMIN_ROLE, OFFICE_ADMIN_ROLE, SUPER_ADMIN_ROLE } from '../../../utils/constants';
import ConfirmationModel from './ConfirmationModel';
import CommonModal from '../../../components/questions/home/CommonModal';

const AddEditClient = () => {
  const { state, pathname } = useLocation();
  const dispatch = useDispatch();
  const { id, user_id } = useParams();
  const { admin } = useSelector((state) => state?.authSlice);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [statusData, setStatusData] = useState({});
  const [clientNameEntered, setClientNameEntered] = useState(false);
  const [participantQuestionCheckbox, setParticipantQuestionCheckbox] = useState(true);
  const [clientDetails, setClientDetails] = useState({});

  // this state is added to rerender component on change of office name/domain
  const [dummyState, setDummyState] = useState(false);

  const [offices, setOffices] = useState([
    { branding_json: {}, is_new: true, id: randomUUID(), is_primary: true },
  ]);
  const inputType = 'outlined';
  const isAdmin =
    admin?.user_role_name === SUPER_ADMIN_ROLE || admin?.user_role_name === CLIENT_ADMIN_ROLE;
  const isAddable = id ? isAdmin : true;

  useEffect(() => {
    if (id) fetchClient();
  }, [id]);

  useEffect(() => {
    if (clientDetails?.comp_name) {
      if (user_id) {
        dispatch(
          setBreadcrumb({
            data: [
              {
                title: clientDetails?.comp_name,
                url: '/admin/clients/:user_id/:id',
              },
            ],
            key: pathname,
          })
        );
      } else {
        dispatch(
          setBreadcrumb({
            data: [
              {
                title: 'Client Offices',
                url: '/admin/clients',
              },
              {
                title: clientDetails?.comp_name,
                url: '/admin/clients/:id',
              },
            ],
            key: pathname,
          })
        );
      }
    }
  }, [clientDetails?.comp_name]);

  // const schema = yup.object().shape({
  //   client_name: yup.string().required('Name is required'),
  //   email: yup.string().required('Email is required').email('Invalid email'),
  //   phone: yup
  //     .string()
  //     .required('Phone is required')
  //     .matches(/^\+1 \([0-9]{3}\) [0-9]{3}-[0-9]{4}$/, 'Invalid phone number format'),

  //   comp_name: yup.string().required('Company Name is required'),
  //   status: yup.string().required('Status is required'),
  //   address_1: yup.string().required('Address 1 is required'),
  //   city: yup.string().required('City is required'),
  //   state: yup.string().required('State is required'),
  //   zip_code: yup.string().required('ZIP Code is required'),
  // });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
    trigger,
  } = useForm();

  const formData = getValues();

  const fetchClient = async () => {
    setLoading(true);
    reset();
    const res = await getClient(id, admin?.token);
    if (res.success) {
      let resetPayload = {
        comp_name: res?.response?.company_name || '',
        domain: res?.response?.domain || '',
        offices: res?.response?.offices || [],
      };

      reset({ ...resetPayload });
      setClientDetails({
        ...res.response,
        comp_name: res.response.company_name,
        domain: res.response.domain,
      });
      setParticipantQuestionCheckbox(
        res?.response?.is_form_template_same_for_all_offices
          ? res.response.is_form_template_same_for_all_offices
          : false
      );
      if (!res?.response?.offices?.length) {
        navigate(-1);
      }
      setOffices([...res.response.offices]);
      if (user_id) {
        dispatch(
          login({
            data: {
              ...admin,
              client_name: res.response.client_name,
              company_name: res.response.company_name,
            },
            code: 'admin',
            showModal: true,
          })
        );
        dispatch(
          login({
            data: {
              ...admin,
              client_name: res.response.client_name,
              company_name: res.response.company_name,
            },
            code: 'order',
            showModal: true,
          })
        );
      }
    } else {
      toast.error(res?.message);
    }
    setLoading(false);
  };

  const onSubmit = async (values) => {
    setLoading(true);

    // logo validation
    const filteredOffices = offices?.filter(
      (ofc) => !ofc.is_branding_same_as_primary && !ofc?.logo_file
    );
    if (filteredOffices?.length) {
      setLoading(false);
      return toast.error('Please add office logo.');
    }
    const form = new FormData();
    let tempOffices = [...values?.offices];

    // appending branding_json and logo

    offices.forEach((ofc, index) => {
      form.append('company_logo[]', ofc?.logo_file);
      tempOffices[index].is_primary = ofc.is_primary;
      tempOffices[index].is_branding_same_as_primary = ofc.is_branding_same_as_primary
        ? ofc.is_branding_same_as_primary
        : false;
      tempOffices[index].branding_json = {
        sidebar_color: ofc?.branding_json?.sidebar_color || '',
        header_color: ofc?.branding_json?.header_color || '',
        background_color: ofc?.branding_json?.background_color || '',
        primary_color: ofc?.branding_json?.primary_color || '',
        sidebar_font_color: ofc?.branding_json?.sidebar_font_color || '',
        header_font_color: ofc?.branding_json?.header_font_color,
      };
    });
    form.append('is_form_template_same_for_all_offices', participantQuestionCheckbox);
    form.append('comp_name', values?.comp_name);
    form.append('domain', values?.domain);
    form.append('offices', JSON.stringify(tempOffices));
    const res = await addClient(form, admin?.token, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    if (res?.success) {
      toast.success(res?.message);
      if (id) {
        fetchClient();
      } else {
        navigate(`/admin/clients`);
      }
    } else {
      toast.error(res?.message);
    }
    setLoading(false);
  };

  const handleAddOffice = () => {
    setClientNameEntered(false);
    setOffices((prev) => {
      return [...prev, { branding_json: {}, is_new: true, id: randomUUID(), is_primary: false }];
    });
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight + 500);
    }, 500);
  };

  const removeOffice = (index) => {
    let isPrimary = false;
    const updatedOffices = [...offices];
    if (updatedOffices?.[index]?.is_primary) {
      isPrimary = true;
    }
    updatedOffices.splice(index, 1);
    if (isPrimary) {
      updatedOffices[0].is_primary = true;
    }

    setOffices(updatedOffices);

    let formValues = getValues('offices');
    if (!updatedOffices?.length) {
      setValue('offices', []);
    } else {
      formValues.splice(index, 1);
      setValue('offices', formValues);
    }
    reset(getValues());
  };

  const addOfficeData = async (data) => {
    setLoading(true);

    // Validation
    await trigger('offices');
    if (Object.keys(errors || {})?.length) {
      setLoading(false);
      return;
    }

    // payload generation
    const values = data;
    let tempOffices = cloneDeep(values.offices);
    let tempAllOffices = cloneDeep(offices);
    tempAllOffices.splice(0, clientDetails?.offices.length);
    tempOffices.splice(0, clientDetails?.offices?.length);

    // logo validation
    const filteredOffices = tempAllOffices?.filter(
      (ofc) => !ofc.is_branding_same_as_primary && !ofc?.logo_file
    );
    if (filteredOffices?.length) {
      setLoading(false);
      return toast.error('Please add office logo.');
    }

    const form = new FormData();

    tempAllOffices.forEach((ofc, index) => {
      form.append('company_logo[]', ofc?.logo_file);
      tempOffices[index].is_primary = ofc.is_primary;
      tempOffices[index].is_branding_same_as_primary = ofc.is_branding_same_as_primary
        ? ofc.is_branding_same_as_primary
        : false;
      tempOffices[index].branding_json = {
        sidebar_color: ofc?.branding_json?.sidebar_color || '',
        header_color: ofc?.branding_json?.header_color || '',
        background_color: ofc?.branding_json?.background_color || '',
        primary_color: ofc?.branding_json?.primary_color || '',
        sidebar_font_color: ofc?.branding_json?.sidebar_font_color || '',
        header_font_color: ofc?.branding_json?.header_font_color,
      };
    });
    form.append('client_id', id);
    form.append('offices', JSON.stringify(tempOffices));
    // API call

    const res = await addClientOffices(form, admin?.token, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    if (res?.success) {
      toast.success(res?.message);
      await fetchClient();
      setLoading(false);
    } else {
      toast.error(res?.message);
      setLoading(false);
    }
  };

  const updateOfficeData = async (officeId, index) => {
    setLoading(true);

    // Validation
    await trigger(`offices[${index}]`);
    if (Object.keys(errors || {})?.length) {
      setLoading(false);
      return;
    }

    if (
      !offices?.[index]?.is_branding_same_as_primary &&
      !offices?.[index]?.logo_file &&
      !offices?.[index]?.company_logo_url
    ) {
      setLoading(false);
      return;
    }
    const formData = getValues();
    const form = new FormData();
    if (offices?.[index]?.logo_file) form.append('company_logo', offices?.[index]?.logo_file);
    form.append('is_primary', offices?.[index]?.is_primary);
    form.append(
      'is_branding_same_as_primary',
      offices?.[index]?.is_branding_same_as_primary
        ? offices?.[index]?.is_branding_same_as_primary
        : false
    );
    form.append('client_id', id);
    form.append('__method', 'put');
    delete formData?.offices?.[index]?.['company_logo_url'];
    delete formData?.offices?.[index]?.['created_by_user'];
    delete formData?.offices?.[index]?.['client_office_id'];
    for (const key in formData?.offices?.[index]) {
      if (key === 'is_primary') continue;
      if (key === 'is_branding_same_as_primary') continue;
      form.append(
        key,
        key === 'branding_json'
          ? JSON.stringify(offices[index]?.branding_json)
          : formData?.offices?.[index]?.[key]
      );
    }

    const res = await updateClientOffice(officeId, form, admin?.token, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    if (res?.success) {
      toast.success(
        'Office details updated successfully. If you have updated colors or logo please reload the page.'
      );
      await fetchClient();
      setLoading(false);
    } else {
      toast.error(res?.message);
      setLoading(false);
    }
  };

  const handleUpdate = async (skip = false) => {
    if (
      clientDetails?.is_form_template_same_for_all_offices &&
      !participantQuestionCheckbox &&
      !skip
    ) {
      return setOpen(true);
    }

    setLoading(true);
    await trigger('domain');
    await trigger('comp_name');
    if (Object.keys(errors || {})?.length) {
      setLoading(false);
      return;
    }
    const formValues = getValues();
    const formData = new FormData();
    formData.append('comp_name', formValues.comp_name);
    formData.append('domain', formValues.domain);
    formData.append('is_form_template_same_for_all_offices', participantQuestionCheckbox);
    formData.append('_method', 'PUT');
    const res = await updateClient(id, formData, admin?.token, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    if (res?.success) {
      toast.success(res?.message);
      setOpen(false);
      await fetchClient();
      setLoading(false);
    } else {
      toast.error(res?.message);
      setLoading(false);
    }
  };

  const handleStatusUpdate = async () => {
    const res = await updateOfficeStatus(
      { status: statusData?.status, client_id: id, client_office_id: statusData?.office_id },
      admin?.token
    );
    if (res?.success) {
      toast.success(res?.message);
      setStatusModalOpen(false);
      await fetchClient();
      setLoading(false);
    } else {
      toast.error(res?.message);
      setLoading(false);
    }
  };

  return (
    <Box className='dashboard-main'>
      <ConfirmationModel setOpen={setOpen} open={open} handleSave={handleUpdate} />
      {loading && <Loader />}
      <CommonModal
        setOpen={setStatusModalOpen}
        open={statusModalOpen}
        handleSave={handleStatusUpdate}
        title={'Confirmation'}
        description={statusData?.message}
      />
      <Grid container className='dashboard-content'>
        <Grid item md={12}>
          <div className='transaction-logs-wrapper'>
            <Grid item md={12} className='d-flex justify-between align-center sub-header'>
              <Typography variant='h4' className='mb-0'>
                {id ? (state?.disabled ? 'Client' : 'Client Information') : 'Client Information'}
              </Typography>
            </Grid>
            <Box sx={{ mt: 2 }} className='client-user-form'>
              <CardContent>
                <Alert
                  severity='info'
                  className='f-13 mb-8 fw-450 '
                  style={{ padding: '2px 10px' }}
                >
                  Note: You cannot check this checkbox{' '}
                  <b>(Do all offices have the same participant questions?)</b> again once it is
                  saved as unchecked, and the questionnaires are specifically configured for each
                  office.
                </Alert>
                <form
                  onSubmit={id && isAddable ? handleSubmit(addOfficeData) : handleSubmit(onSubmit)}
                  // className='user-form'
                >
                  <fieldset
                    disabled={state?.disabled ? true : false}
                    className='m-0 p-0 border-none'
                  >
                    <Grid container spacing={1}>
                      <Grid
                        container
                        spacing={1}
                        className='office-block mt-16'
                        style={{ marginLeft: '0.6%' }}
                      >
                        <Grid item xs={12} sm={3}>
                          <label>Company Name</label>
                          <label className='required'>{'*'}</label>
                          <Controller
                            name='comp_name'
                            control={control}
                            defaultValue=''
                            rules={{ required: 'Company Name is required' }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                placeholder='Enter company name'
                                variant={inputType}
                                fullWidth
                                disabled={id && admin?.user_role_name === OFFICE_ADMIN_ROLE}
                                error={!!errors.comp_name}
                                helperText={errors.comp_name?.message}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <label>Company Domain</label>
                          <Controller
                            name='domain'
                            control={control}
                            defaultValue=''
                            rules={{
                              pattern: {
                                value:
                                  /^((https?:\/\/)?((localhost|[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}|(\d{1,3}\.){3}\d{1,3}|localhost)(:\d{1,5})?)(\/[\w-]*)*(\?[\w-=&]*)?(#[\w-]*)?$/,
                                message: 'Invalid URL format',
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                onBlur={() => {
                                  setDummyState((prev) => !prev);
                                }}
                                placeholder='Enter company url'
                                variant={inputType}
                                fullWidth
                                disabled={id && admin?.user_role_name !== SUPER_ADMIN_ROLE}
                                error={!!errors.domain}
                                helperText={errors.domain?.message}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          xs={4}
                          className='color-p order-switch-label branding-toggle f-14 fw-500 plt-8 mt-16'
                        >
                          {/* <label className='f-12 fw-500' style={{ color: 'black' }}>
                            Do all offices have the same participant questions?
                          </label> */}
                          <FormControlLabel
                            className='office-checkbox office-label'
                            label='Do all offices have the same participant questions?'
                            control={
                              <Checkbox
                                disabled={
                                  admin?.user_role_name === OFFICE_ADMIN_ROLE ||
                                  (id && !clientDetails?.is_form_template_same_for_all_offices)
                                }
                                checked={participantQuestionCheckbox}
                                onChange={(e) => {
                                  setParticipantQuestionCheckbox((prev) => !prev);
                                }}
                                color='primary'
                                size='small'
                              />
                            }
                          />
                        </Grid>
                        {id && admin?.user_role_name !== OFFICE_ADMIN_ROLE && (
                          <Grid
                            item
                            xs={2}
                            className='mt-12 d-flex justify-end'
                            style={{ float: 'right' }}
                          >
                            <Button
                              className='add-participant-btn'
                              variant='contained'
                              color='primary'
                              style={{ height: '35px' }}
                              onClick={() => {
                                handleUpdate(false);
                              }}
                            >
                              Update
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item xs={12} className='mt-12'>
                        <div className='d-flex justify-between'>
                          <Typography variant='h4' className='mb-0'>
                            {'Office Information'}
                          </Typography>
                          {isAddable ? (
                            <Button
                              className='add-participant-btn'
                              variant='outlined'
                              color='primary'
                              style={{ height: '35px' }}
                              onClick={handleAddOffice}
                            >
                              Add New Office
                            </Button>
                          ) : (
                            ''
                          )}
                        </div>
                      </Grid>
                      {offices?.map((office, index) => {
                        return (
                          <Grid
                            container
                            spacing={1}
                            className='office-block mt-16'
                            style={{ marginLeft: '0.6%' }}
                            key={office?.id || index}
                          >
                            <Grid item xs={12} sm={12} className='primary-radio'>
                              <Grid container>
                                {isAddable ? (
                                  <Grid item className='primary-radio'>
                                    <FormControlLabel
                                      onChange={(e) => {
                                        setOffices((prevOffices) => {
                                          let tempOffices = [...prevOffices];
                                          tempOffices.forEach((temp, idx) => {
                                            if (index === idx) {
                                              temp.is_branding_same_as_primary = false;
                                              temp.is_primary = true;
                                            } else {
                                              temp.is_primary = false;
                                            }
                                          });
                                          return tempOffices;
                                        });
                                      }}
                                      control={<Radio checked={office?.is_primary} />}
                                      label={'Primary Office'}
                                      className='fw-600'
                                    />
                                  </Grid>
                                ) : (
                                  ''
                                )}
                                {id && isAdmin && !office.is_new ? (
                                  <Grid
                                    item
                                    className='d-flex align-items-center'
                                  >
                                    {office?.status === 'enabled' ? (
                                      <div className='f-14 fw-550 d-flex align-items-center'>
                                        {' '}
                                        Enabled{' '}
                                        <CheckCircle
                                          className='ml-4'
                                          style={{ color: 'green', fontSize: '13px' }}
                                        />{' '}
                                        :
                                      </div>
                                    ) : (
                                      <div className='f-14 fw-550 d-flex align-items-center'>
                                        Disabled{' '}
                                        <DoNotDisturb
                                          className='ml-4'
                                          style={{ color: 'red', fontSize: '13px' }}
                                        />{' '}
                                        :
                                      </div>
                                    )}
                                  </Grid>
                                ) : (
                                  ''
                                )}
                                {id && isAdmin && !office.is_new ? (
                                  <Grid
                                    item
                                    className='d-flex align-items-center'
                                  >
                                    {office?.status === 'enabled' ? (
                                      <Button
                                        variant='outlined'
                                        color='error'
                                        className='ml-8 pl-4 pr-4 f-13'
                                        style={{ height: '28px' }}
                                        onClick={() => {
                                          setStatusModalOpen(true);
                                          setStatusData({
                                            office_id: office.client_office_id,
                                            status: 'disabled',
                                            message: `<div class='error'>Do you want to disable this office? Disabling it will make it unavailable to all users.</div>`,
                                          });
                                        }}
                                      >
                                        Disable
                                      </Button>
                                    ) : (
                                      <Button
                                        variant='outlined'
                                        className='ml-8 pl-4 pr-4 f-13'
                                        style={{ height: '28px' }}
                                        onClick={() => {
                                          setStatusModalOpen(true);
                                          setStatusData({
                                            office_id: office.client_office_id,
                                            status: 'enabled',
                                            message:
                                              'Do you want to enable this office? Enabling it will make it available to all users.',
                                          });
                                        }}
                                      >
                                        Enable
                                      </Button>
                                    )}
                                  </Grid>
                                ) : (
                                  ''
                                )}
                              </Grid>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <label>Office Name</label>
                              <label className='required'>{'*'}</label>
                              <Controller
                                name={`offices[${index}].office_name`}
                                control={control}
                                defaultValue=''
                                rules={{ required: 'Office Name is required' }}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    placeholder='Enter office name'
                                    variant={inputType}
                                    onBlur={() => {
                                      setDummyState((prev) => !prev);
                                      if (office?.is_new) {
                                        setClientNameEntered(true);
                                      }
                                    }}
                                    onChange={(e) => {
                                      field.onChange(e);
                                      if (office?.is_new && !clientNameEntered) {
                                        setValue(
                                          `offices[${index}].office_identifier`,
                                          e.target.value?.replace(/\s+/g, '_').toLowerCase()
                                        );
                                      }
                                    }}
                                    fullWidth
                                    error={!!errors?.offices?.[index]?.office_name}
                                    helperText={errors?.offices?.[index]?.office_name?.message}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <label>Office ID</label>
                              <label className='required'>{'*'}</label>
                              <Controller
                                name={`offices[${index}].office_identifier`}
                                control={control}
                                defaultValue=''
                                rules={{ required: 'Office Id is required' }}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    placeholder='Enter office ID'
                                    variant={inputType}
                                    fullWidth
                                    error={!!errors?.offices?.[index]?.office_identifier}
                                    helperText={
                                      errors?.offices?.[index]?.office_identifier?.message
                                    }
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <label>Contact Name</label>
                              <Controller
                                name={`offices[${index}].contact_name`}
                                control={control}
                                defaultValue=''
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    placeholder='Enter client name'
                                    variant={inputType}
                                    fullWidth
                                    error={!!errors?.offices?.[index]?.contact_name}
                                    helperText={errors?.offices?.[index]?.contact_name?.message}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <label>Contact Email</label>
                              <Controller
                                name={`offices[${index}].contact_email`}
                                rules={{
                                  pattern: {
                                    value: /^\S+@\S+\.\S+$/,
                                    message: 'Invalid email format',
                                  },
                                }}
                                control={control}
                                defaultValue=''
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    placeholder='Enter contact email'
                                    variant={inputType}
                                    fullWidth
                                    error={!!errors?.offices?.[index]?.contact_email}
                                    helperText={errors?.offices?.[index]?.contact_email?.message}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <label>Phone No </label>
                              <Controller
                                name={`offices[${index}].phone_no`}
                                rules={{
                                  pattern: {
                                    value: /\(\d{3}\) \d{3}-\d{4}/, // Validate the phone number pattern
                                    message: 'Invalid phone number format (e.g., (123) 456-7890)',
                                  },
                                }}
                                control={control}
                                defaultValue=''
                                render={({ field: { ref, ...field }, fieldState }) => (
                                  <InputMask mask={'+1 (999) 999-9999'} {...field}>
                                    {() => (
                                      <TextField
                                        {...field}
                                        name='phone'
                                        placeholder='Enter phone'
                                        variant={inputType}
                                        fullWidth
                                        error={!!errors?.offices?.[index]?.phone_no}
                                        helperText={errors?.offices?.[index]?.phone_no?.message}
                                      />
                                    )}
                                  </InputMask>
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <label>Fax </label>
                              <Controller
                                name={`offices[${index}].fax_no`}
                                control={control}
                                defaultValue=''
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    name='fax'
                                    placeholder='Enter Fax'
                                    variant={inputType}
                                    fullWidth
                                    error={!!errors?.offices?.[index]?.fax_no}
                                    helperText={errors?.offices?.[index]?.fax_no?.message}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <label>Address</label>
                              <label className='required'>{'*'}</label>
                              <Controller
                                name={`offices[${index}].address_line_1`}
                                rules={{ required: 'Address is required' }}
                                control={control}
                                defaultValue=''
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    placeholder='Enter address'
                                    variant={inputType}
                                    fullWidth
                                    error={!!errors?.offices?.[index]?.address_line_1}
                                    helperText={errors?.offices?.[index]?.address_line_1?.message}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <label>Address 2</label>
                              <Controller
                                name={`offices[${index}].address_line_2`}
                                control={control}
                                defaultValue=''
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    placeholder='Enter address 2'
                                    variant={inputType}
                                    fullWidth
                                    error={!!errors?.offices?.[index]?.address_line_2}
                                    helperText={errors?.offices?.[index]?.address_line_2?.message}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <label>City</label>
                              <label className='required'>{'*'}</label>
                              <Controller
                                name={`offices[${index}].city`}
                                rules={{ required: 'City is required' }}
                                control={control}
                                defaultValue=''
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    placeholder='Enter city'
                                    variant={inputType}
                                    fullWidth
                                    error={!!errors?.offices?.[index]?.city}
                                    helperText={errors?.offices?.[index]?.city?.message}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <label>State</label>
                              <label className='required'>{'*'}</label>
                              <Controller
                                name={`offices[${index}].state`}
                                rules={{ required: 'State is required' }}
                                control={control}
                                defaultValue=''
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    placeholder='Enter state'
                                    variant={inputType}
                                    fullWidth
                                    error={!!errors?.offices?.[index]?.state}
                                    helperText={errors?.offices?.[index]?.state?.message}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <label>Zip</label>
                              <label className='required'>{'*'}</label>
                              <Controller
                                name={`offices[${index}].zip_code`}
                                rules={{ required: 'Zip code is required' }}
                                control={control}
                                defaultValue=''
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    placeholder='Enter ZIP Code'
                                    variant={inputType}
                                    type='number'
                                    fullWidth
                                    error={!!errors?.offices?.[index]?.zip_code}
                                    helperText={errors?.offices?.[index]?.zip_code?.message}
                                  />
                                )}
                              />
                            </Grid>
                            {/* <Grid item xs={12} sm={3}>
                              <label>Status</label>
                              <label className='required'>{'*'}</label>
                              <Controller
                                name={`offices[${index}].status`}
                                control={control}
                                defaultValue='active'
                                rules={{ required: 'Status is required' }}
                                render={({ field }) => (
                                  <FormControl
                                    fullWidth
                                    variant={inputType}
                                    error={!!errors.status}
                                  >
                                    <Select {...field} disabled={state?.disabled ? true : false}>
                                      <MenuItem value='active'>Active</MenuItem>
                                      <MenuItem value='inactive'>Inactive</MenuItem>
                                    </Select>
                                  </FormControl>
                                )}
                              />
                            </Grid> */}
                            <Grid item xs={12} sm={12}>
                              <label>Office URL</label>
                              <div className='mt-4 f-13-5 fw-500 color-p'>
                                {formData?.domain?.length
                                  ? `${formData?.domain}/${formData?.offices?.[
                                      index
                                    ]?.office_name?.replace(/ /g, '')}`
                                  : ''}
                              </div>
                            </Grid>
                            <Grid
                              xs={12}
                              className='color-p order-switch-label branding-toggle f-14 fw-500 plt-8 mt-8'
                            >
                              <span style={{ marginRight: '4px' }}>Branding</span>
                              {!office.is_primary && (
                                <>
                                  <span style={{ color: 'grey' }}>( Same as primary office</span>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={office.is_branding_same_as_primary}
                                        onChange={(e) => {
                                          setOffices((prevOffices) => {
                                            let tempOffices = [...prevOffices];
                                            let primaryOffice = tempOffices.find(
                                              (ofc) => ofc.is_primary
                                            );
                                            tempOffices[index].is_branding_same_as_primary =
                                              !tempOffices[index].is_branding_same_as_primary;
                                            if (!e.target.checked) {
                                              tempOffices[index].branding_json = {
                                                sidebar_color: '',
                                                header_color: '',
                                                background_color: '',
                                                primary_color: '',
                                                sidebar_font_color: '',
                                                header_font_color: '',
                                              };
                                            } else {
                                              tempOffices[index].branding_json =
                                                primaryOffice.branding_json;
                                            }

                                            return tempOffices;
                                          });
                                        }}
                                        color='primary'
                                        size='small'
                                      />
                                      // <Switch
                                      //   checked={sameAddress[index]}
                                      //   onChange={() => {
                                      //     handleSameAddress(index, sameAddress[index]);
                                      //   }}
                                      //   color='primary'
                                      //   size='small'
                                      // />
                                    }
                                  />

                                  <span style={{ color: 'grey' }}>)</span>
                                </>
                              )}
                            </Grid>
                            {!office?.is_branding_same_as_primary ? (
                              <>
                                <Grid xs={12} md={2} className='plt-8'>
                                  <label>Primary Color</label>
                                  <div>
                                    <MuiColorInput
                                      fullWidth
                                      format='hex'
                                      isAlphaHidden
                                      value={office?.branding_json?.primary_color}
                                      onChange={(value) => {
                                        setOffices((old) => {
                                          let tempOffices = [...old];
                                          tempOffices[index].branding_json = {
                                            ...tempOffices[index].branding_json,
                                            primary_color: value,
                                          };
                                          return tempOffices;
                                        });
                                      }}
                                    />
                                  </div>
                                </Grid>
                                <Grid xs={12} md={2} className='plt-8'>
                                  <label>Sidebar Color</label>
                                  <div>
                                    <MuiColorInput
                                      fullWidth
                                      format='hex'
                                      isAlphaHidden
                                      value={office?.branding_json?.sidebar_color}
                                      onChange={(value) => {
                                        setOffices((old) => {
                                          let tempOffices = [...old];
                                          tempOffices[index].branding_json = {
                                            ...tempOffices[index].branding_json,
                                            sidebar_color: value,
                                          };
                                          return tempOffices;
                                        });
                                      }}
                                    />
                                  </div>
                                </Grid>
                                <Grid xs={12} md={2} className='plt-8'>
                                  <label>Sidebar Font Color</label>
                                  <div>
                                    <MuiColorInput
                                      fullWidth
                                      format='hex'
                                      isAlphaHidden
                                      value={office?.branding_json?.sidebar_font_color}
                                      onChange={(value) => {
                                        setOffices((old) => {
                                          let tempOffices = [...old];
                                          tempOffices[index].branding_json = {
                                            ...tempOffices[index].branding_json,
                                            sidebar_font_color: value,
                                          };
                                          return tempOffices;
                                        });
                                      }}
                                    />
                                  </div>
                                </Grid>
                                <Grid xs={12} md={2} className='plt-8'>
                                  <label>Header Color</label>
                                  <div>
                                    <MuiColorInput
                                      format='hex'
                                      isAlphaHidden
                                      fullWidth
                                      value={office?.branding_json?.header_color}
                                      onChange={(value) => {
                                        setOffices((old) => {
                                          let tempOffices = [...old];
                                          tempOffices[index].branding_json = {
                                            ...tempOffices[index].branding_json,
                                            header_color: value,
                                          };
                                          return tempOffices;
                                        });
                                      }}
                                    />
                                  </div>
                                </Grid>
                                <Grid xs={12} md={2} className='plt-8'>
                                  <label>Header Font Color</label>
                                  <div>
                                    <MuiColorInput
                                      format='hex'
                                      fullWidth
                                      isAlphaHidden
                                      value={office?.branding_json?.header_font_color}
                                      onChange={(value) => {
                                        setOffices((old) => {
                                          let tempOffices = [...old];
                                          tempOffices[index].branding_json = {
                                            ...tempOffices[index].branding_json,
                                            header_font_color: value,
                                          };
                                          return tempOffices;
                                        });
                                      }}
                                    />
                                  </div>
                                </Grid>
                                <Grid xs={12} md={2} className='plt-8'>
                                  <label>Background Color</label>
                                  <div>
                                    <MuiColorInput
                                      format='hex'
                                      isAlphaHidden
                                      fullWidth
                                      value={office?.branding_json?.background_color}
                                      onChange={(value) => {
                                        setOffices((old) => {
                                          let tempOffices = [...old];
                                          tempOffices[index].branding_json = {
                                            ...tempOffices[index].branding_json,
                                            background_color: value,
                                          };
                                          return tempOffices;
                                        });
                                      }}
                                    />
                                  </div>
                                </Grid>{' '}
                                <Grid xs={12} md={12} className='plt-8'>
                                  <label>Logo</label>
                                  {id && <label className='required'>{'*'}</label>}
                                  <Dropzone
                                    onDrop={(acceptedFiles) => {
                                      const tempUrl = URL.createObjectURL(acceptedFiles[0]);
                                      setOffices((old) => {
                                        let tempOffices = [...old];
                                        tempOffices[index] = {
                                          ...tempOffices[index],
                                          logo_file: acceptedFiles[0],
                                          company_logo_url: tempUrl,
                                        };
                                        return tempOffices;
                                      });
                                    }}
                                    accept={{ 'image/png': ['.png', '.jpeg', '.jpg'] }}
                                    style={{
                                      cursor: !!office?.company_logo_url
                                        ? 'not-allowed'
                                        : 'pointer',
                                    }}
                                  >
                                    {({
                                      getRootProps,
                                      getInputProps,
                                      isDragActive,
                                      isFileDialogActive,
                                    }) => (
                                      <div
                                        className={
                                          isDragActive || isFileDialogActive
                                            ? 'drag-active dropzone'
                                            : 'dropzone'
                                        }
                                      >
                                        <div
                                          className='dz-message d-flex flex-column justify-center'
                                          {...getRootProps()}
                                        >
                                          <input {...getInputProps()} name='file' />
                                          <i className='h2 d-inline-block'>
                                            {!!office?.company_logo_url ? (
                                              <CloudOff className='ms-2' fontSize={'medium'} />
                                            ) : (
                                              <CloudUpload className='ms-2' fontSize={'medium'} />
                                            )}
                                          </i>
                                          <p
                                            className='mb-0 mt-0'
                                            style={{ fontWeight: '600', fontSize: '13px' }}
                                          >
                                            Drag & drop you files here or select files.
                                          </p>
                                          <p className='mb-0 mt-0' style={{ color: 'grey' }}>
                                            <small>
                                              You can upload the logo for the client. This logo will
                                              be placed on the participant form for that client.
                                            </small>
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  </Dropzone>
                                  {office?.company_logo_url && (
                                    <div className={`file-preview mt-8`}>
                                      {/* <div className='file-icon'> */}
                                      <img
                                        src={office?.company_logo_url}
                                        alt='logo-main'
                                        loading='lazy'
                                        className='logo-size'
                                      />
                                      {/* </div> */}
                                      <div
                                        className='file-details'
                                        onClick={() => {
                                          if (office?.company_logo_url)
                                            window.open(office?.company_logo_url, '_blank');
                                        }}
                                        style={{ cursor: 'pointer' }}
                                      >
                                        <div>{office?.logo_file?.path || 'logo'}</div>
                                      </div>
                                      <div className='file-close'>
                                        <Button
                                          variant='link'
                                          onClick={() => {
                                            setOffices((old) => {
                                              let tempOffices = [...old];
                                              tempOffices[index] = {
                                                ...tempOffices[index],
                                                logo_file: '',
                                                company_logo_url: '',
                                              };
                                              return tempOffices;
                                            });
                                          }}
                                        >
                                          <Clear fontSize='small' />
                                        </Button>
                                      </div>
                                    </div>
                                  )}
                                  {!loading && !office?.company_logo_url && (
                                    <div className='error f-13'>
                                      Client office logo is required.
                                    </div>
                                  )}
                                </Grid>
                              </>
                            ) : (
                              ''
                            )}

                            {/* <Grid xs={12} md={4} className='plt-8'>
                              <label>Logo</label>
                              <div className='logo-input'>
                                <MuiFileInput
                                variant='outlined'
                                  placeholder='Upload Logo'
                                  InputProps={{
                                    placeholder: 'Upload Logo',
                                    inputProps: {
                                      accept: 'image/*',
                                    },
                                    startAdornment: <AttachFile style={{ fontSize: '20px' }} />,
                                  }}
                                  value={office.logo_file}
                                  onChange={(newFile) => {
                                    setOffices((old) => {
                                      let tempOffices = [...old];
                                      tempOffices[index] = {
                                        ...tempOffices[index],
                                        logo_file: newFile,
                                      };
                                      return tempOffices;
                                    });
                                  }}
                                />
                              </div>
                            </Grid>{' '} */}
                            {!office?.is_new ? (
                              <Grid item xs={12}>
                                <div className='d-flex justify-end'>
                                  <Button
                                    type='button'
                                    onClick={() => {
                                      updateOfficeData(office?.client_office_id, index);
                                    }}
                                    className='add-participant-btn mt-16'
                                    variant='contained'
                                    color='primary'
                                    style={{ height: '35px' }}
                                  >
                                    Update
                                  </Button>
                                </div>
                              </Grid>
                            ) : (
                              ''
                            )}
                            {office?.is_new && index !== 0 ? (
                              <Grid item xs={12} className='remove-participant-btn'>
                                <Button
                                  type='button'
                                  onClick={() => removeOffice(index)}
                                  variant='outlined'
                                  color='secondary'
                                >
                                  <HighlightOff />
                                </Button>
                              </Grid>
                            ) : (
                              ''
                            )}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </fieldset>
                  <Grid item xs={12}>
                    <div className='d-flex justify-end mt-16'>
                      {admin?.user_role_name === SUPER_ADMIN_ROLE ? <Button
                        variant={inputType}
                        color='primary'
                        onClick={() => {
                          reset();
                          navigate('/admin/clients');
                        }}
                        className='mr-8'
                      >
                        {id ? 'Back' : 'Cancel'}
                      </Button> : ""}
                      {!id && (
                        <Button
                          variant='contained'
                          color='primary'
                          type='submit'
                          disabled={state?.disabled ? true : false}
                        >
                          {'Save'}
                        </Button>
                      )}
                      {id && isAddable && offices?.filter((ofc) => ofc.is_new)?.length ? (
                        <Button
                          variant='contained'
                          color='primary'
                          type='submit'
                          disabled={state?.disabled ? true : false}
                        >
                          {'Save Offices'}
                        </Button>
                      ) : (
                        ''
                      )}
                    </div>
                  </Grid>
                </form>
              </CardContent>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};
export default AddEditClient;
