import { Article } from '@mui/icons-material';
import { Grid, Link, Tooltip } from '@mui/material';

const DocumentLink = ({ handleClick, label, disabled = false }) => {
  return (
    <Grid container spacing={1} className='mt-4'>
      <Grid xs={12} item={true}>
        <Tooltip title={disabled ? "You can access documents only after the participant completes the form." : ""}>
          <Link
            href={''}
            className='media-link d-flex'
            onClick={(e) => {
              e.preventDefault();
              if (disabled) return
              handleClick();
            }}
            style={{ textDecoration: 'none', width: 'fit-content', color: disabled ? "grey" : "#005fd4", cursor: disabled ? "not-allowed" : "pointer" }}
          >
            <Article fontSize='small' />
            <div className='f-13 fw-500 ml-8'>{label}</div>
          </Link>
        </Tooltip>
      </Grid>
      <hr className='mt-4 mb-4 w-100p' style={{ backgroundColor: '#d2cece' }} />
    </Grid>
  );
};

export default DocumentLink;
