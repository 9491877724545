import React from 'react';
import { useParams } from 'react-router-dom';
import {
  DocElementType,
  MAILING_ADDRESS_ADDRESS,
  MAILING_ADDRESS_CITY,
  MAILING_ADDRESS_QUESTION,
  MAILING_ADDRESS_STATE,
  MAILING_ADDRESS_ZIP,
  SOI_SECTION_ID,
} from '../../../utils/constants';
import MediaField from './media';
import FileField from './file';
import TextFieldInput from './text-field';
import TextArea from './text-area';
import SelectBox from './select-box';
import RadioButton from './radio-button';
import SocialNumber from './social-number';
import DateField from './date-field';
import Upload from './upload';
import Label from './label';
import MediaLink from './media-link';
import Link from './link';
import Checkbox from './check-box';
import { useSelector } from 'react-redux';
import { findElement } from '../../../utils/helpers';
import useRedux from '../../../utils/hooks/useRedux';

const DocumentField = (props) => {
  const { getFormValue } = useRedux();
  const { participantNo, code } = useParams();
  let disabled = props.disabled;
  const question = useSelector((state) => state.questionSlice.questionDetails)?.find(
    (que) => que.tx_id === code
  )?.questions;
  const orderInfo = useSelector((state) => state.questionSlice.orderDetails)?.find(
    (order) => order.tx_id === code
  )?.order;
  const participantNum = participantNo || 1;
  const isAdditionalParticipant = participantNo && participantNo != 1 ? true : false;
  const authData = useSelector((state) => state?.authSlice);

  // participant_no is to identify private fields that should not be visible in additional participant and participant_number is to identify field belongs to which participant 
  const participantNumber = props?.element?.participant_no || props?.element?.seller_no || 1;

  if (props?.element?.is_private && participantNumber != participantNum) {
    return <></>;
  }
  if (!props?.element?.display) {
    return <></>;
  }
  if (!disabled && isAdditionalParticipant) {
    // if (spouseFields.includes(props?.element?.element_name)) {
    //   if (authData?.[code]?.is_spouse) {
    //     disabled = false;
    //   } else {
    //     disabled = true;
    //   }
    // } else if (
    //   [...secondParticipantFields, ...additionalSellerFields].includes(props?.element?.element_name)
    // ) {
    //   if (authData?.[code]?.is_spouse) {
    //     disabled = true;
    //   } else {
    //     disabled = false;
    //   }
    // }
    if (props?.element?.is_private) {
      disabled = false;
    } else if (participantNum != 1) {
      disabled = true;
    }
  }

  const getUserAddress = (addressType = null) => {
    const section = question.sections.find((section) => section.section_id == SOI_SECTION_ID);

    let userAddress = {};
    const currentOrOtherAddress = addressType
      ? addressType
      : getFormValue(MAILING_ADDRESS_QUESTION) ||
        findElement(section, MAILING_ADDRESS_QUESTION)?.user_value ||
        '';

    if (!currentOrOtherAddress) return userAddress;
    if (currentOrOtherAddress === 'Other') {
      const address1 =
        getFormValue(MAILING_ADDRESS_ADDRESS) ||
        findElement(section, MAILING_ADDRESS_ADDRESS)?.user_value ||
        '';
      const state =
        getFormValue(MAILING_ADDRESS_STATE) ||
        findElement(section, MAILING_ADDRESS_STATE)?.user_value ||
        '';
      const city =
        getFormValue(MAILING_ADDRESS_CITY) ||
        findElement(section, MAILING_ADDRESS_CITY)?.user_value ||
        '';
      const zip =
        getFormValue(MAILING_ADDRESS_ZIP) ||
        findElement(section, MAILING_ADDRESS_ZIP)?.user_value ||
        '';
      userAddress = {
        site_address: address1,
        site_city: city,
        site_state: state,
        site_zip: `${zip}`,
        // site_unit: unit,
        site_full_address: address1 + ',' + city + ',' + state + ',' + zip,
      };
    } else {
      userAddress = {
        site_address: orderInfo?.property_address_1,
        site_city: orderInfo?.property_city,
        site_state: orderInfo?.property_state,
        site_zip: `${orderInfo?.property_zipcode}`,
        // site_unit: unit,
        site_full_address:
          orderInfo?.property_address_1 +
          ',' +
          orderInfo?.property_city +
          ',' +
          orderInfo?.property_state +
          ',' +
          orderInfo?.property_zipcode,
      };
    }
    return userAddress;
  };
  return (
    <React.Fragment>
      {props.element.element_type === DocElementType.Media && (
        <MediaField {...props} disabled={disabled} />
      )}
      {props.element.element_type === DocElementType.File && (
        <FileField {...props} disabled={disabled} />
      )}
      {props.element.element_type === DocElementType.TextField && (
        <TextFieldInput {...props} disabled={disabled} getUserAddress={getUserAddress} />
      )}
      {props.element.element_type === DocElementType.TextArea && (
        <TextArea {...props} disabled={disabled} />
      )}
      {(props.element.element_type === DocElementType.SelectBox ||
        props.element.element_type === DocElementType.SSelectBox) && (
        <SelectBox {...props} disabled={disabled} getUserAddress={getUserAddress} />
      )}
      {props.element.element_type === DocElementType.RadioButton && (
        <RadioButton {...props} disabled={disabled} getUserAddress={getUserAddress} />
      )}
      {props.element.element_type === DocElementType.SocialNumber && (
        <SocialNumber {...props} disabled={disabled} />
      )}
      {props.element.element_type === DocElementType.DateField && (
        <DateField {...props} disabled={disabled} />
      )}
      {props.element.element_type === DocElementType.Checkbox && (
        <Checkbox {...props} disabled={disabled} />
      )}
      {props.element.element_type === DocElementType.Upload && (
        <Upload {...props} disabled={disabled} />
      )}
      {props.element.element_type === DocElementType.Label && (
        <Label {...props} disabled={disabled} />
      )}
      {props.element.element_type === DocElementType.MediaLink && (
        <MediaLink {...props} disabled={disabled} />
      )}
      {props.element.element_type === DocElementType.DocumentLink && (
        <Link {...props} disabled={disabled} />
      )}
    </React.Fragment>
  );
};
export default DocumentField;
