import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
  Grid,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import { toast } from 'react-toastify';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DocumentField from '../../common/fields';
import logo from '../../../assets/images/Voxtur-verify.png';
import {
  ADDITIONAL_SELLER_GROUP_ID,
  ADDITIONAL_SELLER_OPTION_ID,
  ADDITIONAL_SELLER_PARENT_ID,
  dlSectionIds,
  IS_INDIVIDUAL_ENTITY,
  LIEN_SECTION_ID,
  SOI_SECTION_ID,
  UTILITY_SECTION_ID,
  MAILING_ADDRESS_ADDRESS,
  MAILING_ADDRESS_CITY,
  MAILING_ADDRESS_QUESTION,
  MAILING_ADDRESS_STATE,
  MAILING_ADDRESS_ZIP,
  ADDITIONAL_SELLER_TYPE,
} from '../../../utils/constants';
import SectionExtraDetailsModal from './SectionExtraDetailsModal';
import { cloneDeep } from 'lodash';
import { verifyElement } from '../../../services/admin/participantForms';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import {
  checkAvailable,
  findElement,
  findElementByName,
  findElements,
  matchAddress,
  modifyJson,
  randomUUID,
} from '../../../utils/helpers';
import {
  additionalParticipantToggle,
  addPaddingDetails,
  addQRDetails,
  addDlDetails,
  addConvertedTexts,
} from '../../../store/slices/questionSlice';
import additionalSeller from '../../../utils/additionalSeller.json';
import AdditionalParticipantConfirmation from './AdditionalParticipantConfirmation';
import { useTranslation } from 'react-i18next';
import { createInvitation, getDLDetails } from '../../../services/questions/moby';
import Loader from '../../common/Loader';
import useRedux from '../../../utils/hooks/useRedux';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import axios from 'axios';
import { translateContent } from '../../../services/questions/home';
import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
import additionalSellerOld from '../../../utils/additionalSellerOld.json';

const excludedElements = [
  'label_193',
  'acknowledgement_privacy',
  'acknowledgement_engagement',
  'acknowledgement_release',
  'acknowledgement_third_party',
];

const StepDetails = ({
  authData,
  section,
  sections,
  sectionCount,
  activeSection,
  setActiveSection,
  orderId,
  setStepperDetails,
  token,
  setLoading,
  disabled,
  getQuestionData,
  sectionIdx,
  companyDetails,
  paddingDetails,
  orderInfo,
  oneStepForm,
  saveFormDataPHH,
}) => {
  const { t } = useTranslation();
  const address = companyDetails?.full_address?.length
    ? companyDetails?.full_address
    : (companyDetails?.address_line_1 ? companyDetails?.address_line_1 + ', ' : '') +
    (companyDetails?.address_line_2 ? companyDetails?.address_line_2 + ', ' : '') +
    (companyDetails?.city ? companyDetails?.city + ', ' : '') +
    (companyDetails?.state ? companyDetails?.state + ', ' : '') +
    (companyDetails?.zip_code ? companyDetails?.zip_code : '');
  const isSeller = authData?.participant_type_name?.toLowerCase().includes('seller');
  const isBorrower = authData?.participant_type_name?.toLowerCase().includes('borrower');
  const hideAddBtn =
    authData?.is_participant_form_separate_or_single &&
    authData?.is_participant_form_separate_or_single === 'separate';
  const dispatch = useDispatch();
  const { getFormValue } = useRedux();
  const { code, participantNo } = useParams();
  const isAdditionalParticipant = participantNo && participantNo != 1 ? true : false;
  const participantNum = participantNo || 1;
  const selectedLanguage = useSelector((state) => state.questionSlice.languages)?.[code];
  const languageForLabel = selectedLanguage && selectedLanguage !== 'en' ? selectedLanguage : '';
  const question = useSelector((state) => state.questionSlice.questionDetails)?.find(
    (que) => que.tx_id === code
  )?.questions;
  const translations = useSelector((state) => state.questionSlice.convertedTexts)?.find(
    (que) => que.tx_id === code
  );
  const dlDetails = useSelector((state) => state.questionSlice.dlDetails)?.[code];
  const qrDetails = useSelector((state) => state.questionSlice.qrDetails)?.[code];
  const incompleteSections = useSelector((state) => state.questionSlice.incompleteSections)?.[code];

  const additionalParticipants = useSelector(
    (state) => state.questionSlice.additionalParticipants
  )?.find((que) => que.tx_id === code);

  const [open, setOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [showUtilityBtn, setShowUtilityBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nextSectionTemp, setNextSectionTemp] = useState(null);
  const [prevSectionTemp, setPrevSectionTemp] = useState(null);
  const entityValue =
    getFormValue(IS_INDIVIDUAL_ENTITY) ||
    findElement(section, IS_INDIVIDUAL_ENTITY)?.user_value ||
    '';
  const activeSectionCount = question?.sections?.filter((sec) => !sec?.is_hidden)?.length;
  const singlePageForm =
    oneStepForm &&
    isBorrower &&
    +activeSectionCount === activeSection + 1 &&
    orderInfo?.order_create_type === 'imported';

  // below logic is to get latest state in function getMobydetails

  const qrDetailsRef = useRef(qrDetails);

  const findPrevNextIndex = (indexes, currentIndex) => {
    // Sort the array to ensure indexes are in order
    indexes.sort((a, b) => a - b);

    // Variables to store previous and next indexes
    let prevIndex = null;
    let nextIndex = null;

    for (let i = 0; i < indexes.length; i++) {
      if (indexes[i] < currentIndex) {
        prevIndex = indexes[i]; // Update prevIndex if current element is less than currentIndex
      } else if (indexes[i] > currentIndex) {
        nextIndex = indexes[i]; // Set nextIndex when we find a value greater than currentIndex
        break; // We can stop the loop once we find the nextIndex
      }
    }

    return { prev: prevIndex, next: nextIndex };
  };

  // below logic is to set next and prev incomplete sections on header

  useEffect(() => {
    if (incompleteSections?.length) {
      const curStepIndex = activeSection;
      const indexArr = incompleteSections?.map((sec) =>
        sections?.findIndex(
          (tempSec) => tempSec === sec?.section_label?.replace('(Upload Government Issued ID)', '')
        )
      );
      const { prev, next } = findPrevNextIndex(indexArr, curStepIndex);
      setPrevSectionTemp(prev && prev !== curStepIndex ? prev : null);
      setNextSectionTemp(next && next !== curStepIndex ? next : null);
    } else {
      setPrevSectionTemp(null);
      setNextSectionTemp(null);
    }
  }, [incompleteSections]);

  useEffect(() => {
    qrDetailsRef.current = qrDetails;
  }, [qrDetails]);

  useEffect(() => {
    if (dlSectionIds.includes(section.section_id)) {
      // window.Pusher = Pusher;
      const echo = new Echo({
        broadcaster: 'reverb',
        key: process.env.REACT_APP_REVERB_APP_KEY || 'kyruwxz6m0anjioauvxu',
        wsHost: window.location.hostname,
        wsPort: process.env.REACT_APP_REVERB_PORT || 8080,
        transports: ['websocket'],
        enabledTransports: ['ws', 'wss'],
        forceTLS: false,
        disableStats: true,
        authorizer: (channel) => {
          return {
            authorize: (socketId, callback) => {
              axios
                .post(
                  `${process.env.REACT_APP_BACKEND_URL}/broadcasting/auth`,
                  {
                    socket_id: socketId,
                    channel_name: channel.name,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((response) => {
                  callback(null, response.data);
                })
                .catch((error) => {
                  callback(error);
                });
            },
          };
        },
      });
      console.log('socket channel : ', `moby_dl_status.${authData?.id}`);
      echo
        .private(`moby_dl_status.${authData?.id}`)
        .listen('MobyInvitationResponseEvent', (data) => {
          console.log('Socket event called', data);
          getMobyDetails();
        })
        .error((error) => {
          console.error('Socket event error', error);
        });

      // echo
      //   .private(`moby_dl_status.308`)
      //   .listen('MobyInvitationResponseEvent', (data) => {
      //     console.log('Dummy test event called', data);
      //   })
      //   .error((error) => {
      //     console.error('Socket event error', error);
      //   });

      echo.connector.pusher.connection.bind('connected', () => {
        console.log('WebSocket connected');
      });

      echo.connector.pusher.connection.bind('disconnected', () => {
        console.log('WebSocket disconnected');
      });

      echo.connector.pusher.connection.bind('error', (error) => {
        console.error('WebSocket connection error', error);
      });
      return () => {
        echo.disconnect();
      };
    }
  }, []);
  // // for testing socket connection

  // useEffect(() => {
  //   testEventCall();
  // }, []);

  // const testEventCall = async () => {
  //   const res = await testEvent();
  // };

  useEffect(() => {
    if (section.section_id == UTILITY_SECTION_ID) {
      if (section?.groups[0]?.elements[0]?.user_value === 'Yes') {
        setShowUtilityBtn(true);
      }
    }
    // if (section.section_id == HOA_SECTION_ID) {
    //   const value = findElement(section, CREDIT_CARD_PARENT_ID)?.user_value;
    //   if (value === 'Yes') {
    //     dispatch(
    //       addCreditCardInfo({ show: true, info: creditCardInformation?.info || {}, tx_id: code })
    //     );
    //   } else {
    //     dispatch(addCreditCardInfo({ show: false, info: {}, tx_id: code }));
    //   }
    // }
    if (section.section_id == SOI_SECTION_ID && !additionalParticipants) {
      const value = findElement(section, ADDITIONAL_SELLER_PARENT_ID)?.user_value;
      if (value === 'Yes') {
        dispatch(
          additionalParticipantToggle({
            value: true,
            tx_id: code,
          })
        );
      } else {
        dispatch(
          additionalParticipantToggle({
            value: false,
            tx_id: code,
          })
        );
      }
    }
  }, [section.section_id]);

  useEffect(() => {
    let selectedEntityValue = entityValue;
    if (isAdditionalParticipant) {
      const entityElem = findElementByName(
        section,
        authData?.participant_type_name?.toLowerCase() +
        ADDITIONAL_SELLER_TYPE +
        `_${participantNo}`
      );
      if (entityElem?.user_value) selectedEntityValue = entityElem?.user_value;
    }
    if (
      selectedEntityValue &&
      selectedEntityValue === 'Individual' &&
      dlSectionIds.includes(section.section_id)
    ) {
      getMobyDetails();
    }
  }, [section.section_id, entityValue]);

  const getUserAddress = () => {
    let userAddress = {};
    const currentOrOtherAddress =
      getFormValue(MAILING_ADDRESS_QUESTION) ||
      findElement(section, MAILING_ADDRESS_QUESTION)?.user_value ||
      '';
    if (!currentOrOtherAddress) return userAddress;
    if (currentOrOtherAddress === 'Other') {
      const address1 =
        getFormValue(MAILING_ADDRESS_ADDRESS) ||
        findElement(section, MAILING_ADDRESS_ADDRESS)?.user_value ||
        '';
      const state =
        getFormValue(MAILING_ADDRESS_STATE) ||
        findElement(section, MAILING_ADDRESS_STATE)?.user_value ||
        '';
      const city =
        getFormValue(MAILING_ADDRESS_CITY) ||
        findElement(section, MAILING_ADDRESS_CITY)?.user_value ||
        '';
      const zip =
        getFormValue(MAILING_ADDRESS_ZIP) ||
        findElement(section, MAILING_ADDRESS_ZIP)?.user_value ||
        '';
      userAddress = {
        site_address: address1,
        site_city: city,
        site_state: state,
        site_zip: `${zip}`,
        // site_unit: unit,
        site_full_address: address1 + ',' + city + ',' + state + ',' + zip,
      };
    } else {
      userAddress = {
        site_address: orderInfo?.property_address_1,
        site_city: orderInfo?.property_city,
        site_state: orderInfo?.property_state,
        site_zip: `${orderInfo?.property_zipcode}`,
        // site_unit: unit,
        site_full_address:
          orderInfo?.property_address_1 +
          ',' +
          orderInfo?.property_city +
          ',' +
          orderInfo?.property_state +
          ',' +
          orderInfo?.property_zipcode,
      };
    }
    return userAddress;
  };
  const getMobyDetails = async () => {
    setIsLoading(true);
    try {
      const res = await getDLDetails(token, authData?.id);
      if (
        Object.keys(res?.response?.transaction_response || {})?.length &&
        res?.response?.transaction_id
      ) {
        let data = { ...res.response };
        if (data?.transaction_response?.CustomerAddress) {
          const userAddr = getUserAddress();
          if (data?.transaction_response?.CustomerAddress) {
            const addressResult = await matchAddress(
              data?.transaction_response?.CustomerAddress,
              userAddr,
              token
            );
            data = {
              ...data,
              errors: addressResult,
              state: addressResult?.state ? addressResult.state : null,
            };
          }
        }
        // if (qrDetailsRef?.current?.regenerated) {
        //   const res = await addUserConcent(authData?.token, {
        //     participant_uuid: code,
        //   });
        //   if (res.success) {
        //     data.transaction_response.user_consent = true;
        //   }
        // }
        dispatch(
          addDlDetails({
            tx_id: code,
            data: { ...data, review: dlDetails?.review ? dlDetails?.review : {} },
          })
        );
      } else {
        const res = await createInvitation(token, {
          participant_uuid: code,
          client_id: companyDetails.client_id,
          client_office_id: companyDetails.client_office_id,
          order_participant_id: authData?.id,
        });

        if (res.success) {
          dispatch(
            addQRDetails({
              tx_id: code,
              data: {
                ...res.response,
                regenerated: qrDetailsRef?.current?.regenerated ? true : false,
                isError: qrDetailsRef?.current?.isError ? true : false,
              },
            })
          );
        }
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  // const getQrCode = async () => {
  //   setLoading(true);
  //   const res = await createInvitation(token, {
  //     participant_uuid: code,
  //     client_id: companyDetails.client_id,
  //     client_office_id: companyDetails.client_office_id,
  //   });
  //   if (res.success) {
  //     dispatch(addQRDetails({ tx_id: code, data: { ...res.response } }));
  //   }
  //   setLoading(false);
  // };

  // const getDL = async () => {
  //   setLoading(true);
  //   const res = await getDLDetails(token, code);
  //   if (res.success) {
  //     dispatch(addQRDetails({ tx_id: code, data: { ...res.response } }));
  //   }
  //   setLoading(false);
  // };

  const translateContentAndUpdate = async (elementsForTranslations, options) => {
    try {
      const alreadyTranslatedLanguages = Object.keys(translations || {});
      for (const language of alreadyTranslatedLanguages) {
        if (language === 'tx_id') continue;
        let translatedContentElements = {};
        let translatedContentOptions = {};
        if (elementsForTranslations?.length) {
          const body = {
            text: elementsForTranslations.map((elem) => elem.label),
            target: language,
          };
          const res = await translateContent(body, token);
          if (res.success) {
            const staticTranslations = res?.response?.data?.translations?.map(
              (translation) => translation.translatedText
            );
            staticTranslations.forEach((translatedLabel, index) => {
              translatedContentElements[elementsForTranslations[index]['id']] = translatedLabel;
            });
          }
        }
        if (options?.length) {
          const body = {
            text: options.map((elem) => elem.label),
            target: language,
          };
          const res = await translateContent(body, token);
          if (res.success) {
            const staticTranslations = res?.response?.data?.translations?.map(
              (translation) => translation.translatedText
            );
            staticTranslations.forEach((translatedLabel, index) => {
              translatedContentOptions[options[index]['id']] = translatedLabel;
            });
          }
        }

        dispatch(
          addConvertedTexts({
            tx_id: code,
            language: language,
            translations: {
              ...translations?.[language],
              elements: { ...translations?.[language]?.elements, ...translatedContentElements },
              options: { ...translations?.[language]?.options, ...translatedContentOptions },
            },
          })
        );
      }
    } catch (e) {
      toast.error(
        'Something went wrong! We were unable to translate the new fields into your selected language.'
      );
      await getQuestionData();
    }
  };

  const labelFinder = (() => {
    if (section?.section_id === LIEN_SECTION_ID) {
      return 'Lien Details';
    } else if (section.section_id === UTILITY_SECTION_ID) {
      return 'Utility Service';
    } else {
      return '';
    }
  })();

  const lastSection = sectionCount;
  const onExtraFieldsSave = async (fields) => {
    setLoading(true);
    let tempJson = cloneDeep(question);
    if (labelFinder === 'Utility Service') {
      tempJson.sections[sectionIdx].groups[0].elements[0].options[0].elements.push(fields);
    } else {
      tempJson.sections[sectionIdx].groups[0].elements.push(fields);
    }
    const res = await verifyElement(
      code,
      {
        cached_questions_json: {
          question: cloneDeep(tempJson),
        },
        is_completed: false,
      },
      token
    );
    if (res?.success) {
      let contentToTranslate = findElements([fields]);
      await translateContentAndUpdate(
        contentToTranslate?.elements || [],
        contentToTranslate?.options || []
      );
      await getQuestionData(code);
      toast.success(res?.message);
      setOpen(false);
    } else {
      toast.error(res?.message);
    }
    setLoading(false);
  };

  const currentParticipantFinder = () => {
    if (isSeller) {
      const mainElem = findElement(section, ADDITIONAL_SELLER_PARENT_ID);
      const elements = mainElem?.options?.[0]?.elements;
      // const filteredElements = elements?.filter((elem) =>
      //   elem?.element_name?.startsWith('seller_first_name')
      // );
      let lastElem = {};
      for (const elem of elements) {
        if (!elem?.prepopulated_participant && !elem?.is_private) {
          lastElem = elem;
        }
      }
      // const lastElem = elem?.[elem?.length - 1];
      return lastElem?.participant_number ? +lastElem?.participant_number : 1;
    }
    return 2;
  };

  /* Need to refactor this code for adding additional participants, combine at one place (radio) */
  const addParticipants = async () => {
    setLoading(true);
    let tempJson = cloneDeep(question);
    let currentParticipantsAdded = currentParticipantFinder();
    let tempElements = authData?.is_old_record
      ? cloneDeep(additionalSellerOld)
      : cloneDeep(additionalSeller);
    for (const tempElement of tempElements) {
      tempElement.by_participant = 'Y';
      tempElement[tempElement?.is_private ? 'participant_no' : 'participant_number'] =
        currentParticipantsAdded + 1;
      tempElement.element_name = tempElement.element_name + (currentParticipantsAdded + 1);
      tempElement.element_id = randomUUID();
      tempElement?.options?.forEach((option) => {
        option.option_id = randomUUID();
        option?.elements?.forEach((element) => {
          element.by_participant = 'Y';
          element[element?.is_private ? 'participant_no' : 'participant_number'] =
            currentParticipantsAdded + 1;
          element.element_name = element.element_name + (currentParticipantsAdded + 1);
          element.element_id = randomUUID();
        });
      });
    }
    const updatedJson = modifyJson(
      tempJson,
      tempElements,
      SOI_SECTION_ID,
      ADDITIONAL_SELLER_GROUP_ID,
      ADDITIONAL_SELLER_PARENT_ID,
      ADDITIONAL_SELLER_OPTION_ID
    );
    if (updatedJson) {
      const res = await verifyElement(
        code,
        {
          cached_questions_json: {
            question: cloneDeep(updatedJson),
          },
          is_completed: false,
        },
        token
      );
      if (res?.success) {
        let contentToTranslate = findElements(tempElements);
        await translateContentAndUpdate(
          contentToTranslate?.elements || [],
          contentToTranslate?.options || []
        );
        await getQuestionData(code);
        setConfirmationModalOpen(false);
      } else {
        toast.error(res?.message);
      }
    } else {
      toast.error('Something went wrong! Please try again');
    }
    setLoading(false);
  };
  return (
    <>
      {isLoading ? <Loader /> : ''}
      <Card id='card-main-form' style={{ borderRadius: '10px', boxShadow: 'none' }}>
        {/* <CardComponent /> */}

        <div className='d-flex flex-column pl-8 pr-12 pt-8 pb-8 participant-header-bg participant-header-color'>
          <div className='d-flex justify-between align-items-center w-100p'>
            {' '}
            <div>
              <IconButton onClick={() => setStepperDetails(false)} disabled={activeSection < 1}>
                <RiArrowLeftSLine />
              </IconButton>
              {/* {window?.innerWidth > 600 && prevSectionTemp ? (
                <Button
                  className='f-13'
                  onClick={() => setStepperDetails(false, false, prevSectionTemp)}
                >
                  Go To Previous Incomplete Step
                </Button>
              ) : (
                ''
              )} */}
            </div>
            <div className='card-header-title participant-header-color'>
              {languageForLabel
                ? translations?.[languageForLabel]?.sections?.[section?.section_id]
                  ? translations?.[languageForLabel]?.sections?.[section?.section_id]
                  : section?.section_label
                : section?.section_label}
            </div>
            <div>
              {/* {window?.innerWidth > 600 &&
              incompleteSections?.length &&
              incompleteSections?.length === 1 &&
              !prevSectionTemp &&
              !nextSectionTemp ? (
                <Button
                  className='f-13'
                  onClick={() => setStepperDetails(true, false, sections?.length)}
                >
                  Go To Last Step
                </Button>
              ) : (
                ''
              )}
              {window?.innerWidth > 600 && nextSectionTemp ? (
                <Button
                  className='f-13'
                  onClick={() => setStepperDetails(true, false, nextSectionTemp)}
                >
                  Go To Next Incomplete Step
                </Button>
              ) : (
                ''
              )} */}
              <IconButton
                onClick={() => setStepperDetails(true)}
                disabled={activeSection > lastSection || singlePageForm}
              >
                <RiArrowRightSLine />
              </IconButton>
            </div>
          </div>
          {/* {window.innerWidth <= 600 && (
            <div className='d-flex justify-between align-items-center w-100p'>
              {' '}
              <div>
                {prevSectionTemp && (
                  <Button
                    className='f-13'
                    onClick={() => setStepperDetails(false, false, prevSectionTemp)}
                  >
                    Prev Incomplete Step
                  </Button>
                )}
              </div>
              <div>
                {incompleteSections?.length &&
                incompleteSections?.length === 1 &&
                !prevSectionTemp &&
                !nextSectionTemp ? (
                  <Button
                    className='f-13'
                    onClick={() => setStepperDetails(true, false, sections?.length)}
                  >
                    Last Step
                  </Button>
                ) : (
                  ''
                )}
                {nextSectionTemp ? (
                  <Button
                    className='f-13'
                    onClick={() => setStepperDetails(true, false, nextSectionTemp)}
                  >
                    Next Incomplete Step
                  </Button>
                ) : (
                  ''
                )}
              </div>
            </div>
          )} */}
        </div>
        <div className='scrollable-card'>
          <CardContent className='card-content' style={{ minHeight: '78vh', overflowY: 'scroll' }}>
            <form className='inline-form'>
              {(section?.groups ?? []).map((group, idx) => {
                if (group?.is_hidden) return;
                let grpParticipant = group.participant_no || 1;
                if (grpParticipant != participantNum && group.is_private) {
                  return;
                }
                const elements = group.elements ?? [];
                const isAvailable = checkAvailable(group?.elements || [], participantNum);
                if (!isAvailable) return;
                return (
                  <React.Fragment key={group.group_id}>
                    {idx == 0 && activeSection != lastSection && !singlePageForm && (
                      <div className='f-12 fw-500 color-g mt-8'>{t('note')}</div>
                    )}

                    <hr />
                    <h4 style={{ fontSize: '15.5px' }} className='mb-24 fw-650 color-p'>
                      {languageForLabel && languageForLabel !== 'en'
                        ? translations?.[languageForLabel]?.groups?.[group?.group_id]
                          ? translations?.[languageForLabel]?.groups?.[group?.group_id]
                          : group?.group_label
                        : group?.group_label}
                    </h4>
                    <Grid container spacing={1} className='p-form'>
                      {elements.map((element, elementIdx) => {
                        if (excludedElements.includes(element.element_name) || !element?.display) {
                          return;
                        }
                        return (
                          <DocumentField
                            key={elementIdx}
                            element={element}
                            orderId={orderId}
                            disabled={disabled}
                            setShowUtilityBtn={setShowUtilityBtn}
                            getUserAddress={getUserAddress}
                            saveFormDataPHH={saveFormDataPHH}
                          />
                        );
                      })}
                    </Grid>
                  </React.Fragment>
                );
              })}
            </form>
            {(labelFinder === 'Utility Service' ? showUtilityBtn : true) &&
              labelFinder?.length &&
              participantNum == 1 ? (
              <div className='d-flex justify-end'>
                <Tooltip title={'You can add extra details regarding this section.'}>
                  <Button
                    variant='outlined'
                    style={{ display: 'flex', height: '30px' }}
                    onClick={() => {
                      setOpen(true);
                    }}
                    disabled={disabled}
                  >
                    {labelFinder === 'Utility Service' ? t('addUtility') : t('addLien')}
                  </Button>
                </Tooltip>
              </div>
            ) : (
              ''
            )}
            {additionalParticipants?.value &&
              section.section_id === SOI_SECTION_ID &&
              participantNum == 1 &&
              isSeller &&
              !hideAddBtn ? (
              <div className='d-flex justify-end'>
                <Tooltip title={'You can add additional participants for this transaction.'}>
                  <Button
                    variant='outlined'
                    style={{ display: 'flex', height: '30px' }}
                    onClick={() => setConfirmationModalOpen(true)}
                    disabled={disabled}
                  >
                    {t('addSeller')}
                  </Button>
                </Tooltip>
              </div>
            ) : (
              ''
            )}
          </CardContent>

          <hr className='hr-label' />
          {/* <div className='d-flex justify-between align-items-center w-100p'>
            {' '}
            <div>
              {prevSectionTemp && (
                <Button onClick={() => setStepperDetails(false, false, prevSectionTemp)}>
                  Prev Incomplete Step
                </Button>
              )}
            </div>
            <div>
              {incompleteSections?.length &&
              incompleteSections?.length === 1 &&
              !prevSectionTemp &&
              !nextSectionTemp ? (
                <Button onClick={() => setStepperDetails(true, false, sections?.length)}>
                  Last Step
                </Button>
              ) : (
                ''
              )}
              {nextSectionTemp ? (
                <Button onClick={() => setStepperDetails(true, false, nextSectionTemp)}>
                  Next Incomplete Step
                </Button>
              ) : (
                ''
              )}
            </div>
          </div> */}
          {window.innerWidth <= 600 && (
            <div className='d-flex justify-between align-items-center p-4 mb-8'>
              {' '}
              <div>
                {prevSectionTemp && (
                  <Button
                    variant='outlined'
                    className='p-4 f-13'
                    onClick={() => setStepperDetails(false, false, prevSectionTemp)}
                  >
                    {/* <KeyboardDoubleArrowLeft className='back-icon' style={{ fontSize: '14px' }} /> */}
                    Prev Incomplete Step
                  </Button>
                )}
              </div>
              <div>
                {incompleteSections?.length &&
                  incompleteSections?.length === 1 &&
                  !prevSectionTemp &&
                  !nextSectionTemp ? (
                  <Button
                    variant='outlined'
                    className='p-4 f-13'
                    onClick={() => setStepperDetails(true, false, sections?.length)}
                  >
                    Last Step
                  </Button>
                ) : (
                  ''
                )}
                {nextSectionTemp ? (
                  <Button
                    variant='outlined'
                    className='p-4 f-13'
                    onClick={() => setStepperDetails(true, false, nextSectionTemp)}
                  >
                    <div>Next Incomplete Step</div>
                    {/* <KeyboardDoubleArrowRight
                      className='forward-icon'
                      style={{ fontSize: '18px', marginLeft: '4px' }}
                    /> */}
                  </Button>
                ) : (
                  ''
                )}
              </div>
            </div>
          )}
          <Grid container spacing={1} className='next-button'>
            <Grid xs={6} md={5.5} item={true}>
              {activeSection > 0 && (
                <Button
                  variant='contained'
                  color='primary'
                  size='lg'
                  onClick={() => setActiveSection(activeSection - 1)}
                // className='btn-section'
                >
                  <ArrowBackIcon
                    className='back-icon'
                    style={{ fontSize: '18px', marginRight: '4px' }}
                  />
                  {t('prevStep')}
                </Button>
              )}{' '}
              {window.innerWidth > 600 && prevSectionTemp && (
                <Button
                  variant='outlined'
                  className='p-6 f-13-5 mr-4'
                  onClick={() => setStepperDetails(false, false, prevSectionTemp)}
                >
                  <KeyboardDoubleArrowLeft
                    className='back-icon'
                    style={{ fontSize: '18px', marginRight: '4px' }}
                  />
                  Prev Incomplete Step
                </Button>
              )}
            </Grid>
            {window.innerWidth > 768 && (
              <Grid xs={4} md={1} item={true} className='d-flex justify-center'>
                <Tooltip
                  title={paddingDetails?.isCollapsed ? 'Expand Form' : 'Shrink Form to Center'}
                >
                  <Button
                    onClick={() => {
                      dispatch(
                        addPaddingDetails({
                          tx_id: code,
                          isCollapsed: paddingDetails?.isCollapsed ? false : true,
                          padding: paddingDetails?.isCollapsed ? 2 : 12,
                        })
                      );
                    }}
                  >
                    {paddingDetails?.isCollapsed ? (
                      <i class='fa-solid fa-expand' style={{ fontSize: '18px' }}></i>
                    ) : (
                      <i class='fa-solid fa-compress' style={{ fontSize: '18px' }}></i>
                    )}
                  </Button>
                </Tooltip>
              </Grid>
            )}

            <Grid xs={6} md={5.5} item={true} className='d-flex justify-end step-btn'>
              {window.innerWidth > 600 &&
                incompleteSections?.length &&
                incompleteSections?.length === 1 &&
                !prevSectionTemp &&
                !nextSectionTemp ? (
                <Button
                  variant='outlined'
                  className='p-6 f-13-5 mr-4'
                  onClick={() => setStepperDetails(true, false, sections?.length)}
                >
                  Last Step
                  <KeyboardDoubleArrowRight
                    className='forward-icon'
                    style={{ fontSize: '18px', marginLeft: '4px' }}
                  />
                </Button>
              ) : (
                ''
              )}
              {window.innerWidth > 600 && nextSectionTemp ? (
                <Button
                  variant='outlined'
                  className='p-6 f-13-5 mr-4'
                  onClick={() => setStepperDetails(true, false, nextSectionTemp)}
                >
                  <div>Next Incomplete Step</div>
                  <KeyboardDoubleArrowRight
                    className='forward-icon'
                    style={{ fontSize: '18px', marginLeft: '4px' }}
                  />
                </Button>
              ) : (
                ''
              )}{' '}
              {activeSection < lastSection && !singlePageForm && (
                <Button
                  variant='contained'
                  color='primary'
                  size='lg'
                  onClick={() => setStepperDetails(true)}
                // className='btn-section'
                >
                  <Typography component='div' className='forward'>
                    {t('nextStep')}
                  </Typography>
                  <ArrowForwardIcon
                    className='forward-icon'
                    style={{ fontSize: '18px', marginLeft: '4px' }}
                  />
                </Button>
              )}
              {singlePageForm ? (
                <Button
                  variant='contained'
                  color='primary'
                  size='lg'
                  onClick={() => saveFormDataPHH()}
                // className='btn-section'
                >
                  <Typography component='div' className='forward'>
                    {'Save'}
                  </Typography>
                </Button>
              ) : (
                ''
              )}
            </Grid>
          </Grid>

          <div className='hr-no-margin'>
            <hr />
          </div>
          <CardActions className='footer' style={{ maxHeight: 'unset' }}>
            <div className='d-flex flex-column justify-center'>
              <Box variant='div' component='div' className='d-flex justify-center'>
                <img
                  src={companyDetails?.company_logo_url ? companyDetails?.company_logo_url : logo}
                  alt='logo-main'
                  loading='lazy'
                  className='logo-size'
                />
              </Box>
              <Typography component='div' className='copy-right' style={{ fontWeight: '500' }}>
                {companyDetails?.office_name || '-'}, {address || '-'}
              </Typography>
            </div>
          </CardActions>
        </div>
      </Card>
      <SectionExtraDetailsModal
        open={open}
        setOpen={setOpen}
        label={labelFinder === 'Utility Service' ? t('addUtility') : t('addLien')}
        section_id={section?.section_id}
        onSubmit={onExtraFieldsSave}
      />
      <AdditionalParticipantConfirmation
        open={confirmationModalOpen}
        setOpen={setConfirmationModalOpen}
        handleSave={addParticipants}
      />
    </>
  );
};
export default StepDetails;
