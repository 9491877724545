import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormControlLabel, FormHelperText, Checkbox, Grid } from '@mui/material';
import useRedux from '../../../../utils/hooks/useRedux';
import { areAllElementsSame, errorChecker, fetchSourceData } from '../../../../utils/helpers';
import { useSelector } from 'react-redux';

const LCheckbox = ({ element, disabled }) => {
  const { code } = useParams();
  const selectedLanguage = useSelector((state) => state.questionSlice.languages)?.[code];
  const languageForLabel = selectedLanguage && selectedLanguage !== 'en' ? selectedLanguage : '';
  const translations = useSelector((state) => state.questionSlice.convertedTexts)?.find(
    (que) => que.tx_id === code
  );
  const { setFormValue, getFormValue, getErrorDetails, removeError, setError, setTouched } =
    useRedux();
  const value = getFormValue(element.element_id);
  const { error, error_message } = getErrorDetails(element.element_id) || {
    error: false,
    error_message: '',
  };
  const [checked, setChecked] = useState(false);
  const [sourceList, setSourceList] = useState([]);
  const [disabledField, setDisabledField] = useState(false);

  useEffect(() => {
    setSourceList(fetchSourceData(element));
  }, [element]);

  useEffect(() => {
    if (
      (sourceList?.length === 1 || areAllElementsSame(sourceList.map((source) => source?.value))) &&
      !element.user_value
    ) {
      if (sourceList[0]?.value === 'Yes' || sourceList[0]?.value === true) {
        setChecked(true);
        setFormValue({ id: element.element_id, value: true });
        // setDisabledField(true);
      } else {
        setChecked(false);
        setFormValue({ id: element.element_id, value: false });
        // setDisabledField(true);
      }
    }
  }, [sourceList]);

  useEffect(() => {
    if (value !== undefined) setChecked(value);
    else if (element.user_value == true) setChecked(true);
  }, [value, element.user_value]);

  const handleChange = (event) => {
    setTouched(element.element_id);
    setChecked(event.target.checked);
    setFormValue({ id: element.element_id, value: event.target.checked });
    handleError(event.target.checked);
  };

  const handleError = (value) => {
    const { isError, message } = errorChecker(element, value);
    if (!isError) {
      removeError(element.element_id);
    } else {
      setError({ id: element.element_id, error: true, error_message: message });
    }
  };

  const CustomLabel = () => {
    const labelContent = languageForLabel
      ? translations?.[languageForLabel]?.elements?.[element.element_id] || element?.label
      : element?.label;
    return (
      <span className="checkbox-label-wrapper">
        <span
          dangerouslySetInnerHTML={{
            __html: labelContent,
          }}
        />
        {element.required && (
          <label className="required">*</label>
        )}
      </span>
    );
  };

  return (
    <Grid xs={12} md={12} spacing={1} className='grid-container plt-8 light-bg-field'>
      {element.label !== '' && (
        <Grid xs={12} item={true}>
          <FormControlLabel
            className='form-checkbox'
            label={<CustomLabel />}
            checked={checked === true}
            onChange={handleChange}
            disabled={disabled || disabledField}
            control={
              error ? (
                <Checkbox
                  sx={{
                    color: 'red !important',
                    '&.Mui-checked': {
                      color: 'red !important',
                    },
                  }}
                />
              ) : (
                <Checkbox color='primary' />
              )
            }
          />
          {/* <FormHelperText error={true}>{error_message || ''}</FormHelperText> */}
        </Grid>
      )}
    </Grid>
  );
};

export default LCheckbox;
