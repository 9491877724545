import { searchPropertyDetails } from './order';

const dummyRes = {
  sa_parcel_nbr_primary: '46327216',
  sa_site_house_nbr: '18721',
  sa_site_fraction: '',
  sa_site_dir: '',
  sa_site_street_name: 'Paseo Picasso',
  sa_site_post_dir: '',
  sa_site_suf: '',
  sa_site_unit_val: '',
  sa_site_city: 'Irvine',
  sa_site_zip: '92603',
  sa_site_state: 'Ca',
  sa_x_coord: '-117.823717',
  sa_y_coord: '33.649439',
  sa_nbr_bedrms: '3',
  sa_nbr_bath: '2',
  sa_lotsize: '3700',
  use_code_std: 'Rsfr',
  sa_site_mail_same: 'Y',
  sa_owner_1: 'Tony Stark',
  sa_owner_1_first: 'Tony',
  sa_owner_1_last: 'Stark',
  sa_owner_2_first: '',
  sa_owner_2_last: '',
  sa_owner_1_sp_first: '',
  sa_owner_1_sp_mid: '',
  sa_date_transfer: '2011-04-07',
  sa_val_transfer: '643000',
  sa_subdivision: '',
  sa_yr_blt: '1973',
  sa_nbr_units: '0',
  mm_muni_name: 'Orange',
  sa_sqft: '1512',
  sa_property_id: '0039025849',
  mm_fips_state_code: '06',
  mm_fips_muni_code: '059',
  sa_mail_house_nbr: '18721',
  sa_mail_fraction: '',
  sa_mail_dir: '',
  sa_mail_street_name: 'Paseo Picasso',
  sa_mail_suf: '',
  sa_mail_post_dir: '',
  sa_mail_unit_val: '',
  sa_mail_city: 'Irvine',
  sa_mail_zip: '92603',
  sa_mail_state: 'Ca',
  sa_mail_plus_4: '3328',
  sa_val_assd: '776278',
  sa_assr_year: '2022',
  sa_val_market: '0',
  sa_tax_val: '8137.66',
  sa_tax_year: '2022',
  sa_bsmt_fin_sqft: '0',
  sa_bsmt_unfin_sqft: '0',
  sa_parcel_nbr_reference: '',
  v_tract: '',
  v_unit: '',
  v_mail_address: '18721 Paseo Picasso',
  v_site_address: '18721 Paseo Picasso',
  formatted_sa_owner_1: 'Shafi Siddiqi',
};

// export const addressVerification = async (data, token) => {
//   try {
//     if (data?.site_address?.includes('test')) {
//       return { success: true, data: dummyRes, message: 'Address verified' };
//     }
//     // const authData = await axios.post(
//     //   'https://direct.api.titletoolbox.com/webservices/login.json',
//     //   {
//     //     TbUser: {
//     //       username: process.env.REACT_APP_BENUTECH_USER_NAME,
//     //       password: process.env.REACT_APP_BENUTECH_PASSWORD,
//     //     },
//     //   },
//     //   {
//     //     headers: { 'Partner-Key': process.env.REACT_APP_BENUTECH_PARTNER_KEY },
//     //     withCredentials: true,
//     //   }
//     // );

//     // if (authData?.data?.response?.status === 'OK' && authData?.data?.response?.data?.TTBSID) {
//     //   const addressData = await axios.post(
//     //     'https://direct.api.titletoolbox.com/webservices/search_property/ttb.json',
//     //     data,
//     //     {
//     //       headers: {
//     //         'Partner-Key': process.env.REACT_APP_BENUTECH_PARTNER_KEY,
//     //         Cookie: `TTBSID=${authData?.data?.response?.data?.TTBSID}`,
//     //       },
//     //       withCredentials: true,
//     //     }
//     //   );
//     //   if (addressData?.data?.response?.status === 'OK' && addressData?.data?.response?.data) {
//     //     const loanData = await axios.post(
//     //       'https://direct.api.titletoolbox.com/webservices/property_details.json',
//     //       {
//     //         property_id: addressData?.data?.response?.data[0]?.sa_property_id,
//     //         state_fips: addressData?.data?.response?.data[0]?.mm_fips_state_code,
//     //       },
//     //       {
//     //         headers: {
//     //           'Partner-Key': process.env.REACT_APP_BENUTECH_PARTNER_KEY,
//     //           Cookie: `TTBSID=${authData?.data?.response?.data?.TTBSID}`,
//     //         },
//     //         withCredentials: true,
//     //       }
//     //     );
//     //     if (loanData?.data?.response?.status === 'OK') {
//     //       return {
//     //         success: true,
//     //         data: addressData?.data?.response?.data?.[0] || {},
//     //         multiple: addressData?.data?.response?.data?.length > 1 ? true : false,
//     //         loan: loanData?.data?.response?.data?.SalesHistory?.[0] || {},
//     //         ownerType: loanData?.data?.response?.data?.OwnerInfo?.OwnerType || '',
//     //         message: 'Address verified',
//     //       };
//     //     }
//     //     return {
//     //       success: true,
//     //       multiple: addressData?.data?.response?.data?.length > 1 ? true : false,
//     //       data: addressData?.data?.response?.data[0],
//     //       message: 'Address verified',
//     //     };
//     //   } else {
//     //     return {
//     //       success: false,
//     //       data: {},
//     //       message: 'Address not verified',
//     //     };
//     //   }
//     // } else {
//     //   return {
//     //     success: false,
//     //     data: {},
//     //     message: 'Address not verified',
//     //   };
//     // }
//
//   } catch (err) {
//     return {
//       success: false,
//       data: {},
//       message: 'Something went wrong please try again in sometime',
//     };
//   }
// };

export const addressVerification = async (data, token) => {
  if (data?.site_address?.includes('test')) {
    return { success: true, data: [dummyRes], message: 'Address verified' };
  }
  const res = await searchPropertyDetails(token, data);
  if (res?.success) {
    return {
      success: true,
      data: res?.response?.search_property || [],
      multiple: res?.response?.is_multiple,
      loan: res?.response?.SalesHistory?.[0] || {},
      ownerType: res?.response?.OwnerInfo?.OwnerType || '',
      financial_history: res?.response?.FinanceHistory || [],
      sales_history: res?.response?.SalesHistory || [],
      owner_info: res?.response?.OwnerInfo || [],
      message: 'Address verified',
    };
  } else {
    return {
      success: false,
      data: {},
      message: 'Address not verified',
    };
  }
};
