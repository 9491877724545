import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Card,
  IconButton,
  CardContent,
  CardActions,
  Button,
  Box,
  Grid,
  Typography,
  Tooltip,
  Alert,
} from '@mui/material';
import { toast } from 'react-toastify';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SignatureCanvas from 'react-signature-canvas';
import { cl } from '@twipped/utils';
import SubmissionStep from './SubmissionStep';
import { getDocList, saveAnswer, uploadSignature } from '../../../services/questions/home';
import logo from '../../../assets/images/Voxtur-verify.png';
import MediaLink from '../../common/fields/media-link';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import { CheckCircleOutline } from '@mui/icons-material';
import DocConfirmation from '../../common/DocConfirmation';
// import {
//   BORROWER_LENDER_SECTION,
//   BORROWER_LOAN_2,
//   LENDER_INFO_ID,
//   LOAN_2,
// } from '../../../utils/constants';
// import { findElement } from '../../../utils/helpers';
import {
  addCurrentStep,
  addIncompleteSectionDetails,
  addPaddingDetails,
} from '../../../store/slices/questionSlice';
import { useTranslation } from 'react-i18next';
import {
  ADDITIONAL_SELLER_TYPE,
  dlSectionIds,
  IS_INDIVIDUAL_ENTITY,
  SOI_SECTION_ID,
} from '../../../utils/constants';
import useRedux from '../../../utils/hooks/useRedux';
import { checkFormFieldError, findElement, findElementByName } from '../../../utils/helpers';
import ValidationErrorModal from './ValidationErrorModal';

// const excludedElements = [
//   'label_193',
//   'acknowledgement_privacy',
//   'acknowledgement_engagement',
//   'acknowledgement_release',
//   'acknowledgement_third_party',
// ];

// const sellerDocs = [
//   {
//     label: 'Privacy Policy',
//     name: 'privacy_policy',
//   },
//   {
//     label: 'Third Party Authorization',
//     name: 'third_party_auth',
//   },
//   {
//     label: 'Release Authorization',
//     name: 'release_authorization',
//   },
//   {
//     label: 'Disbursement Seller Proceeds',
//     name: 'disbursement_seller_proceeds',
//   },

//   {
//     label: 'Demand Request 1',
//     name: 'demand_request_1',
//   },
// ];

// const buyerDocs = [
//   {
//     label: 'Disbursement Buyer Proceeds',
//     name: 'disbursement_buyer_proceeds',
//   },
// ];

// const borrowerDocs = [
//   {
//     label: 'Privacy Policy',
//     name: 'privacy_policy',
//   },
//   {
//     label: 'Third Party Authorization',
//     name: 'third_party_auth',
//   },
//   {
//     label: 'Release Authorization',
//     name: 'release_authorization',
//   },
//   {
//     label: 'Demand Request 1',
//     name: 'demand_request_1',
//   },
// ];

const LastStep = ({
  authData,
  activeSection,
  setActiveSection,
  signature,
  spouseSignature,
  orderId,
  isShowSubmissionPage,
  setShowSubmissionPage,
  token,
  setLoading,
  getSignatureData,
  disabled,
  getQuestionData,
  setStepperDetails,
  companyDetails,
  paddingDetails,
  sections,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getFormValue, getDocument } = useRedux();
  const address = companyDetails?.full_address?.length
    ? companyDetails?.full_address
    : (companyDetails?.address_line_1 ? companyDetails?.address_line_1 + ', ' : '') +
      (companyDetails?.address_line_2 ? companyDetails?.address_line_2 + ', ' : '') +
      (companyDetails?.city ? companyDetails?.city + ', ' : '') +
      (companyDetails?.state ? companyDetails?.state + ', ' : '') +
      (companyDetails?.zip_code ? companyDetails?.zip_code : '');
  // const isSeller = authData?.participant_type_name?.toLowerCase().includes('seller');
  // const isBuyer = authData?.participant_type_name?.toLowerCase().includes('buyer');
  // const isBorrower = authData?.participant_type_name?.toLowerCase().includes('borrower');
  const { code, participantNo } = useParams();
  const isAdditionalParticipant = participantNo && participantNo != 1 ? true : false;
  const order_part_participant_id = useSelector(
    (state) => state.questionSlice.questionDetails
  )?.find((que) => que.tx_id === code)?.order_part_participant_id;
  const question = useSelector((state) => state.questionSlice.questionDetails)?.find(
    (que) => que.tx_id === code
  )?.questions;
  const dlDetails = useSelector((state) => state.questionSlice.dlDetails)?.[code];

  const isAdditionalSeller = participantNo && participantNo != 1 ? true : false;
  const sigCanvas = useRef({});
  const sigCanvasSpouse = useRef({});
  const [hideOldSignature, setHideOldSignature] = useState(false);
  const [sigRequried, setSigRequried] = useState(false);
  const [hideOldSpouseSignature, setHideOldSpouseSignature] = useState(false);
  const [docConfirmationOpen, setDocConfirmationOpen] = useState(false);
  const [spouseSigRequired, setSpouseSigRequired] = useState(false);
  const [haveDocError, setHaveDocError] = useState(false);
  const [validationErrorModal, setValidationErrorModal] = useState(false);
  const [dlError, setDlError] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [isEmptySpouse, setIsEmptySpouse] = useState(true);
  const [docList, setDocList] = useState([]);
  const [dlSectionStep, setDlSectionStep] = useState(0);
  const [errorSections, setErrorSections] = useState([]);
  const [signatureDisabled, setSignatureDisabled] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');

  useEffect(() => {
    getDocs();
  }, []);

  const getDocs = async () => {
    setLoading(true);
    const res = await getDocList(order_part_participant_id, authData?.id, authData?.token);
    if (Object.keys(res || {})?.length) {
      let tempDocs = [];
      let disabled = false;
      for (const key in res) {
        if (!res?.[key]?.is_reviewed) disabled = true;
        tempDocs.push({
          name: res?.[key]?.doc_type,
          label: res?.[key]?.doc_name,
          is_reviewed: res?.[key]?.is_reviewed,
        });
      }
      setSignatureDisabled(disabled);
      setDocList(tempDocs);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!signature?.url) {
      setHideOldSignature(true);
    } else {
      setHideOldSignature(false);
    }
  }, [signature?.url]);

  useEffect(() => {
    if (!spouseSignature?.url) {
      setHideOldSpouseSignature(true);
    } else {
      setHideOldSpouseSignature(false);
    }
  }, [spouseSignature?.url]);

  const clearSignature = () => {
    setIsEmpty(true);
    if (hideOldSignature) sigCanvas?.current?.clear();
    setHideOldSignature(true);
  };

  const clearSpouseSignature = () => {
    setIsEmptySpouse(true);
    if (hideOldSpouseSignature) sigCanvasSpouse?.current?.clear();
    setHideOldSpouseSignature(true);
  };

  const saveSignature = () => {
    if (sigCanvas.current.isEmpty()) {
      return toast.error('Please add a signature.');
    }
    sigCanvas.current.getTrimmedCanvas().toBlob(async (blob) => {
      setLoading(true);
      if (blob.size < 300) {
        setLoading(false);
        setSigRequried(true);
        return;
      }
      const file = new File([blob], 'signature.png');

      const form = new FormData();
      form.append('signature', file);
      form.append('sign_user_type', 'participant');
      form.append('order_id', orderId);
      form.append('order_part_participant_id', order_part_participant_id);
      form.append('participant_no', participantNo || 1);

      const response = await uploadSignature(
        form,
        { headers: { 'Content-Type': 'multipart/form-data' } },
        token
      );
      setSigRequried(false);
      if (response?.success) {
        await getSignatureData();
        await getDocs();
        setHideOldSignature(false);
        toast.success(response?.message);
      } else {
        toast.error(response?.message || 'Something went wrong!');
      }
      setLoading(false);
    });
  };

  const saveSpouseSignature = () => {
    if (sigCanvasSpouse.current.isEmpty()) {
      return toast.error('Please add a signature.');
    }
    sigCanvasSpouse.current.getTrimmedCanvas().toBlob(async (blob) => {
      setLoading(true);
      if (blob.size < 1000) {
        setSpouseSigRequired(true);
        return;
      }
      const file = new File([blob], 'spouse-signature.png');

      const form = new FormData();
      form.append('signature', file);
      form.append('sign_user_type', authData?.is_spouse ? 'spouse' : 'participant');
      form.append('order_id', orderId);
      form.append('participant_no', participantNo || 1);
      form.append('order_part_participant_id', order_part_participant_id);

      const response = await uploadSignature(
        form,
        { headers: { 'Content-Type': 'multipart/form-data' } },
        token
      );
      setSpouseSigRequired(false);
      if (response?.success) {
        await getSignatureData();
        await getDocs();
        setHideOldSpouseSignature(false);
        toast.success(response?.message);
      } else {
        toast.error(response?.message || 'Something went wrong!');
      }
      setLoading(false);
    });
  };

  const saveAnswerData = async () => {
    if (!disabled) {
      let payload = {
        section_index: activeSection,
        data: {},
        values: {},
        participant_uuid: code,
        order_id: orderId,
        user_data_participant_id: authData?.id,
        order_part_id: '',
        participant_no: participantNo || 1,
        status: 'completed',
      };

      const response = await saveAnswer(payload, authData?.token);
      if (response.success) {
        await getQuestionData(code);
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const handleFormSubmit = async () => {
    // Driving License validation Checker
    const dlSec = question?.sections?.find(
      (section) => dlSectionIds?.includes(section.section_id) && !section?.is_hidden
    );
    let isDlErr = false;
    let isFormErr = false;
    if (dlSec) {

      // for buyer DL Upload need to change below logic to find section of DL upload

      if (isAdditionalParticipant) {
        const entityElem = findElementByName(
          question?.sections?.find((sec) => sec.section_id === SOI_SECTION_ID),
          authData?.participant_type_name?.toLowerCase() +
            ADDITIONAL_SELLER_TYPE +
            `_${participantNo}`
        );
        if (entityElem) {
          const entityValue =
            getFormValue(entityElem?.element_id) ||
            findElement(
              question?.sections?.find((sec) => sec.section_id === SOI_SECTION_ID),
              entityElem?.element_id
            )?.user_value ||
            '';
          if (entityValue && entityValue === 'Individual') {
            if (!Object.keys(dlDetails?.transaction_response || {})?.length) {
              setDlError(true);
              const dlIdx = sections?.findIndex((sec) => sec === dlSec?.section_label);
              setDlSectionStep(dlIdx && dlIdx !== -1 ? dlIdx : 0);
              isDlErr = true;
            }
          }
        }
      } else {
        const entityValue =
          getFormValue(IS_INDIVIDUAL_ENTITY) ||
          findElement(
            question?.sections?.find((sec) => sec.section_id === SOI_SECTION_ID),
            IS_INDIVIDUAL_ENTITY
          )?.user_value ||
          '';
        if (entityValue && entityValue === 'Individual') {
          if (!Object.keys(dlDetails?.transaction_response || {})?.length) {
            setDlError(true);
            const dlIdx = sections?.findIndex((sec) => sec === dlSec?.section_label);
            setDlSectionStep(dlIdx && dlIdx !== -1 ? dlIdx : 0);
            isDlErr = true;
          }
        }
      }
    }

    // Form fields validation checker
    let errSections = await checkFormFieldError(
      question,
      isAdditionalSeller,
      participantNo,
      getDocument
    );
    if (errSections?.length) {
      setDlError(false);
      // const dlIdx = sections?.findIndex((sec) => sec === errSections?.[0]?.section_label);
      // setDlSectionStep(dlIdx && dlIdx !== -1 ? dlIdx : 0);

      errSections?.forEach((errSec, index) => {
        const idx = sections?.findIndex((sec) => sec === errSec?.section_label);
        errSections[index].stepIndex = idx;
      });

      if (isDlErr) {
        const idx = errSections.findIndex((errSec) => dlSectionIds?.includes(errSec?.section_id));
        if (idx !== -1) {
          errSections[idx].section_label =
            errSections[idx].section_label + `(Upload Government Issued ID)`;
        } else {
          errSections = [
            { ...dlSec, section_label: dlSec?.section_label + `(Upload Government Issued ID)` },
            ...errSections,
          ];
        }
      }
      if (errSections?.length)
        dispatch(addIncompleteSectionDetails({ tx_id: code, data: errSections }));
      setErrorSections(errSections);
      isFormErr = true;
    }

    if (isDlErr || isFormErr) {
      const validateMessage =
        isDlErr && isFormErr
          ? t('combinedErrorLastStep')
          : isDlErr
          ? t('dlError')
          : t('validationErrorLastStep');

      setValidationMessage(validateMessage);
      setValidationErrorModal(true);
      setLoading(false);
      return;
    }
    // Document review validation checker
    let isDocError = false;

    for (const doc of docList) {
      if (!doc?.is_reviewed) {
        isDocError = true;
        break;
      }
    }
    // Set below param to false to bypass doc validation
    setHaveDocError(isDocError);
    if (!isDocError) {
      if (!signature?.url) {
        setSigRequried(true);
        return;
      }
      if (isAdditionalSeller && !spouseSignature?.url) {
        setSpouseSigRequired(true);
        return;
      }
    }
    setDocConfirmationOpen(true);
  };
  const handleComplete = async () => {
    setLoading(true);

    const isSuccess = await saveAnswerData();
    setLoading(false);
    if (!isSuccess) return toast.error(t('somethingWrong'));
    setDocConfirmationOpen(false);
    setShowSubmissionPage(true);
    setSigRequried(false);
    setActiveSection(activeSection, true);
  };

  const handleContinue = (index) => {
    dispatch(addCurrentStep({ tx_id: code, step: index }));
    setActiveSection(index);
  };

  return (
    <>
      <ValidationErrorModal
        open={validationErrorModal}
        setOpen={setValidationErrorModal}
        dlError={dlError}
        handleContinue={handleContinue}
        errorSections={errorSections}
        validationMessage={validationMessage}
        dlSectionStep={dlSectionStep}
      />
      <SubmissionStep
        docList={docList}
        getDocs={getDocs}
        show={isShowSubmissionPage}
        setShow={setShowSubmissionPage}
        getQuestionData={getQuestionData}
        isAdditionalSeller={isAdditionalSeller}
        logoUrl={companyDetails?.company_logo_url}
        companyName={companyDetails?.company_name ? companyDetails?.company_name : 'Voxtur Verify'}
        setIsLoading={setLoading}
      />
      <DocConfirmation
        open={docConfirmationOpen}
        setOpen={setDocConfirmationOpen}
        haveDocError={haveDocError}
        handleSave={handleComplete}
      />
      <Card id='card-main-form' style={{ borderRadius: '10px', boxShadow: 'none' }}>
        <div className='d-flex justify-between align-items-center pl-8 pr-12 pt-8 pb-8 participant-header-bg participant-header-color'>
          <IconButton
            color='primary'
            onClick={() => setStepperDetails(false)}
            disabled={activeSection < 1}
          >
            <RiArrowLeftSLine />
          </IconButton>

          <div className='card-header-title participant-header-color'>{t('lastSection')}</div>

          <IconButton color='primary' disabled={true}>
            <RiArrowRightSLine />
          </IconButton>
        </div>{' '}
        <div className='scrollable-card'>
          <CardContent className='card-content' style={{ minHeight: '78vh' }}>
            {docList?.length ? (
              <Alert severity='info' className='f-13 fw-500 mt-8'>
                {t('signDoc')}
              </Alert>
            ) : (
              ''
            )}
            {docList?.length ? (
              <div>
                <h4 style={{ fontSize: '15.5px' }} className='fw-650 color-p'>
                  {' '}
                  {/* {'Additional Documents'} */}
                  {t('additionalDocs')}
                </h4>
                <label className='mt-8 color-g fw-500 f-12'>{t('lastStepMessage1')}</label>
                {/* {docList?.map((doc) => {
                return (
                  <MediaLink
                    element={{
                      label: doc?.label,
                      name: doc?.name,
                      disabled: disabledLink,
                    }}
                    getQuestionData={getQuestionData}
                  />
                );
              })} */}
                <div className='mt-12 mb-8'>
                  {docList?.map((doc, index) => {
                    return (
                      <div>
                        <Grid container className='p-8'>
                          <Grid item xs={8} md={9} className='f-13 fw-500' borderRight={'0px'}>
                            <MediaLink
                              element={{
                                label: doc?.label,
                                name: doc?.name,
                              }}
                              getQuestionData={getQuestionData}
                              getDocs={getDocs}
                              setLoading={setLoading}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            md={3}
                            className='d-flex justify-center align-items-center f-13'
                          >
                            {doc?.is_reviewed ? (
                              <Tooltip title={t('docReviewed')}>
                                <CheckCircleOutline style={{ color: 'green' }} />
                              </Tooltip>
                            ) : (
                              <div className='color-g fw-550 f-13-5'>{t('pendingReview')}</div>
                            )}
                          </Grid>
                        </Grid>
                        <hr className='mt-0 mb-0' style={{ backgroundColor: '#d2cece' }} />
                      </div>
                    );
                  })}
                </div>
                {/* <div className='f-12 fw-500 color-g mt-16 pb-16'>{t('lastStepMessage2')}</div> */}
                <div className='f-12 fw-500 color-g mt-16 pb-16'>{t('lastStepMessage2')}</div>
              </div>
            ) : (
              ''
            )}
            <div>
              <Typography component='div' className='sig-container'>
                <h4 style={{ fontSize: '15.5px' }} className='fw-650 color-p'>
                  {t('participantSignature')}
                  <label className='required'>*</label>
                </h4>{' '}
                <Typography
                  component='div'
                  className={cl('contents', sigRequried && 'sig-requried')}
                >
                  {hideOldSignature ? (
                    <div className='placeholder w-100p'>
                      <SignatureCanvas
                        penColor='#005fd4'
                        dotSize={'3'}
                        canvasProps={{
                          className: `${
                            disabled || isAdditionalSeller || signatureDisabled
                              ? 'sig-canvas pointer-event-none'
                              : 'sig-canvas'
                          }`,
                        }}
                        ref={sigCanvas}
                        clearOnResize={true}
                        onBegin={() => setIsEmpty(false)}
                      />
                      {isEmpty ? (
                        <div className='placeholder-text'>{t('signPlaceholder')}</div>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    signature?.url && (
                      <>
                        <img src={`${signature.url}`} />
                      </>
                    )
                  )}
                  <Button
                    onClick={clearSignature}
                    className='clear-button'
                    disabled={disabled || isAdditionalSeller || signatureDisabled}
                  >
                    Clear
                  </Button>
                  <Button
                    onClick={saveSignature}
                    className='save-button'
                    disabled={
                      !hideOldSignature || disabled || isAdditionalSeller || signatureDisabled
                    }
                  >
                    Save
                  </Button>
                </Typography>
                {sigRequried && <div className='error f-13 mt-4'>{t('signRequired')}</div>}
              </Typography>
              {isAdditionalSeller && (
                <Typography component='div' className='sig-container'>
                  <h4 style={{ fontSize: '15.5px' }} className='fw-650 color-p'>
                    {t('additionalSignature')}
                  </h4>

                  <Typography
                    component='div'
                    className={cl('contents', spouseSigRequired && 'sig-requried')}
                  >
                    {hideOldSpouseSignature ? (
                      <div className='placeholder w-100p'>
                        <SignatureCanvas
                          penColor='#005fd4'
                          dotSize={'3'}
                          canvasProps={{
                            className: signatureDisabled
                              ? 'sig-canvas pointer-event-none'
                              : 'sig-canvas',
                          }}
                          ref={sigCanvasSpouse}
                          clearOnResize={true}
                          onBegin={() => setIsEmptySpouse(false)}
                        />
                        {isEmptySpouse ? (
                          <div className='placeholder-text'>
                            Sign here and click save when completed.
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      spouseSignature?.url && (
                        <>
                          <img src={`${spouseSignature.url}`} />
                        </>
                      )
                    )}
                    <Button
                      onClick={clearSpouseSignature}
                      className='clear-button'
                      disabled={signatureDisabled}
                    >
                      Clear
                    </Button>
                    <Button
                      onClick={saveSpouseSignature}
                      className='save-button'
                      disabled={!hideOldSpouseSignature || signatureDisabled}
                    >
                      Save
                    </Button>
                  </Typography>
                  {spouseSigRequired && <div className='error f-13 mt-4'>{t('signRequired')}</div>}
                </Typography>
              )}
            </div>
          </CardContent>
          <hr className='hr-label' />
          {/* {isShowAlertMessage && (
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity='error'>Make sure you've fixed all issues above</Alert>
          </Stack>
        )} */}
          <Grid container spacing={1} className='next-button'>
            <Grid xs={6} md={4} item={true}>
              {activeSection > 0 && (
                <Button
                  variant='contained'
                  color='primary'
                  size='lg'
                  onClick={() => setActiveSection(activeSection - 1)}
                >
                  <ArrowBackIcon
                    className='back-icon'
                    style={{ fontSize: '18px', marginRight: '4px' }}
                  />
                  Prev Step
                </Button>
              )}
            </Grid>
            {window.innerWidth > 768 && (
              <Grid xs={6} md={4} item={true} className='d-flex justify-center'>
                <Tooltip
                  title={paddingDetails?.isCollapsed ? 'Expand Form' : 'Shrink Form to Center'}
                >
                  <Button
                    onClick={() => {
                      dispatch(
                        addPaddingDetails({
                          tx_id: code,
                          isCollapsed: paddingDetails?.isCollapsed ? false : true,
                          padding: paddingDetails?.isCollapsed ? 2 : 12,
                        })
                      );
                    }}
                  >
                    {paddingDetails?.isCollapsed ? (
                      <i class='fa-solid fa-expand' style={{ fontSize: '18px' }}></i>
                    ) : (
                      <i class='fa-solid fa-compress' style={{ fontSize: '18px' }}></i>
                    )}
                  </Button>
                </Tooltip>
              </Grid>
            )}

            <Grid xs={6} md={4} item={true} className='d-flex justify-end step-btn'>
              <Button
                variant='contained'
                size='lg'
                onClick={disabled ? handleComplete : handleFormSubmit}
                // className='btn-section'
              >
                submit
              </Button>
            </Grid>
          </Grid>
          <div className='hr-no-margin'>
            <hr />
          </div>
          <CardActions className='footer'>
            <div className='d-flex flex-column justify-center'>
              <Box variant='div' component='div' className='d-flex justify-center'>
                <img
                  src={companyDetails?.company_logo_url ? companyDetails?.company_logo_url : logo}
                  alt='logo-main'
                  loading='lazy'
                  className='logo-size'
                />
              </Box>
              <Typography component='div' className='copy-right' style={{ fontWeight: '500' }}>
                {companyDetails?.office_name || '-'}, {address || '-'}
              </Typography>
            </div>
          </CardActions>
        </div>
      </Card>
    </>
  );
};
export default LastStep;
