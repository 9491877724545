import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Grid, Link } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import DocumentViewer from '../../DocumentViewer';
import { getDocumentDetails } from '../../../../services/questions/home';
import Loader from '../../Loader';
import { toast } from 'react-toastify';

const MediaLink = ({ element }) => {
  const { code } = useParams();
  const [docModalOpen, setDocModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState('');
  const [originalUrl, setOriginalUrl] = useState('');
  const orderInfo = useSelector((state) => state.questionSlice.orderDetails)?.find(
    (order) => order.tx_id === code
  )?.order;
  const authData = useSelector((state) => state?.authSlice);

  const downloadAdditionalDocs = async () => {
    setLoading(true);
    const res = await getDocumentDetails(
      {
        order_id: orderInfo?.order_id,
        form_element_name: element.element_name,
      },
      authData?.[code]?.token
    );
    if (res?.success && res?.response?.files?.[0]?.url) {
      fetch(res?.response?.files?.[0]?.url)
        .then((response) => {
          if (!response.ok) {
            setLoading(false);
            toast.error('Document is not available');
          }
          return response.blob();
        })
        .then((blobData) => {
          setLoading(false);
          const blobUrl = URL.createObjectURL(blobData);
          setUrl(blobUrl);
          setOriginalUrl(res?.response?.files?.[0]?.url);
          setDocModalOpen(true);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      toast.error('Document is not available');
    }
  };

  return (
    <>
      {loading && <Loader />}
      <DocumentViewer
        open={docModalOpen}
        setOpen={setDocModalOpen}
        fileUrl={url}
        originalUrl={originalUrl}
        title={element?.label || 'Document'}
      />
      {element?.user_value ? <Grid xs={12} item={true} style={{ paddingTop: '0px' }}>
        <Grid container spacing={1} className='grid-container mt-4 mb-12 plt-8'>
          <Link
            href={''}
            className='media-link d-flex'
            onClick={(e) => {
              e.preventDefault();
              downloadAdditionalDocs();
            }}
            style={{ textDecoration: 'none', width: 'fit-content' }}
          >
            <ArticleIcon />
            <div className='f-16 fw-600 ml-8'>{element.label}</div>
          </Link>
        </Grid>
      </Grid> : ""}
    </>
  );
};
export default MediaLink;
